import { ActionIcon, Button } from "@mantine/core";
import { IconMenu, IconMenu2 } from "@tabler/icons";
import { useAtom } from "jotai";
import React from "react";
import { useNavigate } from "react-router-dom";
import { mobileMenuStatus } from "../atoms/mobile-nav-menu";

type Props = {
	title: string;
};

export function NavBar({ title }: Props) {
	const navigate = useNavigate();
	const [menuStatus, setMenuStatus] = useAtom(mobileMenuStatus);

	return (
		<div className="border-b border-gray-300 pb-4 mb-2 flex align-baseline space-x-2 no-print">
			{/* <ActionIcon
				variant="transparent"
				onClick={() => setMenuStatus((s) => (s === "open" ? "closed" : "open"))}
				className="self-center"
			>
				<IconMenu2 size={18} />
			</ActionIcon>
*/}
			<h1 className="text-xl no-print">{title}</h1>

			{/* <div> */}
			{/* 	<Button */}
			{/* 		variant="subtle" */}
			{/* 		onClick={() => navigate("/admin/create-account")} */}
			{/* 	> */}
			{/* 		Create Customer Account */}
			{/* 	</Button> */}

			{/* 	<Button */}
			{/* 		variant="subtle" */}
			{/* 		onClick={() => navigate("/admin/register-staff")} */}
			{/* 	> */}
			{/* 		Create User Account */}
			{/* 	</Button> */}
			{/* 	<Button variant="subtle" onClick={() => navigate("/admin/sales/new")}> */}
			{/* 		Make a Sale */}
			{/* 	</Button> */}
			{/* </div> */}
		</div>
	);
}
