import { Button, Modal, MultiSelect, Select, Table, Text } from "@mantine/core";
import { DatePicker, DatePickerInput } from "@mantine/dates";
import { v1 as uuidV1 } from "uuid";
import { useForm } from "@mantine/form";
import { subDays, format, startOfDay, endOfDay } from "date-fns";
import { query, where, collection, getDocs } from "firebase/firestore";
import { upperFirst } from "lodash";
import React, { useEffect, useState } from "react";
import { NavBar } from "../../components/AdminNavBar";
import { firestore, functions } from "../../firebase";
import { formatFirebaseDates } from "./CreateAccount";
import { httpsCallable } from "firebase/functions";
import { useFirestoreQuery } from "@react-query-firebase/firestore";
import { Account, Refill } from "../../types";

type DepositFilters = {
	startDate: Date;
	endDate: Date;
	accountType: Refill["accountType"][];
	buyerAccountTypes: Account["type"][];
};

export default function Deposits() {
	const accountsRef = query(collection(firestore, "accounts"));
	const accountQuery = useFirestoreQuery(["accounts"], accountsRef);

	const filterForm = useForm<DepositFilters>({
		initialValues: {
			startDate: startOfDay(subDays(new Date(), 1)),
			endDate: endOfDay(new Date()),
			accountType: [],
			buyerAccountTypes: [],
		},
	});
	const [deposits, setDeposits] = useState<(Refill & { uid: string })[]>([]);
	// const [isSaleModalOpen, setIsSaleModalOpen] = useState<boolean>(false);

	const fetchDeposits = async () => {
		const { startDate, endDate } = filterForm.values;
		const q = query(
			collection(firestore, "refills"),
			where("createdAt", ">=", startDate)
			// where("endDate", "<=", endDate)
		);
		const res = await getDocs(q);
		const depositsList = res.docs.map((d) => ({
			...formatFirebaseDates(d.data()),
			uid: d.id,
		})) as unknown as (Refill & { uid: string })[];
		// @ts-ignor
		setDeposits(depositsList);
	};

	useEffect(() => {
		fetchDeposits();
	}, [filterForm.values.startDate]);

	const accounts = (accountQuery?.data?.docs?.map((d: any) =>
		formatFirebaseDates(d.data())
	) || []) as unknown as Account[];

	const deleteDeposit = (depositId: string) => () => {
		if (
			!window.confirm(
				"Are you sure you want to delete this deposit and delete the amount from the user?"
			)
		) {
			return;
		}
		httpsCallable(
			functions,
			"deleteRefill"
		)({ refillId: depositId })
			.then((res) => {
				console.log({ res });
				// remove the sale from the list of sales;
				setDeposits((deposits) => deposits.filter((d) => d.uid !== depositId));
				alert(
					"Deposit has been deleted and the amount has been deducted from the account."
				);
			})
			.catch((error) => {
				console.error({ error });
				alert(
					"There was an error deleting the deposit. Please check your internet connection and contact support if the issue persists."
				);
			});
	};

	const rows = deposits
		.map((d) => {
			const account = accounts.find((acc) => acc.uid === d.accountId);
			return {
				...d,
				buyerAccountName: account?.ownerFullName || "",
				buyerAccountType: account?.type || ("" as Account["type"]),
			};
		})
		.filter(
			(s) =>
				(filterForm.values.accountType.includes(s.accountType) ||
					filterForm.values.accountType.length === 0) &&
				(filterForm.values.buyerAccountTypes.includes(s.buyerAccountType) ||
					filterForm.values.buyerAccountTypes.length === 0)
		)
		.map((depositItem) => (
			<tr key={depositItem.uid}>
				<td>{format(depositItem.createdAt, "d MMMM yyyy")}</td>
				<td>{upperFirst(depositItem.accountType)}</td>
				<td>{depositItem.buyerAccountName}</td>
				<td>{depositItem.buyerAccountType}</td>
				<td>Tsh {depositItem.amount.toLocaleString()} /=</td>
				<td>
					<Button variant="subtle" onClick={deleteDeposit(depositItem.uid)}>
						Delete Deposit
					</Button>
				</td>
			</tr>
		));

	return (
		<div>
			<NavBar title="Deposits" />

			<div className="md:flex md:flex-row md:space-x-4 items-end pb-4">
				<DatePickerInput
					placeholder="Pick start date"
					label="From"
					{...filterForm.getInputProps("startDate")}
					required
				/>
				<DatePickerInput
					placeholder="Pick end date"
					label="To"
					required
					{...filterForm.getInputProps("endDate")}
				/>
				<MultiSelect
					data={["teacher", "student", "staff", "guest", "visitor"].map(
						(d) => ({
							label: upperFirst(d),
							value: d,
						})
					)}
					{...filterForm.getInputProps("buyerAccountTypes")}
					label="Account type"
					placeholder="Choose all accounts you want to include"
				/>
				<MultiSelect
					data={["main", "snack"].map((d) => ({
						label: upperFirst(d),
						value: d,
					}))}
					{...filterForm.getInputProps("accountType")}
					label="Deposit Type (Snack vs Main)"
					placeholder="Choose all types you want to include"
				/>

				<Button className="mt-3 md:mt-0 bg-sky-800" onClick={fetchDeposits}>
					Search
				</Button>
			</div>

			{/* <div> */}
			{/* 	<Button variant="subtle" onClick={() => setIsSaleModalOpen(true)}> */}
			{/* 		Enter a new Deposit */}
			{/* 	</Button> */}
			{/* </div> */}

			<Text>Total Deposits: {deposits.length.toLocaleString()} </Text>

			<div>
				<Table verticalSpacing="md">
					<thead>
						<tr>
							<th>Date</th>
							<th>Account</th>
							<th>Account Name</th>
							<th>Account Type</th>
							<th>Deposit Amount</th>
							<th>Actions</th>
						</tr>
					</thead>
					{accounts?.length > 0 && <tbody>{rows}</tbody>}
				</Table>{" "}
			</div>
		</div>
	);
}
