import React from "react";
import { getPrice } from "../costStrategy";
import { Account } from "../types";
type MealReportCostSummaryProps = {
	accountType: Account["type"];
	accountTypeSubGroup: Account["typeSubGroup"];
	grade: Account["grade"];
	totalMeals: {
		lunch: number;
		breakfast: number;
		dinner: number;
	};
};

export const MealReportCostSummary: React.FC<MealReportCostSummaryProps> = ({
	accountType,
	accountTypeSubGroup,
	grade,
	totalMeals,
}) => {
	const lunchPrice = getPrice(
		{ type: accountType, typeSubGroup: accountTypeSubGroup, grade } as Account,
		"lunch"
	);
	const breakfastPrice = getPrice(
		{ type: accountType } as Account,
		"breakfast"
	);
	const dinnerPrice = getPrice({ type: accountType } as Account, "dinner");
	return (
		<div className="py-2">
			<h1 className="text-lg">Total Cost Summary:</h1>

			{totalMeals.breakfast > 0 && (
				<div>
					Breakfast: {totalMeals.breakfast} x {breakfastPrice.toLocaleString()}
					{" = "}
					{(breakfastPrice * totalMeals.breakfast).toLocaleString()} Tsh /=
				</div>
			)}
			<div>
				Lunch: {totalMeals.lunch} x {lunchPrice.toLocaleString()}
				{" = "}
				{(lunchPrice * totalMeals.lunch).toLocaleString()} Tsh /=
			</div>
			{totalMeals.dinner > 0 && (
				<div>
					Dinner: {totalMeals.dinner} x {dinnerPrice.toLocaleString()}
					{" = "}
					{(dinnerPrice * totalMeals.dinner).toLocaleString()} Tsh /=
				</div>
			)}
		</div>
	);
};
