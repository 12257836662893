import React from "react";
import { Header } from "@mantine/core";
import { Outlet } from "react-router-dom";

export function AppLayout() {
	return (
		<>
			<Header height={60}>Some</Header>

			<Outlet />
		</>
	);
}
