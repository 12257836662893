import {
	Text,
	Button,
	Group,
	Select,
	SimpleGrid,
	UnstyledButton,
	createStyles,
	Radio,
	Checkbox,
	MultiSelect,
	NumberInput,
	Modal,
} from "@mantine/core";
import { Calendar, DateObject } from "react-multi-date-picker";
import { useFirestoreQuery } from "@react-query-firebase/firestore";
import {
	query,
	collection,
	writeBatch,
	doc,
	increment,
	onSnapshot,
} from "firebase/firestore";
import { firestore } from "../../firebase";
import { v1 as uuidV1 } from "uuid";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { NavBar } from "../../components/AdminNavBar";
import { isUndefined, sortBy, upperFirst } from "lodash";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import useAuth from "../../contexts/auth";
import { Account, MealSaleItem, SaleCategory, SnackSaleItem } from "../../types";
import { getMealCost, pricingTable, PricingTable } from "./Prices";
import { useDisclosure } from "@mantine/hooks";

import { IconQrcode } from "@tabler/icons";

const defaultMealSale: MealSaleItem = {
	uid: "", // unique global identifier from the database
	referenceId: "",
	amount: 6_500,
	category: "lunch",

	// accounts
	buyerId: "",
	buyerAccountName: "",
	buyerAccountType: "student",
	buyerAccountTypeSubGroup: "day",
	sellerId: "",
	organizationId: "",
	metadata: {
	},
	isOnduty: false,
	isGuest: false,
	isPaid: false, // to indicate in the future whether or not this is a pending payment.
	// dates
	createdAt: new Date(),
	updatedAt: new Date(),
}


const defaultSnackSale: SnackSaleItem = {
	uid: "", // unique global identifier from the database
	referenceId: "",
	amount: 6_500,

	// accounts
	buyerId: "",
	buyerAccountName: "",
	buyerAccountType: "student",
	buyerAccountTypeSubGroup: "day",
	sellerId: "",
	organizationId: "",
	items: [],
	metadata: {
	},
	isOnduty: false,
	isGuest: false,
	isPaid: false, // to indicate in the future whether or not this is a pending payment.
	// dates
	createdAt: new Date(),
	updatedAt: new Date(),
}

const SelectItem = forwardRef<HTMLDivElement, Account>(
	({ ownerPhotoURL, ownerFullName, type, ...others }: Account, ref) => (
		<div ref={ref} {...others} className="pb-3">
			<Group noWrap>
				{/* <Avatar src={ownerPhotoURL} /> */}

				<div>
					<Text size="sm">{ownerFullName}</Text>
					<Text size="xs" color="dimmed">
						{upperFirst(type)}
					</Text>
				</div>
			</Group>
		</div>
	)
);

export default function CreateSale() {
	const [account, setAccount] = useState<Account | null>(null);
	const [mealType, setMealType] = useState<SaleCategory>("lunch");
	const [dates, setDates] = useState([]);
	const [chargeSale, setChargeSale] = useState<boolean>(true);
	const [snackItems, setSnackItems] = useState<SnackSaleItem["items"]>([]);
	const [prices, setPrices] = useState<PricingTable["default"]>({ ...pricingTable["default"] })


	const [openedScanningModal, { open: openScanner, close: closeScanner }] = useDisclosure(false);


	const [scannedTextValue, updateScannedTextValue] = useState("")


	useEffect(() => {
		if (openedScanningModal) {
			setTimeout(() => {
				document.getElementById("scannerTextInput")?.focus()
				console.log(document.getElementById("scannerTextInput"))
			}, 500)
		}
		else {
			updateScannedTextValue("")
		}
	}, [openedScanningModal])


	useEffect(() => {
		if (scannedTextValue.split("|").length === 2) {
			const id = scannedTextValue.split("|")[0];
			setAccountWithId(id)
			closeScanner()
		}
	}, [scannedTextValue])


	const [isOnDuty, setIsOnDuty] = useState(false);
	const [isGuest, setIsGuest] = useState(false);
	// const [snacks, setSnacks] = useState("");

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (snackItems.length > 0) {
			setSnackItems([]);
		}
	}, [mealType]);
	const { user } = useAuth();

	const ref = query(collection(firestore, "accounts"));
	// Provide the query to the hook
	const accountQuery = useFirestoreQuery(["accounts"], ref, {
		subscribe: true,
	});


	useEffect(() => {
		const defaultPricingRef = doc(firestore, "pricing", "default")
		const sub = onSnapshot(defaultPricingRef, snap => {
			if (snap.exists()) {
				setPrices((snap.data() as PricingTable)["default"])
			} else {
				setPrices({ ...pricingTable["default"] })
			}
		})

		return () => {
			sub()
		}
	}, [])

	console.log({ prices })

	const snacksRef = query(collection(firestore, "snacks"));

	// Provide the query to the hook
	const snacksQuery = useFirestoreQuery(["snacks"], snacksRef, {
		subscribe: true,
	});

	// const snapshot = snacksQuery.data;
	const snacksList = (snacksQuery?.data?.docs?.map((d: any) => ({
		...d.data(),
	})) || []) as SnackSaleItem["items"];

	if (accountQuery.isLoading) {
		return <div>Loading ...</div>;
	}

	const accounts = (accountQuery?.data?.docs?.map(
		(d: any) => d.data() as Account
	) || []) as unknown as Account[];
	// const accounts = snapshot?.docs?.map((d: any) => d.data() || []) as Account[];

	const setAccountWithId = (uid: string) =>
		setAccount(accounts.find((acc) => acc.uid === uid) || null);

	const submit = async () => {
		if (!account || dates.length == 0) return;
		if (!window.confirm(`Are you sure you want to proceed with making ${dates.length} sales?`)) {
			return;
		}

		const datesList = dates.map((v: any) => v.toDate());

		const referenceId = uuidV1();


		// @ts-ignore
		const saleItem: SnackSaleItem | MealSaleItem = {
			buyerId: account.uid,
			buyerAccountName: account.name,
			buyerAccountType: account.type,
			buyerAccountTypeSubGroup: account.typeSubGroup,
			referenceId,
			amount: 0,
			// mealType === "snack"
			// 	? totalSnackCost
			// 	: getPrice(account, mealType as StrategyName),
			// @ts-ignore
			category: mealType,
			sellerId: user?.uid || "",
			organizationId: account.organizationId,

			items: mealType === "snack" ? snackItems : [], // only for snack do we allow snack items to be recorded
			isGuest: isGuest,
			isOnduty: isOnDuty,
			// isPaid: isPaidForBySchool || isGuest || isOnduty ? false : true,
			isPaid: isOnDuty || isGuest ? false : true,
			metadata: {
				saleExistsToday: false,
				networkStatus: navigator.onLine,
			},

			createdAt: new Date(),
			updatedAt: new Date(),
		};


		let sale: SnackSaleItem | MealSaleItem

		// calculate total snack cost (multiplied by the number of days)
		const totalSnackCost = snackItems.reduce((acc: number, curr: SnackSaleItem["items"][0]) => {
			return acc + curr.count * curr.price;
		}, 0) * datesList.length;

		console.log({ totalSnackCost });
		// handle snack sales
		if (mealType === "snack") {


			sale = {
				...defaultSnackSale,
				...saleItem,
				amount: totalSnackCost,
			}
		} else {
			const amount = getMealCost(prices)(account.type, account.typeSubGroup, account.grade as any).result[mealType]
			sale = {
				...defaultMealSale,
				...saleItem,
				amount
			}
		}


		const salesList = createSales(sale, datesList);

		try {
			const batch = writeBatch(firestore);

			// Create all sales
			// @ts-ignore
			salesList.map((sale) => {
				const saleDocRef = doc(collection(firestore, "sales"));
				return batch.set(saleDocRef, { ...sale });
			});

			// Deduct the total amount from the sale only if it is a snack sale
			const totalCost =
				mealType !== "snack"
					? !chargeSale
						? 0
						// @ts-ignore
						: -(salesList.length * sale.amount)
					: 0;
			const accountRef = doc(firestore, "accounts", account.uid);
			batch.update(accountRef, {
				balance: increment(totalCost),
				// @ts-ignore
				snackBalance: increment(-totalSnackCost),
				updatedAt: new Date(),
			});

			// store the data in the database
			await batch.commit();
			alert("Sales made successfully.");
			// reset the states
			setChargeSale(true);
			setDates([]);
			setMealType("lunch");
			setIsOnDuty(false);
			setAccount(null);
		} catch (error) {
			window.alert(
				"Error: There was an error performing these sales. Please take a moment to smile and dont call J."
			);
			console.error(error);
		}
	};

	const toggleSnackItem = (snackIds: string[]) => {
		const snacks: SnackSaleItem["items"] = snackIds
			.map((s) => {
				const sn = snacksList.find(
					// @ts-ignore
					(snack) => snack.uid === s
				) as unknown as SnackSaleItem["items"][0];
				return {
					name: sn?.name || "",
					count: 1,
					origin: sn?.origin || "meal",
					price: sn?.price || 0,
				};
			})
			.filter((s) => !isUndefined(s));

		setSnackItems(snacks);
	};

	const updateSnackCount = (name: string, count: number = 0) => {
		const updatedSnacks = snackItems.map((item) => {
			if (item.name !== name) return item;
			return {
				...item,
				count,
			};
		});

		setSnackItems(updatedSnacks);
	};

	console.log({ snackItems });
	return (
		<div>
			<NavBar title="Make a new Sale" />

			<Modal opened={openedScanningModal} onClose={closeScanner} title="Scanner Active">
				<IconQrcode size={94} />


				<input type="text" id="scannerTextInput" value={scannedTextValue} onChange={e => updateScannedTextValue(e.target.value)} autoFocus />
			</Modal>


			{/* Step 1: Select an account */}
			<div className="container mx-auto px-2 pt-4 space-y-8">
				<div className="flex row items-end space-x-4">
					<Select
						label="Choose account"
						required
						placeholder="Pick one"
						itemComponent={SelectItem}
						data={sortBy(accounts, ["ownerFullName"]).map((a) => ({
							value: a.uid,
							label: a.ownerFullName,
							ownerFullName: a.ownerFullName,
							ownerPhotoURL: a.ownerPhotoURL,
							type: a.type,
						}))}
						searchable
						maxDropdownHeight={300}
						className="flex-grow"
						clearable
						onChange={(uid) => setAccountWithId(uid || "")}
						value={account?.uid || ""}
						nothingFound="Nobody here"
						filter={(value, item) =>
							item.ownerFullName
								.toLowerCase()
								.includes(value.toLowerCase().trim())
						}
					/>

					<Button variant="outline" onClick={openScanner}>
						<IconQrcode />
						Scan
					</Button>

				</div>

				{/* Step 2: Select the meal type */}
				<Radio.Group
					name="meal"
					label="Select the meal"
					value={mealType}
					onChange={(v) => setMealType(v as SaleCategory)}
					description="You can only select one meal at a time."
					withAsterisk
				>
					<Radio value="breakfast" label="Breakfast" />
					<Radio value="lunch" label="Lunch" />
					<Radio value="dinner" label="Dinner" />
					<Radio value="snack" label="Snack" />
				</Radio.Group>

				{/* If the sale is for a snack, select the snack. */}
				{mealType === "snack" && (
					<div className="space-y-2">
						<MultiSelect
							// @ts-ignore
							data={snacksList.map((s) => ({ value: s.uid, label: s.name }))}
							searchable
							label="Choose the snacks for the sale"
							placeholder="Snacks"
							onChange={toggleSnackItem}
						/>

						{snackItems.map((sn) => (
							<div key={sn.name}>
								<NumberInput
									label={`How many '${sn.name}'`}
									value={sn.count}
									onChange={(val) => updateSnackCount(sn.name, val || 0)}
									withAsterisk
								/>
							</div>
						))}
					</div>
				)}

				{/* Step 3: Choose the dates for this sale */}
				<div className="space-y-2">
					<Text className="text-sm">Choose Date(s) of the sale</Text>
					<Calendar
						value={dates}
						plugins={[<DatePanel />]}
						multiple
						onChange={(v) => setDates(v as any)}
					/>
				</div>

				<Checkbox
					checked={isOnDuty}
					onChange={() => setIsOnDuty((d) => !d)}
					description="This/these are sales of a teacher on duty"
					label="On duty sale"
				/>

				<Checkbox
					checked={chargeSale}
					onChange={() => setChargeSale((d) => !d)}
					description="If you choose not to charge the account, the account balance will not be affected but the sales will just be recorded."
					label="Charge this account for this/these sales."
				/>
				<div>
					Summary:
					<br />
					<div>
						This operation will create {dates.length} individual {mealType}{" "}
						sales. <br />{" "}
						{chargeSale &&
							"It will reflect in the account balance and statements"}
					</div>
					{!chargeSale && (
						<div className="text-red-800">
							Warning: All these sales will not subtract from the owner's
							account balance. They will still show up in the statement but the
							account will not be charged.
						</div>
					)}
				</div>

				<div>
					{/* Amount: {account && getPrice(account, mealType as StrategyName)} */}
					<Button variant="outline" onClick={submit}>
						Make Sale
					</Button>
				</div>
			</div>
		</div>
	);
}

/** 
Given a single sale object and an array of dates, create an array of sales at each date

@param {MealSaleItem | SnackSaleItem} sale
@param {Date[]} dates


@returns {(MealSaleItem | SnackSaleItem)[]}
*/
const createSales = (sale: MealSaleItem | SnackSaleItem, dates: Date[]): (SnackSaleItem | MealSaleItem)[] => {
	return dates.map((date) => ({
		...sale,
		referenceId: uuidV1(),
		createdAt: date,
		// date: date.toISOString(),
	}));
};
