import { Button, Modal, MultiSelect, Select, Table, Text } from "@mantine/core";
import { DatePicker, DatePickerInput } from "@mantine/dates";
import { v1 as uuidV1 } from "uuid";
import { useForm } from "@mantine/form";
import { subDays, format, endOfDay, startOfDay } from "date-fns";
import firestoreRoot, {
	query,
	where,
	collection,
	getDocs,
	updateDoc,
	doc,
	writeBatch,
	increment,
	QuerySnapshot,
} from "firebase/firestore";
import { upperFirst } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { NavBar } from "../../components/AdminNavBar";
import { firestore, functions } from "../../firebase";
import { formatFirebaseDates } from "./CreateAccount";
import { httpsCallable } from "firebase/functions";
import { getEATime, groupSaleDuplicates, isNotDeleted, isSnackSale } from "../../utils";
import { Account, MealSaleItem, Sale, SnackSaleItem } from "../../types";
import { IconEditCircle } from "@tabler/icons";
import { useFirestoreQuery } from "@react-query-firebase/firestore";

type SalesFilters = {
	startDate: Date;
	endDate: Date;
	categories: MealSaleItem["category"][];
	buyerAccountTypes: Sale["buyerAccountType"][];
};

export default function Sales({ }) {
	const filterForm = useForm<SalesFilters>({
		initialValues: {
			startDate: subDays(new Date(), 1),
			endDate: new Date(),
			categories: [],
			buyerAccountTypes: [],
		},
	});

	const saleForm = useForm<Sale>({
		// @ts-ignore
		initialValues: {
			amount: 0,
			buyerAccountName: "",
			sellerId: "",
			buyerAccountType: "student",
			buyerAccountTypeSubGroup: "day",
			buyerId: "",
			category: "lunch",
			createdAt: new Date(),
			items: [],
			referenceId: uuidV1(),
			organizationId: "",
			updatedAt: new Date(),
			isGuest: false,
			isOnduty: false,
			isPaid: false,
			metadata: {
			},
		},
	});

	const [sales, setSales] = useState<(Sale & { uid: string })[]>([]);
	const [isSaleModalOpen, setIsSaleModalOpen] = useState<boolean>(false);


	const ref = query(collection(firestore, "accounts"));
	const accountQuery = useFirestoreQuery(["accounts"], ref, {
		subscribe: true,
	});

	const snapshot = accountQuery?.data;
	const accounts = (snapshot?.docs?.map((d: any) => d.data()) ||
		[]) as Account[];

	const getAccount = useCallback((uid: string) => {
		return accounts.find(acc => acc.uid === uid)
	}, [accountQuery.isLoading])


	const fetchSales = async () => {
		const { startDate, endDate } = filterForm.values;
		setSales([]);
		const q1 = query(
			collection(firestore, "sales"),
			where("createdAt", ">=", startOfDay(getEATime(startDate))),
			where("createdAt", "<=", endOfDay(getEATime(endDate)))
		);
		const q2 = query(
			collection(firestore, "snack-sales"),
			where("createdAt", ">=", startOfDay(getEATime(startDate))),
			where("createdAt", "<=", endOfDay(getEATime(endDate)))
		);
		const res1 = await getDocs(q1);
		const res2 = await getDocs(q2);

		const combinedSales = [...res1.docs, ...res2.docs]//	as QuerySnapshot<DocumentData, DocumentData>
		const salesList = combinedSales
			.map((d) => ({
				...formatFirebaseDates(d.data()),
				uid: d.id,
			}))
			// @ts-ignore
			.filter(isNotDeleted) as unknown as (Sale & { uid: string })[];
		setSales(salesList);
	};

	// useeffect(() => {
	//   fetchSales();
	// }, [filterForm.values.startDate]);

	const deleteSale = (saleId: string) => () => {
		console.log(saleId);
		if (
			!window.confirm(
				"Are you sure you want to delete this sale and refund the amount to the user?"
			)
		) {
			return;
		}
		httpsCallable(
			functions,
			"deleteSale"
		)({ saleId })
			.then((res) => {
				console.log({ res });
				// remove the sale from the list of sales;
				setSales((sales) => sales.filter((s) => s.uid !== saleId));
				alert("Sale has been deleted and account has been refunded.");
			})
			.catch((error) => {
				console.error({ error });
				alert(
					"There was an error deleting the sale. Please check your internet connection and contact support if the issue persists."
				);
			});
	};

	const makeSaleTOD =
		(
			saleId: string,
			status: boolean = true,
			accountId: string,
			amount: number
		) =>
			async () => {
				if (
					!window.confirm(
						"Are you sure you want to make this sale a Teacher On Duty Sale?"
					)
				) {
					return;
				}

				const saleRef = doc(firestore, "sales", saleId);
				const accountRef = doc(firestore, "accounts", accountId);


				try {
					const batch = writeBatch(firestore);
					// await updateDoc(saleRef, {
					// 	"metadata.isOnduty": status,
					// 	updatedAt: new Date(),
					// });

					// Update the Sale to be of the passed in status
					batch.update(saleRef, {
						isOnduty: status,
						updatedAt: new Date(),
					});

					// Sale is going from normal to a TOD sale
					// We need to refund the amount from the sale
					batch.update(accountRef, {
						balance: increment(status === true ? amount : -amount),
						updatedAt: new Date(),
					});

					const res = await batch.commit();
					setSales((sales) =>
						sales.map((s) => {
							if (s.uid === saleId) {
								return { ...s, isOnduty: status };
							}
							return s;
						})
					);

					alert("Sale has updated successfully.");
				} catch (error) {
					alert("Error updating the sale. Please check your internet providers.");
				}
			};


	const editSaleAmount = (saleId: string) => () => {
		const updatedAmount = Number(window.prompt("Enter the new amount for the sale:"))

		if (
			!window.confirm(
				"Are you sure you want to update the amount for this sale?"
			)
		) {
			return;
		}
		httpsCallable(
			functions,
			"changeSaleAmount"
		)({ saleId, updatedAmount })
			.then((res) => {
				console.log({ res });
				// remove the sale from the list of sales;
				setSales((sales) => sales.map((s) => s.uid !== saleId ? s : { ...s, amount: updatedAmount }));
				alert("Sale has been updated and account balance has been changed.");
			})
			.catch((error) => {
				console.error({ error });
				alert(
					"There was an error editing the sale. Please check your internet connection and contact support if the issue persists."
				);
			});
	}

	const filteredSales = useMemo(
		() =>
			sales.filter(
				(s) =>
					(filterForm.values.categories.includes((s as MealSaleItem).category) ||
						filterForm.values.categories.length === 0) &&
					(filterForm.values.buyerAccountTypes.includes(s.buyerAccountType) ||
						filterForm.values.buyerAccountTypes.length === 0)
			),
		[filterForm.values, sales]
	);


	const rows = filteredSales.map((saleItem) => (
		<tr key={saleItem.uid}>
			<td>
				{format(saleItem.createdAt, "do MMMM yyyy")}
				<div className="text-gray-500 text-xs">
					{format(saleItem.createdAt, "EEEE @ HH:mm")}
				</div>
			</td>
			<td>{upperFirst((saleItem as MealSaleItem).category)}</td>
			{/*<td>{saleItem.buyerAccountName} {saleItem.buyerId} {saleItem.uid}</td>*/}
			<td>
				{saleItem.buyerAccountName} <br />
				{saleItem.buyerAccountType === "student" ? "Grade: " + getAccount(saleItem.buyerId)?.grade : "k"}
			</td>
			<td>
				{saleItem.buyerAccountType} - {saleItem.buyerAccountTypeSubGroup}
			</td>
			<td>Tsh {saleItem.amount.toLocaleString()} /=</td>
			<td>
				{saleItem.buyerAccountType === "teacher" && (
					<Button
						variant="subtle"
						color={saleItem?.isOnduty ? "yellow" : "indigo"}
						onClick={makeSaleTOD(
							saleItem.uid,
							!(saleItem?.isOnduty || false),
							saleItem.buyerId,
							saleItem.amount
						)}
					>
						{saleItem?.isOnduty ? `Make not T.O.D` : `Make "T.O.D"`}
					</Button>
				)}
				<Button
					color={"red"}
					variant="subtle"
					onClick={deleteSale(saleItem.uid)}
				>
					Delete Sale
				</Button>

				<Button
					color={"green"}
					variant="subtle"
					onClick={editSaleAmount(saleItem.uid)}
				>
					<IconEditCircle />
				</Button>
			</td>
		</tr>
	));

	// console.log(groupSaleDuplicates(filteredSales), filteredSales);

	return (
		<div>
			<NavBar title="Sales" />
			<Modal
				opened={isSaleModalOpen}
				onClose={() => setIsSaleModalOpen(false)}
				title="Make a new Sale"
			>
				<DatePickerInput
					placeholder=""
					{...saleForm.getInputProps("createdAt", { type: "input" })}
					label="Sale Date"
					required
				/>
				<Select
					label="Select the type of sale (1 only)"
					placeholder="Pick a sale type"
					{...saleForm.getInputProps("category")}
					data={["lunch", "breakfast", "dinner"].map((d) => ({
						name: upperFirst(d),
						label: upperFirst(d),
						value: d,
					}))}
				/>
			</Modal>

			<div className="md:flex md:flex-row md:space-x-4 items-end pb-4">
				<DatePickerInput
					placeholder="Pick start date"
					label="From"
					{...filterForm.getInputProps("startDate")}
					required
				/>
				<DatePickerInput
					placeholder="Pick end date"
					label="To"
					required
					{...filterForm.getInputProps("endDate")}
				/>
				<MultiSelect
					data={["teacher", "student", "staff", "guest", "visitor"].map(
						(d) => ({
							label: upperFirst(d),
							value: d,
						})
					)}
					{...filterForm.getInputProps("buyerAccountTypes")}
					label="Account type"
					placeholder="Choose all accounts you want to include"
				/>
				<MultiSelect
					data={["lunch", "breakfast", "snack", "dinner"].map((d) => ({
						label: upperFirst(d),
						value: d,
					}))}
					{...filterForm.getInputProps("categories")}
					label="Sale type (lunch/snacks/etc)"
					placeholder="Choose all types you want to include"
				/>

				<Button className="mt-3 md:mt-0 bg-sky-800" onClick={fetchSales}>
					Search
				</Button>
			</div>

			<Text>Total Sales: {filteredSales.length.toLocaleString()} </Text>

			<div>
				<Table verticalSpacing="md">
					<thead>
						<tr>
							<th>Date</th>
							<th>Category</th>
							<th>Account Name</th>
							<th>Account Type</th>
							<th>Sale Amount</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>{rows}</tbody>
				</Table>
			</div>
		</div>
	);
}
