import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createEmotionCache, MantineProvider } from "@mantine/core";
import models from "./models";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

const myCache = createEmotionCache({ key: "mantine", prepend: true });
root.render(
	<React.StrictMode>
		<MantineProvider
			// emotionOptions={{ key: "mantine", prepend: false }}
			emotionCache={myCache}
			// theme={{
			// 	colors: {
			// 		brand: ["#004D40", "#FF6F00", "#880E4F", "#263238"],
			// 	},
			// 	primaryColor: "brand",
			// }}
			withGlobalStyles
			withNormalizeCSS
		>
			<App />
		</MantineProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
