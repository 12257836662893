import React, { useMemo } from "react";
import { useViewportSize } from "@mantine/hooks";
import { sortBy } from "lodash";
type SingleBarProps = {
	data: { label: string; value: number }[];
	withLegend?: boolean;
	orientation: "horizontal" | "vertical";
};

export default function SingleBar(
	props: SingleBarProps = {
		data: [],
		withLegend: true,
		orientation: "vertical",
	}
) {
	const { width } = useViewportSize();
	const total = useMemo(
		() => props.data.reduce((acc, cur) => acc + cur.value, 0),
		[props.data]
	);

	const lastItemIdx = props.data.length - 1;

	return (
		<div>
			<div className="flex-row flex">
				{sortBy(props.data, ["value"])
					.reverse()
					.filter(isGreaterThanZero)
					.map((point, idx) => (
						<div
							className={`${
								idx === 0
									? "rounded-l-sm"
									: idx === lastItemIdx
									? "rounded-r-sm"
									: ""
							} rounded-sm`}
							style={{
								...getBarStyle(
									point.value,
									total,
									idx,
									props.orientation,
									width < 900 ? 14 : 6
								),
								marginRight: 4,
								paddingLeft: 6,
							}}
							key={`bar_component_${idx}_${point.label}`}
						>
							<span style={{ fontSize: 12 }} className="text-white font-bold">
								{point.label}
								{/* {point.value} */}
							</span>
						</div>
					))}
			</div>

			{props.withLegend && (
				<div className="flex flex-col pt-4">
					{sortBy(props.data, ["value"])
						.reverse()
						.map((point, idx) => (
							<div
								className="flex flex-row items-center"
								key={`label___${idx}_${point.label}`}
							>
								<div
									className="h-5 w-8 mr-2 text-white text-xs flex items-center justify-start pl-1 rounded-sm font-bold"
									style={{ backgroundColor: colors[idx] }}
								>
									{point.value}
								</div>
								<h2 className="text-md">{point.label}</h2>
							</div>
						))}
				</div>
			)}
		</div>
	);
}

// list of muted colors to pick from
const colors = ["#FF6F00", "#004D40", "#880E4F", "#263238"];

// Get a single bar's style
function getBarStyle(
	value: number,
	total: number,
	index: number,
	orientation: "horizontal" | "vertical",
	baseSize: number = 1
) {
	const percent = (value / total) * 100;
	const backgroundColor = colors[index % colors.length];
	if (orientation === "vertical") {
		return {
			width: `${percent + baseSize}%`,
			// height: "0.8rem",
			backgroundColor,
		};
	} else {
		return {
			width: "0.8rem",
			height: `${percent}%`,
			backgroundColor,
		};
	}
}

function isGreaterThanZero(point: { label: string; value: number }) {
	return point.value > 0;
}
