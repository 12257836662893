import React, { useEffect, useMemo, useState } from "react";
import {
	Paper,
	Text,
	SimpleGrid,
	Avatar,
	Group,
	Badge,
	Anchor,
	useMantineTheme,
	ScrollArea,
	Table,
	Button,
	Modal,
	Tabs,
} from "@mantine/core";
import _, { countBy, flatten, isEqual, upperFirst } from "lodash";
import { addHours, endOfDay, format, startOfDay, sub } from "date-fns";
import { observer } from 'mobx-react-lite'
import {
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
	BarChart,
	Bar,
} from "recharts";
import { useFirestoreQuery } from "@react-query-firebase/firestore";
import {
	query,
	collection,
	// limit,
	// QuerySnapshot,
	// DocumentData,
	where,
	getDocs,
} from "firebase/firestore";
// import { v1 as uuidV1 } from "uuid";
import { firestore, functions } from "../../firebase";

import { NavBar } from "../../components/AdminNavBar";
import {
	formatFirebaseDates,
	// getAccountTypsSubGroups,
	// accountTypes,
} from "./CreateAccount";
import {
	getEATime,
	getMissedMealAccounts,
	isABoarder,
	isMealSale,
	isNotDeleted,
	isSnackSale,
} from "../../utils";
// import { IconPencil, IconTrash } from "@tabler/icons";
import { DatePickerInput } from "@mantine/dates";
import { IconPencil, IconTrash } from "@tabler/icons";
import { useNavigate } from "react-router-dom";
// import AccountsSummary, { applyAccountFilters } from "./AccountsSummary";
import SingleBar from "../../components/SingleBar";
import { MealAttendanceSummary } from "../../components/MealAttendanceSummary";
import { useDailySnackDispatches } from "./DailySnacks";
import { Account, MealSaleItem, Sale, SaleCategory, SnackSaleItem, UIAccount } from "../../types";

type DailyAttendanceSummary = {
	title: string;
	accountTypes: Array<Account["type"]>;
	grades: Array<Account["grade"]>;
	meals: Array<MealSaleItem["category"]>;
	dormitory?: Account["dormitory"];
	missed: number;
	total: number;
	accounts?: Account[];
	remainingAccounts?: Account[];
}[];

const dailyAttendanceTemplate: DailyAttendanceSummary = [
	{
		title: "EC/ Nursery",
		accountTypes: ["student"],
		grades: ["ec1", "ec2", "ec"],
		meals: ["lunch"],
		missed: 0,
		total: 0,
	},
	{
		title: "Primary",
		grades: ["p1", "p2", "p3", "p4", "p5", "p6"],
		accountTypes: ["student"],
		meals: ["lunch"],
		missed: 0,
		total: 0,
	},
	{
		title: "Mid Year",
		grades: ["m1", "m2", "m3", "m4", "m5", "m6"],
		accountTypes: ["student"],
		meals: ["lunch"],
		missed: 0,
		total: 0,
	},
	{
		title: "D1 & D2",
		accountTypes: ["student"],
		grades: ["d1", "d2"],
		meals: ["lunch", "dinner", "breakfast"],
		missed: 0,
		total: 0,
	},
	{
		title: "Staff",
		accountTypes: ["staff"],
		grades: [],
		meals: ["lunch"],
		missed: 0,
		total: 0,
	},

	{
		title: "Teachers",
		accountTypes: ["teacher"],
		grades: [],
		meals: ["lunch"],
		missed: 0,
		total: 0,
	},
	{
		title: "T.O.D",
		accountTypes: ["teacher"],
		grades: [],
		meals: ["lunch", "dinner"],
		missed: 0,
		total: 0,
	},
	{
		title: "Visitors",
		accountTypes: ["visitor", "guest"],
		grades: [],
		meals: ["lunch", "dinner"],
		missed: 0,
		total: 0,
	},
];

function getDailyAccountsSummary(
	initial: DailyAttendanceSummary,
	sales: Sale[],
	accounts: Account[],
	includeAccounts: boolean = false
): DailyAttendanceSummary {
	return initial.map((summary) => {
		const isTeacherOnDuty = summary.title === "T.O.D";
		const gradeAccounts = accounts.filter((acc) => {
			if (acc.dormitory && acc.dormitory.length > 0) {
				return (
					acc.dormitory?.trim().toLowerCase() === summary.dormitory //&&
					//summary.grades.includes(acc.grade.trim().toLowerCase())
				);
			}

			if (summary.grades.length > 0) {
				return summary.grades.includes(acc.grade.trim().toLowerCase());
			}
			if (isEqual(summary.accountTypes, ["teacher"])) {
				return acc.type === "teacher";
			}
			if (isEqual(summary.accountTypes, ["staff"])) {
				return acc.type === "staff";
			}
			if (isEqual(summary.accountTypes, ["visitor", "guest"])) {
				return acc.type === "visitor" || acc.type === "guest";
			}
		});
		const missedAccounts = getMissedMealAccounts(summary.meals)(sales.filter(isMealSale))(
			gradeAccounts
		);

		const returnedMissedAccounts = includeAccounts
			? isTeacherOnDuty
				? []
				: missedAccounts
			: [];

		return {
			...summary,
			missed: isTeacherOnDuty ? 0 : missedAccounts.length,
			total: isTeacherOnDuty
				? sales.filter((s) => s.isOnduty).length
				: gradeAccounts.length,
			accounts: returnedMissedAccounts,
			remainingAccounts: _.differenceWith(
				gradeAccounts,
				missedAccounts,
				_.isEqual
			),
		};
	});
}

function labeledCountBy(
	collection: Record<string, any>[],
	key: string
): string[] {
	const map = new Map(Object.entries(countBy(collection, key)));

	const res: string[] = [];

	map.forEach((idx, k, m) => res.push(`${upperFirst(k)}: ${m.get(k)}`));

	return res;
}

export const Dashboard = observer((props: any) => {
	const navigate = useNavigate();
	const accountsRef = query(collection(firestore, "accounts"));
	const accountQuery = useFirestoreQuery(["accounts"], accountsRef);


	// console.log({props})

	//// Present here to preload schools ahead of time - bug in create account /////
	const schoolRef = query(collection(firestore, "schools"));
	const schoolsQuery = useFirestoreQuery(["schools"], schoolRef);
	//// End ////

	const { dispatchesToday } = useDailySnackDispatches({});

	const [summaryList, setSummaryList] = useState<{
		missedAccounts: UIAccount[];
		presentAccounts: UIAccount[];
	}>({
		missedAccounts: [],
		presentAccounts: [],
	});
	const fiveDaysAgo = sub(getEATime(), { days: 5 });

	// const [summaryDate, setSummaryDate] = useState<Date>(getEATime(new Date()));
	const [summaryDate, setSummaryDate] = useState<Date>(new Date());
	// const [summaryDate, setSummaryDate] = useState<Date>(addHours(new Date(), 10));
	// console.log({ summaryDate:  })

	const salesRef = query(
		collection(firestore, "sales"),
		where("createdAt", ">=", fiveDaysAgo)
	);

	
	const snackSalesRef = query(
		collection(firestore, "snackSales"),
		where("createdAt", ">=", fiveDaysAgo)
	);

	const salesQuery = useFirestoreQuery(["sales"], salesRef, {
		subscribe: true,
	});


	const snackSalesQuery = useFirestoreQuery(["snackSales"], snackSalesRef, {
		subscribe: true,
	});

	const refillsRef = query(
		collection(firestore, "refills"),
		where("createdAt", ">=", fiveDaysAgo)
	);
	const refillsQuery = useFirestoreQuery(["refilss"], refillsRef, {
		subscribe: true,
	});
	const refillsList = (refillsQuery?.data?.docs?.map((d: any) =>
		formatFirebaseDates(d.data())
	) || []) as Sale[];

	// const dailySnacksRef = query(
	// 	collection(firestore, "daily-snack-dispatches"),
	// 	where("date", ">", startOfDay(new Date()))
	// );
	// // Provide the query to the hook
	// const dailyDispatchQuery = useFirestoreQuery(
	// 	["daily-snack-dispatches"],
	// 	dailySnacksRef,
	// 	{
	// 		subscribe: true,
	// 	}
	// );

	//    (dailyDispatchQuery?.data?.docs?.map((d: any) =>
	// 	formatFirebaseDates(d.data())
	// ) || []) as DailySnacksDispatch[];

	// if (accountQuery.isLoading) {
	// 	return <div>Loading ...</div>;
	// }

	// const snapshot = accountQuery.data;
	const accounts = (accountQuery?.data?.docs?.map((d: any) =>
		formatFirebaseDates(d.data())
	) || []) as unknown as Account[];

	const boardingAccounts = useMemo(
		() => accounts.filter(isABoarder),
		[accounts.length]
	);

	const salesList = (
		salesQuery?.data?.docs?.map((d: any) => formatFirebaseDates(d.data())) || []
	).filter(isNotDeleted) as MealSaleItem[];

	const snackSalesList = (
		snackSalesQuery?.data?.docs?.map((d: any) => formatFirebaseDates(d.data())) || []
	).filter(isNotDeleted) as SnackSaleItem[];


	console.log({ salesList })

	const todaysSales = [...salesList, ...snackSalesList].filter(
		(s) => {
			// console.log(new Date(s.createdAt) >= startOfDay(new Date()))
			return new Date(s.createdAt) >= startOfDay(summaryDate)//summaryDate.toDateString()
			}
	);


	const accoutSummary = [
		{
			title: "Customer Accounts",
			data: accounts.reduce(
				(acc, cur) => {
					if (cur.type === "student") {
						acc[0].value++;
					} else if (cur.type === "staff") {
						acc[1].value++;
					} else if (cur.type === "teacher") {
						acc[2].value++;
					}
					return acc;
				},
				[
					{ label: "Student", value: 0 },
					{ label: "Staff", value: 0 },
					{ label: "Teachers", value: 0 },
				]
			),
		},
		{
			title: "Staff Accounts",
			data: accounts.reduce(
				(acc, cur) => {
					if (cur.typeSubGroup === "support") {
						acc[0].value++;
					} else if (cur.typeSubGroup === "auxiliary") {
						acc[1].value++;
					}
					return acc;
				},
				[
					{ label: "Support", value: 0 },
					{ label: "Auxiliary", value: 0 },
				]
			),
		},
		{
			title: "Student Accounts",
			data: accounts.reduce(
				(acc, cur) => {
					if (cur.typeSubGroup === "day") {
						acc[0].value++;
					} else if (cur.typeSubGroup === "boarding") {
						acc[1].value++;
					}
					return acc;
				},
				[
					{ label: "Day", value: 0 },
					{ label: "Boarding", value: 0 },
				]
			),
		},
		{
			title: "Today's Sales",
			data: todaysSales.reduce(
				(acc, cur: any) => {
					if (cur.category === "breakfast") {
						acc[0].value++;
					} else if (cur.category === "lunch") {
						acc[1].value++;
					} else if (cur.category === "dinner") {
						acc[2].value++;
					} else if (isSnackSale(cur)) {
						acc[3].value++;
					}
					return acc;
				},
				[
					{ label: "Breakfast", value: 0 },
					{ label: "Lunch", value: 0 },
					{ label: "Dinner", value: 0 },
					{ label: "Snack", value: dispatchesToday.length || 0 },
				]
			),
		},
	];

	const openAccounts =
		(stat: { title: string; value: number; params: string }) => () => {
			navigate(`/admin/accounts?${stat.params}`);
		};

	const dailyAccSummary = useMemo(
		() =>
			getDailyAccountsSummary(
				dailyAttendanceTemplate,
				todaysSales,
				accounts,
				true
			),
		[todaysSales.length, summaryDate.toDateString()]
	);

	const totalDeposits = refillsList.reduce((p, c) => p + c.amount, 0);
	const snackSalesBreakdown = todaysSales
		.filter(isSnackSale)
		.reduce(
			(prev, curr) => {
				const saleItems: { shop: number; meal: number } = curr.items.reduce(
					(prev: any, curr: any) => {
						if (curr.origin) {
							return {
								...prev,
								shop:
									curr.origin === "shop"
										? prev.shop + curr.price || 0
										: prev.shop,
								meal:
									curr.origin === "meal"
										? prev.meal + curr.price || 0
										: prev.meal,
							};
						}
						return prev;
					},
					{ shop: 0, meal: 0 }
				);

				return {
					...prev,
					shop: prev.shop + saleItems.shop,
					meal: prev.meal + saleItems.meal,
				};
			},
			{ shop: 0, meal: 0 }
		);

	return (
		<div>
			<NavBar title="Dashboard" />

			<Text className="text-xl">Overall Accounts Summary</Text>
			<SimpleGrid
				breakpoints={[
					{ maxWidth: 1200, cols: 2, spacing: "md" },
					// { maxWidth: 755, cols: 2, spacing: "sm" },
					{ maxWidth: 600, cols: 1, spacing: "sm" },
				]}
				mb="md"
				className="py-4"
				cols={3}
			>
				{accoutSummary.map((summary, i) => (
					<Paper
						key={summary.title}
						p="md"
						className="bg-gray-200"
						shadow={"xs"}
					>
						<Text className="text-xl">{summary.title}</Text>
						<Text className="text-gray-700 text-sm">
							Total: {summary.data.reduce((acc: any, curr: any) => acc + curr.value, 0)}
						</Text>
						<SingleBar
							data={summary.data}
							withLegend={true}
							orientation={"vertical"}
						/>
					</Paper>
				))}

				{/* {updatedStats.map((stat) => ( */}
				{/* 	<Paper shadow="xs" p="md" key={stat.title}> */}
				{/* 		<Text className="text-lg">{stat.title}</Text> */}
				{/* 		<pre> */}
				{/* 			<Text className="text-blue-800 text-xl">{stat.value}</Text> */}
				{/* 		</pre> */}
				{/* 		{stat.params && ( */}
				{/* 			<div className="flex justify-end"> */}
				{/* 				<Button onClick={openAccounts(stat as any)} variant="subtle"> */}
				{/* 					View */}
				{/* 				</Button> */}
				{/* 			</div> */}
				{/* 		)} */}
				{/* 	</Paper> */}
				{/* ))} */}
			</SimpleGrid>

			<hr />

			<div className="mt-16">
				<div className="flex flex-col">
					<Text className="text-xl">
						Daily summaries: {format(summaryDate, "dd/MM/yyyy")}
					</Text>
					<DatePickerInput
						label="Change the date"
						className="max-w-sm"
						value={summaryDate}
						minDate={fiveDaysAgo}
						onChange={(d) => setSummaryDate(d as Date)}
					/>
				</div>
				<Paper shadow="xs" p="md" mt="md" className="">
					{salesQuery.isLoading ? (
						`Loading last ${5} days sales`
					) : (
						<RecentActivities
							sales={
								salesQuery?.data?.docs?.map((d: any) => d.data()) ||
								([] as Sale[])
							}
						/>
					)}
				</Paper>

				<Text className="mt-4 font-semibold">Summaries by Topic</Text>
				<SimpleGrid
					breakpoints={[
						{ maxWidth: 980, cols: 3, spacing: "md" },
						{ maxWidth: 755, cols: 2, spacing: "sm" },
						{ maxWidth: 600, cols: 1, spacing: "sm" },
					]}
					cols={5}
				>
					{dailyAccSummary.map((das) => (
						<Paper shadow="xs" p="md" key={das.title}>
							<Text className="text-xl">{das.title}</Text>
							<Text className="text-gray-700 text-sm">Eaten all meals</Text>
							<Text className="text-blue-800 text-xl">
								{das.total - das.missed} out of {das.total}
							</Text>
							<Text className="text-gray-500 text-xs">
								Meals: {das.meals.map(upperFirst).join(", ")}
							</Text>
							<div className="flex justify-end">
								<Button
									onClick={() => {
										setSummaryList({
											missedAccounts: (das.accounts as UIAccount[]) || [],
											presentAccounts:
												(das.remainingAccounts as UIAccount[]) || [],
										});
									}}
									variant="subtle"
								// disabled={das.accounts?.length === 0}
								>
									View
								</Button>
							</div>
						</Paper>
					))}
					<Paper shadow="xs" p="md">
						<Text className="text-xl">Total Deposits</Text>
						<Text className="text-blue-800 text-xl">
							Tsh {totalDeposits.toLocaleString()} /=
						</Text>
					</Paper>
					<Paper shadow="xs" p="md">
						<Text className="text-xl">Snack Sales</Text>
						<Text className="text-blue-800 text-xl">
							Shop: Tsh {snackSalesBreakdown.shop.toLocaleString()} /=
						</Text>
						<Text className="text-blue-800 text-xl">
							Meal: Tsh {snackSalesBreakdown.meal.toLocaleString()} /=
						</Text>
					</Paper>
				</SimpleGrid>
			</div>

			<DormitoryMissedMealsSummary
				accounts={boardingAccounts}
				sales={todaysSales}
			/>

			{/* <DailyDepositSummary date={summaryDate} /> */}

			<div className="h-8" />
			<hr />
			<div className="py-4">
				<MealAttendanceSummary
					accounts={accounts}
					startDate={fiveDaysAgo}
					sales={salesList}
					defaultFilters={{
						meal: "lunch",
						accountType: "student",
						accountTypeSubGroup: "boarding",
					}}
				/>
			</div>
			<Modal
				opened={
					summaryList.missedAccounts.length > 0 ||
					summaryList.presentAccounts.length > 0
				}
				size="55%"
				onClose={() =>
					setSummaryList({ missedAccounts: [], presentAccounts: [] })
				}
				title={`Accounts List ${"" || summaryList.missedAccounts.length}`}
			>
				<SummaryAccountsList
					missedAccounts={summaryList.missedAccounts}
					presentAccounts={summaryList.presentAccounts}
				/>
			</Modal>
		</div>
	);
})

const DailyDepositSummary = React.memo(
	({ date }: { date: Date }) => {
		const [loading, setLoading] = useState(false);
		const [sales, setSales] = useState<Sale[]>([]);

		useEffect(() => {
			setLoading(true);
			// const today = new Date(new Date().toDa
			const q = query(
				collection(firestore, "refills"),
				where("createdAt", ">=", startOfDay(date)),
				where("createdAt", "<=", endOfDay(date))
			);
			getDocs(q)
				.then((res) => {
					setLoading(false);
					const sales = res.docs.map((d) => ({
						...d.data(),
						uid: d.id,
					})) as unknown as Sale[];
					setSales(sales || []);
				})
				.catch((error) => {
					console.error({ error });
				});
		}, [date]);

		return (
			<div className="my-4">
				<Text className="text-xl">Account Deposit Activity</Text>

				{loading ? (
					"Loading ..."
				) : (
					<SimpleGrid cols={4}>
						<Paper shadow="xs" p="md">
							<Text className="text-xl">Deposit Amount</Text>
							<Text className="text-blue-800 text-xl">
								Tsh {sales.reduce((p, c) => p + c.amount, 0).toLocaleString()}
							</Text>
						</Paper>
					</SimpleGrid>
				)}
			</div>
		);
	},
	(prev, next) => prev.date.toDateString() === next.date.toDateString()
);

const dailyDormitoryAttendanceTemplate: DailyAttendanceSummary = [
	{
		title: "Acacia",
		accountTypes: [],
		grades: [],
		meals: ["lunch", "dinner", "breakfast"],
		dormitory: "acacia",
		missed: 0,
		total: 0,
	},
	{
		title: "Baobab",
		accountTypes: [],
		grades: [],
		meals: ["lunch", "dinner", "breakfast"],
		dormitory: "baobab",
		missed: 0,
		total: 0,
	},
	{
		title: "Jacaranda",
		accountTypes: [],
		grades: [],
		meals: ["lunch", "dinner", "breakfast"],
		dormitory: "jacaranda",
		missed: 0,
		total: 0,
	},
];

type DormitoryMissedMealsSummaryProps = {
	accounts: Account[];
	sales: (MealSaleItem | SnackSaleItem)[];
};

function DormitoryMissedMealsSummary({
	accounts,
	sales,
}: DormitoryMissedMealsSummaryProps) {
	// const [summaryList, setSummaryList] = useState<UIAccount[]>([]);
	const [summaryList, setSummaryList] = useState<{
		missedAccounts: UIAccount[];
		presentAccounts: UIAccount[];
	}>({
		missedAccounts: [],
		presentAccounts: [],
	});
	const summary = useMemo(
		() =>
			getDailyAccountsSummary(
				dailyDormitoryAttendanceTemplate,
				sales,
				accounts,
				true
			),
		[sales.length]
	);

	return (
		<div className="pt-8">
			<Text className="text-md font-bold">Summaries by Dormitory</Text>
			<SimpleGrid
				breakpoints={[
					{ maxWidth: 1220, cols: 2, spacing: "md" },
					{ maxWidth: 755, cols: 2, spacing: "sm" },
					{ maxWidth: 600, cols: 1, spacing: "sm" },
				]}
				cols={3}
				mt="sm"
			>
				{summary.map((das) => {
					const missedAccounts = getMissedMealAccountsByMeal(
						accounts.filter(
							(a) =>
								a.dormitory?.trim().toLowerCase() ===
								das?.dormitory?.trim().toLowerCase()
						),
						sales as MealSaleItem[]
					);
					return (
						<Paper shadow="xs" p="md" className="bg-gray-200" key={das.title}>
							<Text className="text-md font-bold">{das.title}</Text>
							{das.total - das.missed} out of {das.total} missed at least one
							meal
							<SingleBar
								data={["breakfast", "lunch", "dinner"].map((m) => ({
									label: upperFirst(m),
									// @ts-ignore
									value: missedAccounts[m].length,
								}))}
								withLegend={true}
								orientation={"vertical"}
							/>
							<div className="h-2" />
							<span
								className="text-md text-blue-700 cursor-pointer"
								onClick={() => {
									const accs = Object.keys(missedAccounts).map((key) =>
										// @ts-ignore
										missedAccounts[key].map((mA: Account) => ({
											...mA,
											subtitle: key,
										}))
									);
									setSummaryList({
										missedAccounts: flatten(accs) || [],
										presentAccounts: [],
									});
								}}
							// disabled={das.accounts?.length === 0}
							>
								View
							</span>
						</Paper>
					);
				})}
			</SimpleGrid>

			<Modal
				opened={
					summaryList.presentAccounts.length > 0 ||
					summaryList.missedAccounts.length > 0
				}
				size="55%"
				onClose={() =>
					setSummaryList({ presentAccounts: [], missedAccounts: [] })
				}
				title={`Accounts List ${"" || summaryList.missedAccounts.length}`}
			>
				<SummaryAccountsList
					missedAccounts={summaryList.missedAccounts}
					presentAccounts={summaryList.presentAccounts}
				/>
			</Modal>
		</div>
	);
}

function getMissedMealAccountsByMeal(
	accounts: Account[],
	sales: MealSaleItem[]
): Record<MealSaleItem["category"], Account[]> {
	// @ts-ignore
	return ["breakfast", "lunch", "dinner"].reduce((prev, meal) => {
		const mealSales = sales.filter(
			(s) => s.category?.toLowerCase().trim() === meal
		);
		return {
			...prev,
			[meal]: getMissedMealAccounts([meal as MealSaleItem["category"]])(mealSales)(
				// @ts-ignore
				accounts
			),
		};
	}, {});
}

const accountColors: Record<Account["type"], string> = {
	student: "blue",
	teacher: "cyan",
	staff: "pink",
	guest: "gray",
	visitor: "gray",
	school: "purple",
	other: "gray",
};

const SummaryAccountsList = ({
	missedAccounts,
	presentAccounts,
}: {
	missedAccounts: (Account & { subtitle: string })[];
	presentAccounts: (Account & { subtitle: string })[];
}) => {
	const [activeTab, setActiveTab] = useState<"missed" | "present">("missed");
	const theme = useMantineTheme();
	const rows = (activeTab === "missed" ? missedAccounts : presentAccounts).map(
		(item) => (
			<tr key={`${item.uid}__${item.subtitle}`}>
				<td>
					<Group spacing="sm">
						<Avatar size={30} src={item.ownerPhotoURL} radius={30} />
						<Text size="sm" weight={500}>
							{item.ownerFullName}
							<Text size="sm" weight={300} className="text-gray-500">
								{upperFirst(item.subtitle)}
							</Text>
						</Text>
					</Group>
				</td>

				<td>
					<Badge
						color={
							accountColors[item.type.toLowerCase() as Account["type"]] ||
							"gray"
						}
						variant={theme.colorScheme === "dark" ? "light" : "outline"}
					>
						{item.type} / {item.typeSubGroup}
					</Badge>
				</td>
				<td>
					<Anchor<"a">
						size="sm"
						href="#"
						onClick={(event) => event.preventDefault()}
					>
						{item.dormitory}
					</Anchor>
				</td>
				<td>
					<Text size="sm" color="dimmed">
						{item.grade}
					</Text>
				</td>
				<td>
					{/* <Group spacing={0} position="right"> */}
					{/* 	<ActionIcon> */}
					{/* 		<IconPencil size={16} stroke={1.5} /> */}
					{/* 	</ActionIcon> */}
					{/* 	<ActionIcon color="red"> */}
					{/* 		<IconTrash size={16} stroke={1.5} /> */}
					{/* 	</ActionIcon> */}
					{/* </Group> */}
				</td>
			</tr>
		)
	);

	return (
		<ScrollArea>
			<Tabs
				defaultValue="missed"
				value={activeTab}
				onTabChange={(v) => setActiveTab(v as any)}
			>
				<Tabs.List>
					<Tabs.Tab
						rightSection={
							<Badge
								// sx={{ width: 16, height: 16, pointerEvents: "none" }}
								px={2}
								variant="filled"
							// size="xs"
							// p={0}
							>
								{missedAccounts.length}
							</Badge>
						}
						value="missed"
					>
						Missed Accounts
					</Tabs.Tab>
					<Tabs.Tab
						rightSection={
							<Badge
								// sx={{ width: 16, height: 16, pointerEvents: "none" }}
								variant="filled"
								// size="xs"
								// p={0}
								px={2}
							>
								{presentAccounts.length}
							</Badge>
						}
						value="present"
					>
						Present Accounts
					</Tabs.Tab>
				</Tabs.List>
			</Tabs>
			<Table sx={{ minWidth: 800 }} verticalSpacing="sm">
				<thead>
					<tr>
						<th>Name</th>
						<th>Account type</th>
						<th>Dormitory</th>
						<th>Grade</th>
						<th />
					</tr>
				</thead>
				<tbody>{rows}</tbody>
			</Table>
		</ScrollArea>
	);
};

// type MealAttendanceSummaryProps = {
// 	startDate: Date;
// 	accounts: Account[];
// 	sales: Sale[];
// 	todayOnly?: boolean;
// };

// export function MealAttendanceSummary({
// 	startDate,
// 	accounts,
// 	sales,
// 	todayOnly = true,
// }: MealAttendanceSummaryProps) {
// 	const [date, onChange] = useState<Date | null>(new Date());
// 	const [accountType, setAccountType] = useState<AccountType>("student");
// 	const [accountSubType, setAccountSubType] =
// 		useState<AccountTypeSubGroup>("boarding");
// 	const [meal, setMeal] = useState<Sale["category"]>("lunch");
// 	const [dormitory, setDormitory] = useState("");
// 	const [grades, setGrades] = useState([]);

// 	const accountGrades = React.useMemo(
// 		() =>
// 			uniq(accounts.map((acc) => acc.grade || "")).filter((s) => s.length > 0),
// 		[]
// 	);

// 	const missedByCategory = getMissedMealAccounts([meal]);
// 	const categorySales = missedByCategory(
// 		sales.filter((s) => s.createdAt.toDateString() === date?.toDateString())
// 	);

// 	const dormitoryPopulations = countBy(
// 		accounts.map((a) => a.dormitory?.toLowerCase().trim())
// 	);

// 	const dormitoryOptions = Array.from(
// 		new Set(
// 			accounts.map((d) => d.dormitory?.toLowerCase().trim()).filter(Boolean)
// 		)
// 	);

// 	const appliedFilters = applyAccountFilters("", {
// 		typeSubGroups: [accountSubType],
// 		types: [accountType],
// 		grades,
// 		overdraftOnly: false,
// 	});

// 	const missedAccounts = categorySales(accounts.filter(appliedFilters)).filter(
// 		(a) =>
// 			a.dormitory
// 				?.toLowerCase()
// 				.trim()
// 				.includes(dormitory || "")
// 	);
// 	console.log({ missedAccounts });
// 	const dormitoryDistribution = countBy(missedAccounts, (o) =>
// 		o.dormitory?.toLowerCase().trim()
// 	);

// 	// @ts-ignore HACK!
// 	delete dormitoryDistribution[undefined];

// 	return (
// 		<Paper shadow="xs" p="md">
// 			<h1 className="text-xl text-bold">Missed Meals Summary</h1>
// 			<div className="flex flex-row space-x-4 no-print">
// 				<DatePickerInput
// 					label="Date"
// 					minDate={startDate}
// 					maxDate={new Date()}
// 					value={date}
// 					onChange={onChange}
// 				/>
// 				<Select
// 					label="Select the meal type"
// 					placeholder="Pick a sale type"
// 					value={meal}
// 					onChange={(v) => {
// 						console.log(v);
// 						setMeal(v as Sale["category"]);
// 					}}
// 					data={["breakfast", "lunch", "dinner"]}
// 				/>
// 				<Select
// 					label="Account Type"
// 					placeholder="Pick one"
// 					onChange={(s) => setAccountType(s as any)}
// 					value={accountType}
// 					data={accountTypes.map((acc) => ({
// 						value: acc,
// 						label: upperFirst(acc),
// 					}))}
// 				/>

// 				<Select
// 					label="Account Type Group"
// 					placeholder="Pick one"
// 					clearable
// 					value={accountSubType}
// 					onChange={(s) => setAccountSubType(s as any)}
// 					data={getAccountTypsSubGroups(accountType as any)}
// 				/>
// 				{accountType === "student" && (
// 					<MultiSelect
// 						label="Select the grade"
// 						placeholder="Pick a grades to filter by"
// 						value={grades}
// 						clearable
// 						multiple
// 						onChange={(g) => setGrades(g as any)}
// 						data={accountGrades}
// 					/>
// 				)}
// 				{accountSubType === "boarding" && (
// 					<Select
// 						label="Select the dormitory"
// 						placeholder="Pick a dormitory type"
// 						value={dormitory}
// 						clearable
// 						onChange={(v) => {
// 							setDormitory(v as string);
// 						}}
// 						data={dormitoryOptions}
// 					/>
// 				)}
// 			</div>
// 			<div className="py-2">
// 				{accountSubType === "boarding" && (
// 					<>
// 						Absent Dormitory Summary
// 						{keys(dormitoryDistribution).map((k) => (
// 							<div key={k}>
// 								{upperFirst(k)} : {dormitoryDistribution[k]} out of{" "}
// 								{dormitoryPopulations[k]}
// 							</div>
// 						))}
// 					</>
// 				)}
// 				<h1 className="text-lg">Total Missed: ({missedAccounts.length})</h1>
// 				{missedAccounts.map((acc) => (
// 					<div
// 						className="border-b border-gray-200 py-4 flex flex-row space-x-2"
// 						key={acc.uid}
// 					>
// 						<Avatar
// 							className="self-center"
// 							radius={"xl"}
// 							src={acc.ownerPhotoURL || ""}
// 						/>
// 						<div>
// 							<h1 className="text-lg text-gray-800">{acc.ownerFullName}</h1>
// 							{accountSubType === "boarding" && (
// 								<p>Dormitory Name: {acc.dormitory}</p>
// 							)}
// 						</div>
// 					</div>
// 				))}
// 			</div>
// 		</Paper>
// 	);
// }

type DailySalesSummary = {
	name: string;
	lunch: number;
	breakfast: number;
	dinner: number;
	snack: number;
};

function prepSalesHistory(sales: Sale[]) {
	return sales.reduce(
		// @ts-ignore
		(prev, curr: any | Sale) => {
			// Date is firebase timestamp
			// @ts-ignore
			const d = format(new Date(curr.createdAt.toDate()), "yyyy-MM-dd");
			if (prev[d] === undefined) {
				return {
					...prev,
					[d]: {
						name: d,
						breakfast: curr.category === "breakfast" ? curr.amount : 0,
						lunch: curr.category === "lunch" ? curr.amount : 0,
						dinner: curr.category === "dinner" ? curr.amount : 0,
						snack: isSnackSale(curr) ? curr.amount : 0,
					},
				};
			} else {
				return {
					...prev,
					[d]: {
						name: d,
						breakfast:
							curr.category === "breakfast"
								? curr.amount + prev[d].breakfast
								: prev[d].breakfast,
						lunch:
							curr.category === "lunch"
								? curr.amount + prev[d].lunch
								: prev[d].lunch,
						dinner:
							curr.category === "dinner"
								? curr.amount + prev[d].dinner
								: prev[d].dinner,
						snack:
							isSnackSale(curr)
								? curr.amount + prev[d].snack
								: prev[d].snack,
					},
				};
			}
		},
		{} as { [date: string]: DailySalesSummary }
	);
}

type RecentActivitiesProps = {
	sales: Sale[];
};

const RecentActivities: React.FC<RecentActivitiesProps> = ({ sales }) => {
	return (
		<div className="h-96">
			<ResponsiveContainer width="100%" height="100%">
				{/* <ResponsiveContainer width="100%" height="100%"> */}
				<BarChart
					width={500}
					height={300}
					data={Object.values(prepSalesHistory(sales))}
					margin={{
						top: 5,
						right: 30,
						left: 20,
						bottom: 5,
					}}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="name" />
					<YAxis />
					<Tooltip />
					<Legend />
					<Bar dataKey="breakfast" fill="#880E4F" />
					<Bar dataKey="lunch" fill="#8884d8" />
					<Bar dataKey="dinner" fill="#004D40" />
					<Bar dataKey="snack" fill="#ccc" />
				</BarChart>
				{/*    </ResponsiveContainer> */}
				{/* 	<LineChart */}
				{/* 		data={Object.values(prepSalesHistory(sales))} */}
				{/* 		margin={{ */}
				{/* 			top: 5, */}
				{/* 			right: 30, */}
				{/* 			left: 20, */}
				{/* 			bottom: 5, */}
				{/* 		}} */}
				{/* 	> */}
				{/* 		<CartesianGrid strokeDasharray="3 3" /> */}
				{/* 		<XAxis dataKey="name" /> */}
				{/* 		<YAxis */}
				{/* 			tickFormatter={(tick) => { */}
				{/* 				return tick.toLocaleString(); */}
				{/* 			}} */}
				{/* 		/> */}
				{/* 		<Tooltip /> */}
				{/* 		<Legend /> */}
				{/* 		<Line */}
				{/* 			type="monotone" */}
				{/* 			dataKey="breakfast" */}
				{/* 			stroke="#8884d8" */}
				{/* 			activeDot={{ r: 8 }} */}
				{/* 		/> */}
				{/* 		<Line type="monotone" dataKey="lunch" stroke="#82ca9d" /> */}
				{/* 		<Line type="monotone" dataKey="dinner" stroke="#f44336" /> */}
				{/* 		<Line type="monotone" dataKey="snack" stroke="#ccc" /> */}
				{/* 	</LineChart> */}
			</ResponsiveContainer>
		</div>
	);
};
