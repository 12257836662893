import {
	Autocomplete,
	Avatar,
	Button,
	Checkbox,
	FileInput,
	Group,
	Select,
	TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { v4 as uuidV4 } from "uuid";
import _, { omit, times, upperFirst } from "lodash";
import React, { useEffect, useState } from "react";
import { DatePicker, DatePickerInput } from "@mantine/dates";
import { NavBar } from "../../components/AdminNavBar";
import { createAccountTemplate } from "../../utils";
import { httpsCallable } from "firebase/functions";
import { firestore, functions, storage } from "../../firebase";
import {
	ref as storageRef,
	getDownloadURL,
	uploadBytesResumable,
	uploadBytes,
} from "firebase/storage";
import { useNavigate, useParams } from "react-router-dom";
import { IconUpload } from "@tabler/icons";
import {
	useFirestoreDocument,
	useFirestoreQuery,
	useFirestoreQueryData,
} from "@react-query-firebase/firestore";
import { collection, doc, getDoc, query, updateDoc } from "firebase/firestore";
import { Account, AccountTypeSubGroup, MealLiabilityHolder, School } from "../../types";

export const accountTypes: Account["type"][] = [
	"student",
	"visitor",
	"teacher",
	"staff",
	"school",
	"other",
];

export const secondayIdTypes: Account["ownerSecondaryIdType"][] = [
	"school-id",
	"passport",
	"drivers-license",
	"national-identification",
	"other",
];

export const schoolGrades = [
	"ec",
	"p1",
	"p2",
	"p3",
	"p4",
	"p5",
	"m1",
	"m2",
	"d1",
	"d2",
	...times(12, (n) => `year ${n}`),
];

export const mealLiabilityOptions: MealLiabilityHolder[] = ["main", "school"];

export function getAccountTypsSubGroups(
	type: Account["type"]
): { label: string; value: AccountTypeSubGroup }[] {
	let groups: AccountTypeSubGroup[] = [];
	switch (type) {
		case "student":
			groups = ["day", "boarding"];
			break;
		case "staff":
			groups = ["support", "auxiliary"];
			break;
		default:
			groups = ["none", "other"];
			break;
	}

	return groups.map((g) => ({ label: upperFirst(g), value: g }));
}

type CreateAccountProps = {
	isEditing?: boolean; // whether or not we are in edit mode; default is false
};

export default function CreateAccount({
	isEditing = false,
}: CreateAccountProps) {
	const navigate = useNavigate();
	const params = useParams();

	const [loading, setLoading] = useState(false);

	const form = useForm<Account>({
		initialValues: {
			...createAccountTemplate("894nfjisb8wcheosi", "student", "day"),
		},
	});
	// If is editing, then the latest document and set to state;

	// const schoolRef = query(collection(firestore, "schools")) as any;
	const ref = query(collection(firestore, "schools")) as any;
	const schoolsQuery = useFirestoreQuery<School>(["schools"], ref, {
		subscribe: true,
	});
	// const schoolsQuery = useFirestoreQueryData<School>(["schools"], schoolRef);

	useEffect(() => {
		if (isEditing === true) {
			getDoc(doc(collection(firestore, "accounts"), params.accountId))
				.then((account) => {
					if (!account.exists) {
						console.error("Account does not exist.");
						alert("This is an invalid Account.");
						return navigate("/accounts");
					}

					const accountForm = account.data();
					form.setValues({
						...form.values,
						...formatFirebaseDates(accountForm as Account),
					});
				})
				.catch((error) => {
					console.error({ error });
				});
		}
	}, []);

	useEffect(() => {
		if (isEditing === true && !params.accountId) {
			alert("Cannot edit this account. Invalid Account.");
			throw new Error("Cannot edit an account that does not exist");
		}

		// getAccount()

		// when form type changes, update the subgroup to change too the first option from the options function
		return form.setFieldValue(
			"typeSubGroup",
			getAccountTypsSubGroups(form.values.type)[0].value
		);
	}, [form.values.type]);



	if (schoolsQuery.isLoading) {
		return <div>Loading Schools...</div>;
	}

	const schools = schoolsQuery.data?.docs.map(d => d.data()) as unknown as School[] || []
	console.log({ schools })

	const createAccount = (values: Account) => {
		if (values.organizationId === "") {
			return alert(
				"Please make sure you have selected the school / organzation for this account."
			);
		}
		if (values.ownerPhotoURL.length === 0) {
			const confirmation = window.confirm(
				"This account does not have a photo. Are you sure you want to continue?"
			);

			if (!confirmation) {
				return;
			}
			// return alert("Please upload a valid photo URL for the account.");
		}
		setLoading(true);

		if (isEditing && params.accountId) {
			const accRef = doc(collection(firestore, "accounts"), params.accountId);
			updateDoc(accRef, {
				..._.omit(values, ["createdAt", "balance", "snackBalance"]),
				// balance: Number(values.balance),
				// snackBalance: Number(values.snackBalance),
				updatedAt: new Date(),
			})
				.then((res) => {
					setLoading(false);
					alert("Account updated!");
					navigate("/admin/accounts");
				})
				.catch((error) => {
					setLoading(false);
					console.error("Error: ", error);
					alert("Error updating the account. Please try again.");
				});
			return;
		}
		httpsCallable(
			functions,
			"createAccount"
		)({
			account: values,
		})
			.then((res) => {
				// @ts-ignore
				if (res.data.status !== 200) {
					throw new Error();
				}

				setLoading(false);
				console.log(res);
				alert("Account created");
				navigate("/admin/accounts");
			})
			.catch((error) => {
				console.error({ error });
				setLoading(false);
				alert("Error creating account. Please try again.");
			});
	};

	const onFileSelected = async (file: File) => {
		const imageRef = storageRef(storage, `photos/${file.name}_${uuidV4()}`);

		const uploadTask = await uploadBytes(imageRef, file);
		const photoURL = await getDownloadURL(uploadTask.ref);

		console.log("Photo uploaded!", photoURL);
		form.setFieldValue("ownerPhotoURL", photoURL);
	};

	console.log({schools});

	return (
		<div>
			<NavBar title="Create Account" />

			<form onSubmit={form.onSubmit(createAccount)} className="space-y-4">
				{form.values.ownerPhotoURL.length > 0 && (
					<Avatar
						src={form.values.ownerPhotoURL}
						size="xl"
						radius={100}
						alt="photo"
					/>
				)}
				<FileInput
					onChange={onFileSelected}
					label="Owner Photo"
					placeholder="Your photo"
					icon={<IconUpload size={14} />}
					accept="image/png,image/jpeg"
				/>
				<TextInput
					required
					label="Owner Full Name"
					placeholder="My Full Name"
					{...form.getInputProps("ownerFullName")}
				/>
				<TextInput
					required
					label="Account Name (optional)"
					placeholder="Awesome account name"
					{...form.getInputProps("name")}
				/>
				<TextInput
					required
					label="Email"
					placeholder="your@email.com"
					{...form.getInputProps("ownerEmail")}
				/>
				<TextInput
					label="Owner Telephone"
					placeholder="+255777888888"
					{...form.getInputProps("ownerTelephone")}
				/>

				{/* <Autocomplete */}
				{/* 	required */}
				{/* 	label="School Name / Organization" */}
				{/* 	placeholder="Awesome School" */}
				{/* 	{...form.getInputProps("organization")} */}
				{/* 	data={[ */}
				{/* 		"UWCEA Arusha", */}
				{/* 		"International School of Moshi (ISM)", */}
				{/* 		"St. Constantine’s International School", */}
				{/* 	]} */}
				{/* /> */}
				<Select
					label="School Name / Organization"
					placeholder="Pick one"
					required
					value={form.values.organizationId}
					onChange={(uid: string) => {
						form.setFieldValue("organizationId", uid);
						form.setFieldValue(
							"organization",
							schools.find((s) => s.uid === uid)?.name || ""
						);
					}}
					data={schools.map((s) => ({
						label: upperFirst(s.name),
						value: s.uid,
					}))}
				/>

				<Select
					label="Account Type"
					placeholder="Pick one"
					{...form.getInputProps("type")}
					data={accountTypes.map((acc) => ({
						value: acc,
						label: upperFirst(acc),
					}))}
				/>

				<Select
					label="Account Type Group"
					placeholder="Pick one"
					{...form.getInputProps("typeSubGroup")}
					data={getAccountTypsSubGroups(form.values.type)}
				/>

				{form.values["typeSubGroup"] === "boarding" && (
					<TextInput
						label="Dormitory Name"
						{...form.getInputProps("dormitory")}
						placeholder="F, A, 22, ..."
					/>
				)}

				<Autocomplete
					label="Year group (students only)"
					placeholder="Select the year group"
					{...form.getInputProps("grade")}
					data={schoolGrades}
				/>

				<Select
					label="Secondary ID Type"
					placeholder="Pick one"
					{...form.getInputProps("ownerSecondaryIdType")}
					data={secondayIdTypes.map((id) => ({
						value: id,
						label: upperFirst(id.replace("-", " ")),
					}))}
				/>
				<TextInput
					label="Seconday Id"
					placeholder=""
					{...form.getInputProps("ownerSecondaryId")}
				/>
				<Checkbox
					mt="md"
					label="This account is active"
					{...form.getInputProps("active", { type: "checkbox" })}
				/>
				<Checkbox
					mt="md"
					label="This account is Postpaid (default accounts are prepaid)"
					{...form.getInputProps("postpaid", { type: "checkbox" })}
				/>

				<DatePickerInput
					placeholder=""
					{...form.getInputProps("expiryDate", { type: "input" })}
					label="Account Expiration Date"
					required
				/>

				<div>
					<h1 className="text-xl">Meal Liabilities</h1>
					<span className="text-sm">
						Set ahead of time who is responsible for each accounts meals
					</span>

					<div className="pl-6 space-y-2 mt-2">
						<Select
							label="Breakfast"
							placeholder="Pick account payer type"
							required
							data={mealLiabilityOptions.map((mL) => ({
								value: mL,
								label: upperFirst(mL),
							}))}
							{...form.getInputProps("metadata.mealLiabilities.breakfast")}
						/>

						<Select
							label="Lunch"
							placeholder="Pick account payer type"
							required
							data={mealLiabilityOptions.map((mL) => ({
								value: mL,
								label: upperFirst(mL),
							}))}
							{...form.getInputProps("metadata.mealLiabilities.lunch")}
						/>

						<Select
							label="Dinner"
							placeholder="Pick account payer type"
							required
							data={mealLiabilityOptions.map((mL) => ({
								value: mL,
								label: upperFirst(mL),
							}))}
							{...form.getInputProps("metadata.mealLiabilities.dinner")}
						/>
					</div>
				</div>

				{!isEditing && (
					<>
						<div style={{ height: "20px" }} />
						<hr />

						<h3 className="text-xl mt-4">Initial Account Settings</h3>
						<TextInput
							required
							label="Initial Balance"
							type={"number"}
							description="This is balance will be deposited into their main (meals) account immediately"
							{...form.getInputProps("balance", { type: "input" })}
						/>

						<TextInput
							required
							label="Snack Balance"
							type={"number"}
							description="This is balance will be deposited into their snacks account immediately"
							{...form.getInputProps("snackBalance", { type: "input" })}
						/>
					</>
				)}

				<Group position="right" mt="md">
					<Button loading={loading} className="bg-sky-700" type="submit">
						{isEditing ? "Update Account" : "Submit"}
					</Button>
				</Group>
			</form>
		</div>
	);
}

export function formatFirebaseDates<T extends Object>(obj: T): T {
	return Object.keys(obj).reduce(
		(prev, curr) => {
			// @ts-ignore
			if (obj[curr].toDate !== undefined) {
				// this is a firestore data object;
				return {
					...prev,
					// @ts-ignore
					[curr]: obj[curr].toDate(),
				};
			}

			return prev;
		},
		{ ...obj }
	);
}

// TODO: Should we set up a temporary password for every account we are creating??
// FIXME: For existing email address/accounts, show meaningful errors when creating a new account with the same credentials
