import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { firestore } from "../../firebase";
import { Account, Card } from "../../types";
import { CardItem } from "./AccountCards";

export default function PrintableCard({ }) {
  const { search } = useLocation();
  const { accountId } = useParams();
  const [card, setCard] = useState<Card | null>(null);
  const [account, setAccount] = useState<Account | null>();

  const id = React.useMemo(
    () => new URLSearchParams(search).get("id"),
    [search]
  );
  useEffect(() => {
    console.log(id, accountId);
    getDoc(doc(firestore, "accounts", accountId || "")).then((res) =>
      setAccount(res.data() as Account)
    );

    getDoc(doc(firestore, `accounts`, accountId || "", "cards", id || "")).then(
      (res) => setCard(res.data() as Card)
    );
  }, []);

  return (
    <div>{account && card && <CardItem card={card} account={account} />}</div>
  );
}
