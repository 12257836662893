import React, { useEffect, useMemo, useState, Fragment } from "react";
import {
	Navbar,
	Group,
	Code,
	ScrollArea,
	createStyles,
	AppShell,
	Button,
	Box,
	ThemeIcon,
	ActionIcon,
} from "@mantine/core";
import {
	Gauge,
	PresentationAnalytics,
	Users,
	Adjustments,
	BuildingBank,
	PaperBag,
	BrandShopee,
	ListDetails,
	School,
	Coin,
	X,
	Menu2,
	ZoomMoney
} from "tabler-icons-react";
import { Dialog, Transition } from '@headlessui/react'
// import {
//   Bars3Icon,
//   CalendarIcon,
//   ChartPieIcon,
//   DocumentDuplicateIcon,
//   FolderIcon,
//   HomeIcon,
//   UsersIcon,
//   XMarkIcon,
// } from '@heroicons/react/24/outline'
import { UserButton } from "../../components/UserButton";
import { LinksGroup } from "../../components/NavbarLinksGroup";
import {
	Link,
	NavigateFunction,
	Outlet,
	useLocation,
	useNavigate,
} from "react-router-dom";
import { auth } from "../../firebase";
import { signOut } from "firebase/auth";
import {
	IconBasket,
	IconBolt,
	IconCross,
	IconPaperBag,
	IconUserPlus,
	IconX,
} from "@tabler/icons";
import { useAtom } from "jotai";
import { mobileMenuStatus } from "../../atoms/mobile-nav-menu";
import { useViewportSize } from "@mantine/hooks";

// <<<<<<< HEAD
const navigationLinks = [
	{ label: "Dashboard", icon: Gauge, path: "" },
	{ label: "Accounts", icon: Users, path: "accounts" },
	{ label: "Pricing", icon: ZoomMoney, path: "prices", current: false },
	{ label: "Users", icon: BuildingBank, path: "staff" },
	{ label: "Daily Snacks", icon: PaperBag, path: "daily-snacks" },
	{ label: "Snacks List", icon: ListDetails, path: "snacks" },
	{ label: "Sales", icon: BrandShopee, path: "sales" },
	{ label: "Deposits", icon: Coin, path: "deposits" },
	{ label: "Inventory", icon: Coin, path: "inventory", current: false },
	{ label: "Schools", icon: School, path: "schools" },
	{ label: "Reports", icon: PresentationAnalytics, path: "reports" },
	{
		label: "Deduplicator", icon: () => (
			<svg 
				xmlns="http://www.w3.org/2000/svg" 
				width="24" 
				height="24" 
				viewBox="0 0 24 24" 
				fill="none" 
				stroke="currentColor" 
				strokeWidth="2" 
				strokeLinecap="round" strokeLinejoin="round" 
				className="lucide lucide-skull">
					<circle cx="9" cy="12" r="1" />
					<circle cx="15" cy="12" r="1" />
					<path d="M8 20v2h8v-2" />
				<path d="m12.5 17-.5-1-.5 1h1z" />
				<path d="M16 20a2 2 0 0 0 1.56-3.25 8 8 0 1 0-11.12 0A2 2 0 0 0 8 20" />
				</svg>
		), path: "deduplicator"
	},
	// { label: "Settings", icon: Adjustments, path: "settings" },
	// { label: "Menu", icon: ListDetails, path: "menu" },
	// =======
	// const navigationLinks = [
	// { label: "Dashboard", icon: Gauge, path: "", current: false },
	// { label: "Accounts", icon: Users, path: "accounts", current: false },
	// { label: "Prices", icon: ZoomMoney, path: "prices", current: false },
	// { label: "Users", icon: BuildingBank, path: "staff", current: false },
	// { label: "Snacks", icon: PaperBag, path: "snacks", current: false },
	// { label: "Sales", icon: BrandShopee, path: "sales", current: false },
	// { label: "Deposits", icon: Coin, path: "deposits", current: false },
	// { label: "Inventory", icon: Coin, path: "inventory", current: false },
	// { label: "Schools", icon: School, path: "schools", current: false },
	// { label: "Menu", icon: ListDetails, path: "menu", current: false },
	// { label: "Reports", icon: PresentationAnalytics, path: "reports", current: false },
	// { label: "Settings", icon: Adjustments, path: "settings", current: false },
	// >>>>>>> 46f42cf (add pricing support)
	// {
	// 	label: "Accounts",
	// 	icon: Notes,
	// 	initiallyOpened: true,
	// 	links: [
	// 		{ label: "Overview", link: "/" },
	// 		{ label: "Forecasts", link: "/" },
	// 		{ label: "Outlook", link: "/" },
	// 		{ label: "Real time", link: "/" },
	// 	],
	// },
	// {
	// 	label: "Releases",
	// 	icon: CalendarStats,
	// 	links: [
	// 		{ label: "Upcoming releases", link: "/" },
	// 		{ label: "Previous releases", link: "/" },
	// 		{ label: "Releases schedule", link: "/" },
	// 	],
	// },
	// { label: "Analytics", icon: PresentationAnalytics },
	// { label: "Contracts", icon: FileAnalytics },
	// {
	// 	label: "Security",
	// 	icon: Lock,
	// 	links: [
	// 		{ label: "Enable 2FA", link: "/" },
	// 		{ label: "Change password", link: "/" },
	// 		{ label: "Recovery codes", link: "/" },
	// 	],
	// },
];

const useStyles = createStyles((theme) => ({
	navbar: {
		backgroundColor:
			theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.white,
		paddingBottom: 0,
	},

	header: {
		padding: theme.spacing.md,
		paddingTop: 0,
		marginLeft: -theme.spacing.md,
		marginRight: -theme.spacing.md,
		color: theme.colorScheme === "dark" ? theme.white : theme.black,
		borderBottom: `1px solid ${theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
			}`,
	},

	links: {
		marginLeft: -theme.spacing.md,
		marginRight: -theme.spacing.md,
	},

	linksInner: {
		paddingTop: theme.spacing.xl,
		paddingBottom: theme.spacing.xl,
	},

	footer: {
		marginLeft: -theme.spacing.md,
		marginRight: -theme.spacing.md,
		borderTop: `1px solid ${theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
			}`,
	},
}));

export function NestedNavbar({
	activeLink,
	hidden,
}: {
	activeLink: string;
	hidden: boolean;
}) {
	const { classes } = useStyles();
	const [isHidden, setIsHidden] = useAtom(mobileMenuStatus);
	const { height, width } = useViewportSize();
	// const [menuStatus, setMenuStatus] = useAtom(mobileMenuStatus);

	useEffect(() => {
		console.log(width);
		if (width < 500) {
			return setIsHidden("closed");
		}
		setIsHidden(hidden || width < 500 ? "closed" : "open");
	}, [hidden, width]);

	const links = navigationLinks.map((item) => (
		<LinksGroup
			isActive={activeLink === item.path}
			{...item}
			key={item.label}
		/>
	));

	const logout = () => {
		if (window.confirm("Are you sure you want to signout?")) {
			signOut(auth).catch((error) => {
				alert("Error signing out. Contact support.");
			});
		}
	};

	if (isHidden === "closed") {
		return null;
	}

	return (
		<Navbar
			width={{ sm: 220 }}
			// hidden={true}
			// hiddenBreakpoint={"md"}
			p="md"
			className={classes.navbar}
		>
			<Navbar.Section className={classes.header}>
				<Group position="apart">
					<Logo />
					{/* <Code sx={{ fontWeight: 700 }}>v0.1.2</Code> */}
					<ActionIcon
						onClick={() => setIsHidden("closed")}
						className="md:hidden"
						variant="transparent"
					>
						<IconX size={18} />
					</ActionIcon>
				</Group>
			</Navbar.Section>

			<Navbar.Section grow className={classes.links} component={ScrollArea}>
				<div className={classes.linksInner}>
					{navigationLinks.map((item) => {
						const isActive = activeLink === item.path;
						return (
							<Link key={item.path} to={`/admin/${item.path}`}>
								<Box
									sx={{ display: "flex", alignItems: "center" }}
									className={`hover:bg-gray-100 ${isActive ? "bg-gray-200" : ""
										}`}
									py={8}
									my={4}
									pl={16}
								>
									<ThemeIcon variant="light" size={30}>
										<item.icon size={18} />
									</ThemeIcon>
									<Box ml="md">{item.label}</Box>
								</Box>
							</Link>
						);
					})}
				</div>
			</Navbar.Section>

			<Navbar.Section className={classes.footer}>
				{/* <UserButton
					image="https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?ixid=MXwxMjA3fDsB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=255&q=80"
					name="Ann Nullpointer"
					email="anullpointer@yahoo.com"
				/> */}
				<Button variant="light" onClick={logout}>
					Sign Out
				</Button>
			</Navbar.Section>
		</Navbar>
	);
}

function Logo() {
	return (
		<>
			<h1 className="text-xl mt-2">Chakula Admin</h1>
		</>
	);
}

export function AdminLayout2() {
	const location = useLocation();
	const isOnline = useOnlineStatus();
	const navigate = useNavigate();

	const { height, width } = useViewportSize();
	const [_, setMenuStatus] = useAtom(mobileMenuStatus);
	const activeLink = useMemo(() => {
		width < 500 && setMenuStatus("closed");
		return location.pathname.split("/")[2];
	}, [location]);

	console.log({ activeLink, printable: location.pathname.includes("print") });
	return (
		<AppShell
			padding="md"
			// navbar={<Navbar width={{ base: 300 }} height={500} p="xs">{/* Navbar content */}</Navbar>}
			navbar={
				<NestedNavbar
					hidden={location.pathname.includes("public")}
					activeLink={activeLink}
				/>
			}
			// header={<Header height={60} p="xs">{/* Header content */}</Header>}
			styles={(theme) => ({
				main: {
					backgroundColor:
						theme.colorScheme === "dark"
							? theme.colors.dark[8]
							: theme.colors.gray[0],
				},
			})}
		>
			{!isOnline && (
				<div className="bg-amber-600 text-white p-1">
					Not connected to the internet
				</div>
			)}
			<Outlet />
			<ActionsFAB navigate={navigate} />
		</AppShell>
	);
}

function ActionsFAB({ navigate }: { navigate: NavigateFunction }) {
	const [expanded, setExpanded] = useState(false);
	const toggleExpanded = () => setExpanded((s) => !s);

	const actionBtn = (label: string, Icon: any, onPress: () => void) => (
		<div className="flex items-center space-x-4 pr-3">
			<div className="py-1 px-3 bg-gray-300 rounded-md">{label}</div>
			<ActionIcon
				// size="xl"
				radius="xl"
				className="bg-blue-800 p-2 h-10 w-10 shadow-2xl hover:bg-indigo-800"
				onClick={onPress}
				variant="filled"
			>
				<Icon size={34} />
			</ActionIcon>
		</div>
	);
	return (
		<div className="fixed bottom-16 right-10 flex flex-col items-end justify-end ">
			{expanded && (
				<div className="flex flex-col space-y-3 mb-4 justify-end items-end">
					{actionBtn("Create Customer", IconUserPlus, () => {
						navigate("/admin/create-account");
						toggleExpanded();
					})}
					{actionBtn("Create User", IconUserPlus, () => {
						navigate("/admin/register-staff");
						toggleExpanded();
					})}
					{actionBtn("Make a Sale", IconBasket, () => {
						navigate("/admin/sales/create");
						toggleExpanded();
					})}
					{actionBtn("Make a Sale (testing)", IconBasket, () => {
						navigate("/admin/sales/new");
						toggleExpanded();
					})}
					{actionBtn("Dispatch Daily Snacks", IconPaperBag, () => {
						navigate("/admin/daily-snacks");
						toggleExpanded();
					})}
					{actionBtn("New Snack Sales", IconPaperBag, () => {
						navigate("/admin/sell-snacks");
						toggleExpanded();
					})}
				</div>
			)}

			<ActionIcon
				// size="xl"
				radius="xl"
				className="bg-blue-800 p-1 h-14 w-14 shadow-2xl hover:bg-indigo-800"
				onClick={toggleExpanded}
				variant="filled"
			>
				<IconBolt size={34} />
			</ActionIcon>
		</div>
	);
}

const useOnlineStatus = () => {
	const [isOnline, setIsOnline] = useState(navigator.onLine);

	useEffect(() => {
		// update network status
		const handleStatusChange = () => {
			setIsOnline(navigator.onLine);
		};

		// Listen to the online status
		window.addEventListener("online", handleStatusChange);

		// Listen to the offline status
		window.addEventListener("offline", handleStatusChange);

		return () => {
			window.removeEventListener("online", handleStatusChange);
			window.removeEventListener("offline", handleStatusChange);
		};
	}, [isOnline]);

	return isOnline;
};



///////////////////
const navigation = [
	{ name: 'Dashboard', href: '#', icon: Coin, current: true },
	{ name: 'Team', href: '#', icon: Coin, current: false },
	{ name: 'Projects', href: '#', icon: Coin, current: false },
	{ name: 'Calendar', href: '#', icon: Coin, current: false },
	{ name: 'Documents', href: '#', icon: Coin, current: false },
	{ name: 'Reports', href: '#', icon: Coin, current: false },
]
const teams: any[] = [
	// 	{ id: 1, name: 'Heroicons', href: '#', initial: 'H', current: false },
	// 	{ id: 2, name: 'Tailwind Labs', href: '#', initial: 'T', current: false },
	// 	{ id: 3, name: 'Workcation', href: '#', initial: 'W', current: false },
]

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(' ')
}


export function AdminLayout() {
	const navigate = useNavigate();
	const [sidebarOpen, setSidebarOpen] = useState(false)

	return (
		<>
			<div>
				<Transition.Root show={sidebarOpen} as={Fragment}>
					<Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
						<Transition.Child
							as={Fragment}
							enter="transition-opacity ease-linear duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="transition-opacity ease-linear duration-300"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="fixed inset-0 bg-gray-900/80" />
						</Transition.Child>

						<div className="fixed inset-0 flex">
							<Transition.Child
								as={Fragment}
								enter="transition ease-in-out duration-300 transform"
								enterFrom="-translate-x-full"
								enterTo="translate-x-0"
								leave="transition ease-in-out duration-300 transform"
								leaveFrom="translate-x-0"
								leaveTo="-translate-x-full"
							>
								<Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
									<Transition.Child
										as={Fragment}
										enter="ease-in-out duration-300"
										enterFrom="opacity-0"
										enterTo="opacity-100"
										leave="ease-in-out duration-300"
										leaveFrom="opacity-100"
										leaveTo="opacity-0"
									>
										<div className="absolute left-full top-0 flex w-16 justify-center pt-5">
											<button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
												<span className="sr-only">Close sidebar</span>
												<X className="h-6 w-6 text-white" aria-hidden="true" />
											</button>
										</div>
									</Transition.Child>
									{/* Sidebar component, swap this element with another sidebar if you like */}
									<div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2 pt-8">
										<Logo />
										<nav className="flex flex-1 flex-col">
											<ul role="list" className="flex flex-1 flex-col gap-y-7">
												<li>
													<ul role="list" className="-mx-2 space-y-1">
														{navigationLinks.map((item) => (
															<li key={item.label}>
																<Link key={item.path} to={`/admin/${item.path}`}
																	className={classNames(
																		item.current
																			? 'bg-gray-50 text-indigo-600'
																			: 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
																		'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
																	)}
																>
																	<item.icon
																		className={classNames(
																			item.current ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
																			'h-6 w-6 shrink-0'
																		)}
																		aria-hidden="true"
																	/>
																	{item.label}
																</Link>
															</li>
														))}
													</ul>
												</li>
												<li>
													<div className="text-xs font-semibold leading-6 text-gray-400">Quick Actions</div>
													<ul role="list" className="-mx-2 mt-2 space-y-1">
														{teams.map((team) => (
															<li key={team.name}>
																<a
																	href={team.href}
																	className={classNames(
																		team.current
																			? 'bg-gray-50 text-indigo-600'
																			: 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
																		'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
																	)}
																>
																	<span
																		className={classNames(
																			team.current
																				? 'text-indigo-600 border-indigo-600'
																				: 'text-gray-400 border-gray-200 group-hover:border-indigo-600 group-hover:text-indigo-600',
																			'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white'
																		)}
																	>
																		{team.initial}
																	</span>
																	<span className="truncate">{team.name}</span>
																</a>
															</li>
														))}
													</ul>
												</li>
											</ul>
										</nav>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</Dialog>
				</Transition.Root>

				{/* Static sidebar for desktop */}
				<div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
					{/* Sidebar component, swap this element with another sidebar if you like */}
					<div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pt-8">
						<Logo />
						<nav className="flex flex-1 flex-col">
							<ul role="list" className="flex flex-1 flex-col gap-y-7">
								<li>
									<ul role="list" className="-mx-2 space-y-1">
										{navigationLinks.map((item) => (
											<li key={item.label}>
												<Link key={item.path} to={`/admin/${item.path}`}
													className={classNames(
														item.current
															? 'bg-gray-50 text-indigo-600'
															: 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
														'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
													)}
												>
													<item.icon
														className={classNames(
															item.current ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
															'h-6 w-6 shrink-0'
														)}
														aria-hidden="true"
													/>
													{item.label}
												</Link>
											</li>
										))}
									</ul>
								</li>
								<li>
									<div className="text-xs font-semibold leading-6 text-gray-400">Quick Actions</div>
									<ul role="list" className="-mx-2 mt-2 space-y-1">
										{teams.map((team) => (
											<li key={team.name}>
												<a
													href={team.href}
													className={classNames(
														team.current
															? 'bg-gray-50 text-indigo-600'
															: 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
														'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
													)}
												>
													<span
														className={classNames(
															team.current
																? 'text-indigo-600 border-indigo-600'
																: 'text-gray-400 border-gray-200 group-hover:border-indigo-600 group-hover:text-indigo-600',
															'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white'
														)}
													>
														{team.initial}
													</span>
													<span className="truncate">{team.name}</span>
												</a>
											</li>
										))}
									</ul>
								</li>
								{/*<li className="-mx-6 mt-auto">
									<a
										href="#"
										className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50"
									>
										<img
											className="h-8 w-8 rounded-full bg-gray-50"
											src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
											alt=""
										/>
										<span className="sr-only">Your profile</span>
										<span aria-hidden="true">Tom Cook</span>
									</a>
								</li>*/}
							</ul>
						</nav>
					</div>
				</div>

				<div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden no-print">
					<button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
						<span className="sr-only">Open sidebar</span>
						<Menu2 className="h-6 w-6" aria-hidden="true" />
					</button>
					<div className="flex-1 text-sm font-semibold leading-6 text-gray-900 no-print">Chakula Platform</div>
					{/*<a href="#">
						<span className="sr-only">Your profile</span>
						<img
							className="h-8 w-8 rounded-full bg-gray-50"
							src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
							alt=""
						/>
					</a>*/}
				</div>

				<main className="pb-10 pt-4 lg:pl-72">
					<div className="px-4 sm:px-6 lg:px-8">
						<Outlet />
						<ActionsFAB navigate={navigate} />
					</div>
				</main>
			</div>
		</>
	)
}
