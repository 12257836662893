import { Button, Table } from "@mantine/core";
import { format } from "date-fns";
import { httpsCallable } from "firebase/functions";
import { sortBy, upperFirst } from "lodash";
import { action } from "mobx";
import React, {
	useEffect,
	useMemo,
	useState,
} from "react";
import { Trash } from "tabler-icons-react";
import { auth, firestore, functions } from "../firebase";
import { Account, MealSaleItem, Refill, Sale } from "../types";
import { groupSaleDuplicates, isNotDeleted, isSale, isSnackSale, safelyParseJSON } from "../utils";
import { formatFirebaseDates } from "./admin/CreateAccount";

export default function PublicAccountSummary() {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [account, setAccount] = useState<Account | null>(null);
	const [activities, setActivities] = useState<(Sale | Refill)[]>([]);

	// state for the calcuated balance totals;
	const [mainAccountCalculatedBalance, setMainAccountCalculatedBalance] =
		useState(0);
	const [snackAccountCalculatedBalance, setSnackAccountCalculatedBalance] =
		useState(0);

	const isAuthenticated = auth.currentUser !== null;

	// const [activeMenu, setActiveMenu] = useState<WeekMenu | null>(null);

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const accountId = params.get("id");
		console.log(accountId, window.location);

		httpsCallable(
			functions,
			"getAccountSummary"
		)({ accountId })
			.then((res) => {
				// console.log({ res });
				const response = res.data as any;
				const account = JSON.parse(response.data) as Account;
				// console.log({ account: formatFirebaseDates(account) });
				setAccount(account);
				// setAccount(formatFirebaseDates(response.data as unknown as Account));
			})
			.catch((error) => {
				console.error({ error });
				setError(true);
			})
			.finally(() => {
				setLoading(false);
			});

		console.log({ accountId, startDate: new Date("2023-08-8").getTime() })
		httpsCallable(
			functions,
			"getAccountStatement"
		)({ accountId, startDate: new Date("2023-08-08").getTime() })
			.then((res) => {
				const response = JSON.parse(res.data as string) as unknown;
				// @ts-ignore
				setActivities(response.filter(isNotDeleted) as (Sale | Refill)[]);
				console.log({ response });
			})
			.catch((error) => {
				console.error({ error });
				setError(true);
			})
			.finally(() => {
				setLoading(false);
			});

		// getDocs(
		// 	query(
		// 		collection(firestore, "weekly-menus"),
		// 		where("from", "<=", new Date())
		// 	)
		// )
		// 	.then((res) => {
		// 		const menus = res.docs.map((d) =>
		// 			formatFirebaseDates(d.data())
		// 		) as WeekMenu[];
		// 		const results = menus.filter((m) => m.to >= new Date());
		// 		if (results.length > 0) setActiveMenu(results[0]);
		// 	})
		// 	.catch((error) => {
		// 		console.error("unable to load this weeks menu.", error);
		// 	});
	}, []);

	const statementRows = useMemo(() => {
		if (!account) return null;
		let runningBalance = 0;
		let runningSnackBalance = account.initialSnackBalance || 0;
		const rows = sortBy(activities, ["createdAt"]).map((activity, aix) => {
			const saleItem = isSale(activity);
			const saleType: "snack" | "meal" | "none" = isSale(activity) ? isSnackSale(activity) ? "snack" : "meal" : "none"

			const amount = (activity as Sale)?.isOnduty
				? 0
				: activity.amount;
			if (saleItem) {
				const sale = activity as Sale;
				isSnackSale(sale)
					? (runningSnackBalance -= amount)
					: (runningBalance -= amount);
			} else {
				const deposit = activity as Refill;
				deposit.accountType === "snack"
					? (runningSnackBalance += amount)
					: (runningBalance += amount);
			}
			// @ts-ignore
			const parsedActivity = typeof activity.metadata === "string" ? safelyParseJSON(activity.metadata || "{}")?.result : activity.metadata


			const deleteSale = () => {
				if (saleType === "none") {
					return;
				}

				const confirmed = window.confirm("Are you sure you want to delete this sale?");
				if (!confirmed) return;

				let saleId = ""
				if (isSale(activity)) {
					saleId = (activity as Sale).uid
				}
				// return console.log({saleId, saleType})


				httpsCallable(
					functions,
					"deleteSale"
				)({ saleId, saleType })
					.then((res) => {
						console.log({ res });
						// remove the sale from the list of sales;
						setActivities((sales) => sales.filter((s) => (s as Sale).uid !== saleId));
						alert("Sale has been deleted and account has been refunded.");
					})
					.catch((error) => {
						console.error({ error });
						alert(
							"There was an error deleting the sale. Please check your internet connection and contact support if the issue persists."
						);
					});
			}

			console.log({activity})
			// @ts-ignore
			const snacksList = isSale(activity) && isSnackSale(activity) ? typeof activity.items === "string" ? JSON.parse(activity.items).map(item => item.name).join(", ") : activity.items?.map(item => item.name).join(", ") : ""
			return (
				<tr key={aix}>
					<td>
						{/*// {activity?.deleted && <Trash />} */}
						{format(new Date(activity.createdAt), "dd MMMM yyyy, HH:mm:ss")} <br />
						{/* @ts-ignore */}
						{/* isAuthenticated && activity.referenceId */}
						{snacksList}
					</td>
					{/* <td>{JSON.stringify(activity.createdAt)}</td> */}
					<td>
						{isSnackSale(activity as Sale) ? "Snack" : saleItem
							? upperFirst((activity as MealSaleItem).category)
							: `${upperFirst(
								(activity as Refill).accountType
							)} Account Deposit`}
						{(activity as Sale)?.isOnduty && !(activity as Sale).metadata.isAccountVisitor && (
							<span className="text-gray-400 text-xs ml-1">(On duty)</span>
						)}
						{/* @ts-ignore TODO: When the data is no longer a string, change this */}
						{parsedActivity?.isAccountVisitor && (
							<span className="text-gray-400 text-xs ml-1">(guest)</span>
						)}

					</td>
					<td>{saleItem ? amount.toLocaleString() : 0}</td>
					<td>{!saleItem ? amount.toLocaleString() : 0}</td>
					<td>{runningBalance.toLocaleString()}</td>
					<td>{runningSnackBalance.toLocaleString()}</td>
					<td>
						{
							isAuthenticated && isSale(activity) && activity.uid.length > 0 &&
							<Trash onClick={deleteSale} className="cursor-pointer" />
						}
					</td>
				</tr>
			);
		});
		setMainAccountCalculatedBalance(runningBalance);
		setSnackAccountCalculatedBalance(runningSnackBalance);
		return rows;
	}, [activities, account]);
	if (loading) {
		return <div>Loading ...</div>;
	}

	// TODO: support for error screens.
	if (!account && error !== null) {
		return <div>Error loading account</div>;
	}

	return (
		<div className="container mx-auto p-5 pt-20 text-gray-800">
			<div className="rounded outline outline-1 outline-gray-500 p-5">
				<div className=" ">
					<div className="">
						<h1 className="text-lg text-gray-500">Account Summary</h1>

						<h1 className="text-4xl text-gray-900">{account?.ownerFullName}</h1>

						<h4 className="text-lg">Account Type: {account?.type} </h4>
						<h4 className="text-lg">Account Group: {account?.typeSubGroup} </h4>
						<h4 className="text-lg">
							Organization / School: {account?.organization}
						</h4>

						{account?.typeSubGroup !== "boarding" && (
							<h4 className="text-2xl">
								Account Balance: Tsh {account?.balance.toLocaleString()} /=
							</h4>
						)}
						<h4 className="text-2xl">
							Snack Balance: Tsh {account?.snackBalance.toLocaleString()} /=
						</h4>
					</div>
				</div>

				{isAuthenticated && account && (
					<AccountBalanceResets
						accountId={account?.uid}
						mainBalance={mainAccountCalculatedBalance}
						snackBalance={snackAccountCalculatedBalance}
					/>
				)}
			</div>

			<h1 className="text-xl mt-6">Account activities statement</h1>
			<Table className="mt-1" striped highlightOnHover>
				{/* <caption>Some elements from periodic table</caption> */}
				<thead>
					<tr>
						<th>Date</th>
						<th>Description</th>
						<th>Debit</th>
						<th>Credit</th>
						<th>Main Balance</th>
						<th>Snack Balance</th>
						<th></th>
					</tr>
				</thead>

				<tbody>
					{account && statementRows}
				</tbody>
			</Table>

			{/* {activeMenu !== null && ( */}
			{/* 	<div> */}
			{/* 		<h1 className="text-lg">This weeks Menu</h1> */}
			{/* 		<WeekMenuItem menu={activeMenu} /> */}
			{/* 	</div> */}
			{/* )} */}
		</div>
	);
}

type AccountBalanceResetsProps = {
	accountId: string;
	mainBalance: number;
	snackBalance: number;
};

const AccountBalanceResets: React.FC<AccountBalanceResetsProps> = ({
	accountId,
	mainBalance,
	snackBalance,
}) => {
	const [loading, setLoading] = useState(false);

	const setBalanceBalance = (balanceType: "main" | "snack") => {
		if (
			!window.confirm(
				`Are you sure you want to set the new ${balanceType} balance to ${eval(
					`${balanceType}Balance`
				)}? This can not be undone.`
			)
		)
			return;
		setLoading(false);
		httpsCallable(
			functions,
			"setAccountBalance"
		)({
			accountId,
			balanceType,
			balance: balanceType === "main" ? mainBalance : snackBalance,
		})
			.then((res) => {
				setLoading(false);
				alert(`${upperFirst(balanceType)} balance have been reset`);
			})
			.catch((error) => {
				setLoading(false);
				console.error({ error });
				alert(
					`An error occured while resetting the ${balanceType} balance for this account. Please contact support!`
				);
			});
	};
	return (
		<div className="row space-x-4">
			{loading ? (
				"Loading ...."
			) : (
				<>
					<Button variant="outline" onClick={() => setBalanceBalance("main")}>
						Reset Main Account Balance to {mainBalance}
					</Button>
					<Button variant="outline" onClick={() => setBalanceBalance("snack")}>
						Reset Snack Account Balance to {snackBalance}
					</Button>
				</>
			)}
		</div>
	);
};


