import { v1 as uuidV1 } from "uuid";
import {
	Button,
	Checkbox,
	Radio,
	Select,
	SimpleGrid,
	Table,
	Text,
} from "@mantine/core";
import { DatePicker, DatePickerInput, DatePickerValue, DatesRangeValue } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { endOfDay, format, startOfDay } from "date-fns";
import { collection, getDocs, query, where } from "firebase/firestore";
import { chunk, groupBy, times, uniq, upperFirst } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { firestore } from "../firebase";
import {
	getAccountTypsSubGroups,
	accountTypes,
	formatFirebaseDates,
} from "./admin/CreateAccount";
import { Account, MealSaleItem, Sale, SaleCategory } from "../types";
import { isMealSale } from "../utils";

export default function PublicSchoolReports() {
	const [reportType, setReportType] = useState<"summary" | "extended">(
		"summary"
	);
	const [sales, setSales] = useState<Sale[]>([]);
	const form = useForm<Partial<Account> & { teachersOnDutyOnly: boolean }>({
		initialValues: {
			type: "staff",
			typeSubGroup: "support",
			grade: "",
			teachersOnDutyOnly: false,
		},
	});

	const [dates, setDates] = useState<DatesRangeValue>([
		new Date(),
		new Date(),
	]);

	// on account type change, clear the subtype
	useEffect(() => {
		// const type = form.values.type;
		// @ts-ignore
		form.setFieldValue("typeSubGroup", "");
	}, [form.values.type]);

	const accountsInSales = useMemo(() => {
		const saleAccounts = sales.map((s) => ({
			type: s.buyerAccountType,
			typeSubGroup: s.buyerAccountTypeSubGroup,
			accountId: s.buyerId,
			accountName: s.buyerAccountName,
		}));
		return [
			// @ts-ignore
			...new Map(
				// @ts-ignore
				saleAccounts.map((item) => [item["accountId"], item])
			).values(),
		];
	}, [sales.length]);

	const search = async () => {
		setSales([]);
		const { type: accountType, teachersOnDutyOnly } = form.values;
		const q = query(
			collection(firestore, "sales"),
			where("createdAt", ">=", startOfDay(dates[0] || new Date())),
			where("createdAt", "<=", endOfDay(dates[1] || new Date())),
			teachersOnDutyOnly
				? where("isOnduty", "==", true)
				: where("buyerAccountType", "==", accountType)
		);
		// purposely not including the typeSubGroup in the query as there was confusion in creating accounts and some results might be missing (???)
		const res = await getDocs(q);
		const saleItems = res.docs
			.map((d) => formatFirebaseDates(d.data()) as Sale)
			.filter((s) => {
				if (
					(form.values.typeSubGroup?.length || 0) > 0 &&
					!teachersOnDutyOnly
				) {
					return s.buyerAccountTypeSubGroup === form.values.typeSubGroup;
				}
				return true;
			});

		const singleSales = deduplicateSales(saleItems);

		setSales(singleSales);
	};

	const dailySales = useMemo(
		() => groupBy(sales, (o) => o.createdAt.toDateString()),
		[sales.length]
	);

	return (
		<div className="container mx-auto">
			<DatePickerInput
				type="range"
				className="no-print"
				label="Choose the dates"
				placeholder="Pick dates range"
				value={dates}
				onChange={setDates}
			/>
			<div>
				<Checkbox
					label="Show only teachers on duty instead"
					className="no-print"
					{...form.getInputProps("teachersOnDutyOnly")}
				/>
			</div>

			{!form.values.teachersOnDutyOnly && (
				<SimpleGrid className="no-print" cols={4}>
					<Select
						label="Account Type"
						placeholder="Pick one"
						{...form.getInputProps("type")}
						data={accountTypes.map((acc) => ({
							value: acc,
							label: upperFirst(acc),
						}))}
					/>

					<Select
						label="Account Type Group"
						placeholder="Pick one"
						clearable
						{...form.getInputProps("typeSubGroup")}
						data={getAccountTypsSubGroups(form.values.type as any)}
					/>
				</SimpleGrid>
			)}
			<Button className="no-print mt-2" onClick={search} variant="outline">
				Generate Summary Report
			</Button>

			<div className="no-print">
				<Radio.Group
					// name="favoriteFramework"
					className="mt-4"
					label="What type of report do you want to render?"
					// description="Pick the right repo"
					// withAsterisk
					value={reportType}
					onChange={(t) => setReportType(t as any)}
				>
					<Radio value="summary" label="Total summaries" />
					<Radio value="extended" label="Extended report" />
				</Radio.Group>
				<div className="h-20" />
			</div>

			<LetterHead />

			{reportType === "summary" && (
				<div className="print-container">
					<Table className="pt-4">
						<thead>
							<tr>
								<th>#</th>
								<th>Name</th>
								<th>Breakfast</th>
								<th>Lunch</th>
								<th>Dinner</th>
							</tr>
						</thead>
						<tbody>
							{accountsInSales.map((acc, idx) => {
								const accountSales = sales.filter(
									(s) => s.buyerId === acc.accountId
								);
								return (
									<tr key={`${acc.accountId}__${idx}`}>
										<td>{idx + 1}</td>
										<td>{acc.accountName}</td>
										<td>
											{
												getAccountSalesPerMeal(
													acc.accountId,
													"breakfast",
													accountSales as unknown as MealSaleItem[]
												).length
											}
										</td>
										<td>
											{
												getAccountSalesPerMeal(
													acc.accountId,
													"lunch",
													accountSales as unknown as MealSaleItem[]
												).length
											}
										</td>
										<td>
											{
												getAccountSalesPerMeal(
													acc.accountId,
													"dinner",
													accountSales as unknown as MealSaleItem[]
												).length
											}
										</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</div>
			)}

			{reportType === "extended" && (
				<div>
					{getWeeksfromDateRange(dates?.[0] as Date, dates?.[1] as Date).map(
						(week, idx) => {
							const weekSummary = getWeeklySummary(
								sales as unknown as MealSaleItem[],
								dates[0] as Date,
								dates[1] as Date
							);
							return (
								<div key={idx} className="pt-8 mb-14">
									<Text>
										From {format(week[0], "MMM dd")} to{" "}
										{format(week[week.length - 1], "MMM dd")}
									</Text>
									<>
										Breakfast: {weekSummary.breakfast.length} <br />
										Lunch: {weekSummary.lunch.length} <br />
										Dinner: {weekSummary.dinner.length} <br />
									</>
									<Table>
										<thead>
											<tr>
												<th>#</th>
												<th>Name</th>
												{week.map((day) => (
													<th key={`${day}__${idx}`}>
														{format(day, "eee, dd")}
													</th>
												))}

												{times(7 - week.length, (n) => (
													<th key={n} style={{ color: "transparent" }}>
														_
													</th>
												))}
											</tr>
										</thead>
										<tbody>
											{accountsInSales.map((acc, adx) => (
												<tr key={`${acc.accountId}__${idx}`}>
													<td>{adx + 1}</td>
													<td>{acc.accountName}</td>
													{week.map((day, dayIdx) => (
														<td key={dayIdx}>
															{dailySales[day.toDateString()]
																?.filter((s) => s.buyerId === acc.accountId)
																.map((s) => (isMealSale(s) && s.category[0] || "").toUpperCase())
																?.join(", ")}
														</td>
													))}
													{/* TODO: Add total weekly sales per row */}
												</tr>
											))}
											{/* <tr></tr> */}
										</tbody>
									</Table>
								</div>
							);
						}
					)}
				</div>
			)}
			{/* <ReportFooter /> */}
		</div>
	);
}

export function deduplicateSales(sales: Sale[]): Sale[] {
	// Create a new field that contains a "Key" that will be used to deduplicate
	const salesWithKey = sales.map((s) => {
		const onDutyUid = s.isOnduty ? uuidV1() : "notTOD";
		return {
			...s,
			_key: `${s.buyerId}__${
				isMealSale(s) ? s.category : "snack"
			}__${s.createdAt.toLocaleDateString()}__${onDutyUid}`,
		};
	});

	// get unique objects by the unique key
	return uniqueObjArray(salesWithKey, "_key");
}

export function getWeeklySummary(sales: MealSaleItem[], from: Date, to: Date) {
	const tsales = sales.filter((s) => s.createdAt >= from && s.createdAt < to);
	return {
		breakfast: tsales.filter((s) => s.category === "breakfast"),
		lunch: tsales.filter((s) => s.category === "lunch"),
		dinner: tsales.filter((s) => s.category === "dinner"),
	};
}

export function getAccountSalesPerMeal(
	accountId: string,
	meal: SaleCategory,
	sales: MealSaleItem[]
): Sale[] {
	return sales.filter((s) => s.buyerId === accountId && s.category === meal);
}

export function getWeeksfromDateRange(from: Date, to: Date): Date[][] {
	const date = new Date(from?.getTime());

	const dates = [];

	while (date <= to) {
		dates.push(new Date(date));
		date.setDate(date.getDate() + 1);
	}

	return chunk(dates, 7);
}

export function LetterHead() {
	return (
		<div className="print-only pb-10">
			<img src={require("../assets/report_header.jpg")} />
		</div>
	);
}

export function ReportFooter() {
	return (
		<div className="print-only pt-8 ">
			<img src={require("../assets/report_footer.jpg")} />
		</div>
	);
}

// TODO: Move to utils
export function uniqueObjArray<T>(objArray: T, field: string): T {
	return [
		// @ts-ignore
		...new Map(objArray.map((item: any) => [item[field], item])).values(),
	] as unknown as T;
}

// For headers and footers with tables: https://medium.com/@Idan_Co/the-ultimate-print-html-template-with-header-footer-568f415f6d2a
