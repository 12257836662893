import { Paper, SimpleGrid } from "@mantine/core";
import { useFirestoreQuery } from "@react-query-firebase/firestore";
import { collection } from "firebase/firestore";
import { addDays, format, subDays } from "date-fns";
import { query } from "firebase/firestore";
import React from "react";
import { firestore } from "../../firebase";
import { NavBar } from "../../components/AdminNavBar";
import { calculateInvoiceTotal } from "../../utils";
import { formatFirebaseDates } from "./CreateAccount";
import { ChevronRight } from "tabler-icons-react";
import { useNavigate } from "react-router-dom";
import { Invoice } from "../../types";

const DOMAIN_ORIGIN = window.location.origin;

const reports = [
	{
		title: "Staff School Reports",
		description: "School reports summary view",
		link: `${DOMAIN_ORIGIN}/public/staff-reports`,
	},
	{
		title: "Visitors School Reports",
		description: "Visitor reports summary view",
		link: `${DOMAIN_ORIGIN}/public/visitors-report`,
	},
	{
		title: "Boarders Missed Dinners",
		description:
			"Show missed dinner meals by boarders - filtering by date is supported within 1 week.",
		link: `${DOMAIN_ORIGIN}/public/boarding-missed-dinners`,
	},
	{
		title: "Teachers on Duty",
		description:
			"Show teachers on duty - including staff on duty for dinners and breakfasts",
		link: `${DOMAIN_ORIGIN}/public/teachers-on-duty`,
	},
	{
		title: "Daily Snacks : Staff",
		description: "Show daily snacks dispatched to staff",
		link: `${DOMAIN_ORIGIN}/public/daily-snacks?t=staff`,
	},
	{
		title: "Daily Snacks : Boarding",
		description: "Show daily snacks dispatched to boarding students",
		link: `${DOMAIN_ORIGIN}/public/daily-snacks?t=boarding`,
	},
];

export default function Reports() {
	const navigate = useNavigate();
	// const reportsRef = query(collection(firestore, "reports"));
	// const reportsQuery = useFirestoreQuery(["reports"], reportsRef);

	// if (reportsQuery.isLoading) {
	// 	return <div>Loading ...</div>;
	// }

	// const snapshot = reportsQuery.data;
	// const reports = snapshot.docs.map((d: any) =>
	// 	formatFirebaseDates(d.data())
	// ) as unknown as Report[];

	const openReports = (reportId: string) => {
		return () => navigate(`${reportId}`);
	};

	return (
		<div>
			<NavBar title="Reports & Invoices" />

			<ul className="pt-4">
				{reports.map((report, idx) => (
					<a href={report.link} target="_blank" key={`${report.title}__${idx}`}>
						<li className="py-4 border-b border-gray-200 flex flex-row justify-between cursor-pointer">
							<div>
								<h1 className="text-xl">{report.title}</h1>
								<small className="text-gray-400 italic">
									{report.description}
								</small>
							</div>

							<ChevronRight />
						</li>
					</a>
				))}
			</ul>
		</div>
	);
}

type ReportListItemProps = {
	report: Invoice;
};

const ReportListItem: React.FC<ReportListItemProps> = ({ report }) => {
	return (
		<Paper
			shadow={"sm"}
			className="hover:shadow-lg cursor-pointer"
			p={10}
			px={14}
		>
			<h1 className="text-xl">{report.name}</h1>
			<span>
				For services rendered from{" "}
				<span className="text-indigo-700">
					{format(report.startDate, "dd MMMM yyyy")}
				</span>{" "}
				to{" "}
				<span className="text-indigo-700">
					{format(report.endDate, "dd MMMM yyyy")}
				</span>
			</span>
			<div>Total: Tsh {calculateInvoiceTotal(report).toLocaleString()} /=</div>
		</Paper>
	);
};
