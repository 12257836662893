import { Button, Modal, MultiSelect, NumberInput, Select, Table, Text, Textarea, TextInput } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { v1 as uuidV1 } from "uuid";
import { useForm, SubmitHandler } from "react-hook-form"
import { useDisclosure } from '@mantine/hooks';
import { subDays, format, addYears } from "date-fns";
import { query, where, collection, getDocs, addDoc, doc, setDoc, writeBatch } from "firebase/firestore";
import { upperFirst } from "lodash";
import React, { useEffect, useState } from "react";
import { NavBar } from "../../components/AdminNavBar";
import { firestore, functions } from "../../firebase";
import { formatFirebaseDates } from "./CreateAccount";
import { httpsCallable } from "firebase/functions";
import { useFirestoreQuery } from "@react-query-firebase/firestore";
import add from "date-fns/add";


// Table Columns: product name, description, quantity, actions
// Methods: move to shop, create new product, increase products, 

const data = []

export default function Inventory() {
  const [openedAddInventoryForm, { open, close }] = useDisclosure(true)
  const [openModalName, setOpenModalName] = useState("")
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null)
  const inventoryRef = query(collection(firestore, "inventory"));
  const inventoryQuery = useFirestoreQuery(["inventory"], inventoryRef, {
    subscribe: true
  });

  const inventoryList = (inventoryQuery?.data?.docs?.map((d: any) =>
    formatFirebaseDates(d.data())
  ) || []) as Product[];

  const closeModal = () => {
    close();
    setOpenModalName("")
    setSelectedProduct(null)
  }

  const openNewProduct = () => {
    setOpenModalName("newProduct");
    open()
  }

  const openAddInventory = (product: Product) => () => {
    setOpenModalName("newPurchase");
    setSelectedProduct(product)
    open()
  }

  const rows = inventoryList.map(product => (
    <tr key={product.id}>
      <td>{product.name}</td>
      <td>{product.description}</td>
      <td>{product.quantity}</td>
      <td>
        <Button variant="subtle" onClick={openAddInventory(product)}>+ Add Inventory</Button>
      </td>
    </tr>
  ))
  return (
    <div>
      <NavBar title="Inventory" />

      <div>
        <Button className="bg-blue-500" onClick={openNewProduct}>Add New Product</Button>
      </div>

      <div className="md:flex md:flex-row md:space-x-4 items-end pb-4">
        <Table>
          <thead>
            <tr>
              <th>Product Name</th>
              <th>Description</th>
              <th>Quantity</th>
              <th>Actions</th>
            </tr>
          </thead>

          <tbody>{rows}</tbody>
        </Table>
      </div>


      <Modal opened={openedAddInventoryForm} onClose={closeModal} title="Create New Product">
        {/* Modal content */}
        {openModalName === "newProduct" && <NewProductForm close={closeModal} />}
        {openModalName === "newPurchase" && selectedProduct && <NewPurchaseOrder product={selectedProduct} close={closeModal} />}
      </Modal>
    </div>
  )
}



type Product = {
  id: string;
  uid: string; // unique global identifier from the database
  name: string;
  description: string;
  category: string;
  price: number;
  purchasePrice: number;
  quantity: number;
  units: string;
  imageURL: string;
  sku: string;
  metadata: {
    isAvailable: boolean;
    canBeSoldWithMeal: boolean;
    [key: string]: any;
  };
  expirationDate: Date;
  createdAt: Date;
  updatedAt: Date;
}


const NewProductForm = ({ close }: { close: () => void }) => {
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<Product>({
    defaultValues: {
      purchasePrice: 0,
      quantity: 0,
      units: "",
      metadata: {
        isAvailable: false,
        canBeSoldWithMeal: false
      },
      imageURL: "",
      expirationDate: addYears(new Date(), 1),
      createdAt: new Date(),
      updatedAt: new Date()
    }
  })
  const onSubmit: SubmitHandler<Product> = async (data) => {
    if (loading) return;
    const docRef = doc(collection(firestore, "inventory"));
    const res = await setDoc(docRef, {
      ...data,
      id: docRef.id,
      quantity: +data.quantity,
    })

    setLoading(false);
    close()
  }
  return (

    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
      {/* register your input into the hook by invoking the "register" function */}
      <TextInput label="Product Name" {...register("name", { required: true })} />

      <Textarea label="Product Desctiption" {...register("description", { required: true })} />

      <TextInput label="Category" {...register("category")} />

      <TextInput type="number" label="Selling Price" {...register("price")} />

      <TextInput label="SKU" {...register("sku")} />

      <Button type="submit" loading={loading} className="bg-blue-500 w-full" >Create Product</Button>
    </form>
  )
}

type PurchaseOrder = {
  id: string;
  productId: string;
  productName: string;
  price: number;
  quantity: number;
  createdAt: Date;
  updatedAt: Date;
}

const NewPurchaseOrder = ({ close, product }: { close: () => void; product: Product }) => {
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<PurchaseOrder>({
    defaultValues: {
      productId: product.id,
      productName: product.name,
      price: 0,
      quantity: 1,
      createdAt: new Date(),
      updatedAt: new Date()
    }
  })

  const onSubmit: SubmitHandler<PurchaseOrder> = async (data) => {
    if (loading) return;
    console.log(data)
    const batch = writeBatch(firestore)
    const productRef = doc(firestore, "inventory", product.id);

    batch.update(productRef, { "quantity": +data.quantity + product.quantity });

    const purchaseOrderRef = doc(collection(firestore, "purchase-orders"));
    batch.set(purchaseOrderRef, {
      ...data,
      quantity: +data.quantity,
      price: +data.price,
      id: purchaseOrderRef.id
    });

    await batch.commit();

    setLoading(false);
    close()
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
      {/* register your input into the hook by invoking the "register" function */}
      <TextInput label="Product Name" disabled {...register("productName", { required: true })} />

      <TextInput type="number" label="Quantity" {...register("quantity", { required: true })} />

      <TextInput type="number" label="Purchase Price" {...register("price", { required: true })} />

      <Button type="submit" loading={loading} className="bg-blue-500 w-full" >
        Add Inventory
      </Button>
    </form>

  )
}