import {
	Button,
	Checkbox,
	Radio,
	Select,
	SimpleGrid,
	Table,
	Text,
} from "@mantine/core";
import { DatePickerInput, DatesRangeValue } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { endOfDay, format, startOfDay, subDays } from "date-fns";
import { collection, getDocs, query, where } from "firebase/firestore";
import { chunk, groupBy, times, uniq, upperFirst } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { MealReportCostSummary } from "../components/MealReportCostSummary";
import { firestore } from "../firebase";
import { Account, MealSaleItem, Sale } from "../types";
import { getEATime, isMealSale } from "../utils";
import {
	getAccountTypsSubGroups,
	accountTypes,
	formatFirebaseDates,
} from "./admin/CreateAccount";
import {
	deduplicateSales,
	getAccountSalesPerMeal,
	getWeeklySummary,
	getWeeksfromDateRange,
	LetterHead,
} from "./PublicSchoolReports";

// TODO restore to 21 days after fixing internal report
const MIN_DATE = startOfDay(subDays(new Date(), 41));

export default function PublicSchoolReports({
	accountType = "staff",
}: {
	accountType: "staff" | "visitor";
}) {
	const [reportType, setReportType] = useState<"summary" | "extended">(
		"summary"
	);
	const [sales, setSales] = useState<Sale[]>([]);
	const form = useForm<Partial<Account> & { teachersOnDutyOnly: boolean }>({
		initialValues: {
			type: accountType || "staff",
			typeSubGroup: "support",
			grade: "",
			teachersOnDutyOnly: false,
		},
	});

	const [dates, setDates] = useState<DatesRangeValue>([
		getEATime(),
		getEATime(),
	]);

	// on account type change, clear the subtype
	useEffect(() => {
		setSales([]);
	}, [form.values.typeSubGroup]);

	const accountsInSales = useMemo(() => {
		const saleAccounts = sales.map((s) => ({
			type: s.buyerAccountType,
			typeSubGroup: s.buyerAccountTypeSubGroup,
			accountId: s.buyerId,
			accountName: s.buyerAccountName,
		}));
		return [
			// @ts-ignore
			...new Map(
				// @ts-ignore
				saleAccounts.map((item) => [item["accountId"], item])
			).values(),
		];
	}, [sales.length]);

	const search = async () => {
		setSales([]);
		const { type: accountType, teachersOnDutyOnly } = form.values;
		const queryAccountType =
			accountType === "visitor" ? ["visitor", "guest"] : accountType;
		const q = query(
			collection(firestore, "sales"),
			where("createdAt", ">=", startOfDay(dates[0] || new Date())),
			where("createdAt", "<=", endOfDay(dates[1] || new Date())),
			where("metadata.isOnduty", "==", false),
			where("buyerAccountType", "==", accountType)
		);
		// purposely not including the typeSubGroup in the query as there was confusion in creating accounts and some results might be missing (???)
		const res = await getDocs(q);
		const saleItems = res.docs
			.map((d) => formatFirebaseDates(d.data()) as Sale)
			.filter((s) => {
				if (
					form.values.type === "staff" &&
					(form.values.typeSubGroup?.length || 0) > 0 &&
					!teachersOnDutyOnly
				) {
					return s.buyerAccountTypeSubGroup === form.values.typeSubGroup;
				}
				return true;
			});

		console.log({ saleItems });

		const singleSales = deduplicateSales(saleItems);

		setSales(singleSales);
	};

	const totalMeals = {
		breakfast: sales.filter((s) => isMealSale(s) && s.category === "breakfast").length,
		lunch: sales.filter((s) => isMealSale(s) && s.category === "lunch").length,
		dinner: sales.filter((s) => isMealSale(s) && s.category === "dinner").length,
	};

	const dailySales = useMemo(
		() => groupBy(sales, (o) => o.createdAt.toDateString()),
		[sales.length]
	);

	const ReportTitle = () => {
		const { type, typeSubGroup } = form.values;
		const title =
			type === "staff"
				? `${typeSubGroup && typeSubGroup.length > 0
					? typeSubGroup + ` staff`
					: ""
				}`
				: form.values.type;
		return (
			<h1 className="text-xl">
				{upperFirst(title)} {/* @ts-ignore */}
				{title?.length > 0}
			</h1>
		);
	};

	return (
		<div className="container mx-auto">
			<DatePickerInput
				type="range"
				className="no-print"
				label="Choose the dates"
				minDate={MIN_DATE}
				placeholder="Pick dates range"
				value={dates}
				onChange={setDates}
			/>
			{/* <div> */}
			{/* 	<Checkbox */}
			{/* 		label="Show only teachers on duty instead" */}
			{/* 		className="no-print" */}
			{/* 		{...form.getInputProps("teachersOnDutyOnly")} */}
			{/* 	/> */}
			{/* </div> */}

			{!form.values.teachersOnDutyOnly && form.values.type === "staff" && (
				<SimpleGrid className="no-print mt-4" cols={4}>
					{/* <Select */}
					{/* 	label="Account Type" */}
					{/* 	placeholder="Pick one" */}
					{/* 	{...form.getInputProps("type")} */}
					{/* 	data={accountTypes.map((acc) => ({ */}
					{/* 		value: acc, */}
					{/* 		label: upperFirst(acc), */}
					{/* 	}))} */}
					{/* /> */}

					<Select
						label="Account Type Group"
						placeholder="Pick one"
						clearable
						{...form.getInputProps("typeSubGroup")}
						data={getAccountTypsSubGroups(form.values.type as any)}
					/>
				</SimpleGrid>
			)}

			<div className="no-print">
				<Radio.Group
					// name="favoriteFramework"
					className="mt-4"
					label="What type of report do you want to render?"
					// description="Pick the right repo"
					// withAsterisk
					value={reportType}
					onChange={(t) => setReportType(t as any)}
				>
					<Radio value="summary" label="Total summaries" />
					<Radio value="extended" label="Extended report" />
				</Radio.Group>
			</div>
			<div className="flex space-x-6">
				<Button className="no-print mt-6" onClick={search} variant="outline">
					Generate Summary Report
				</Button>
				<Button
					className="no-print mt-6 bg-orange-500"
					onClick={window.print}
					variant="filled"
					color={"orange"}
				>
					Print
				</Button>
			</div>

			<LetterHead />

			<div className="h-8" />
			<div className="px-4">
				{reportType === "summary" && (
					<div className="print-container">
						<ReportTitle />
						<p className="text-gray-500 text-sm">
							From {format(dates[0] || new Date(), "dd/MM/yyyy")} to{" "}
							{format(dates[1] || new Date(), "dd/MM/yyyy")}
						</p>

						<MealReportCostSummary
							accountType="staff"
							accountTypeSubGroup={form.values.typeSubGroup || "support"}
							grade=""
							totalMeals={totalMeals}
						/>
						<Table className="pt-4">
							<thead>
								<tr>
									<th>#</th>
									<th>Name</th>
									<th>Breakfast</th>
									<th>Lunch</th>
									<th>Dinner</th>
								</tr>
							</thead>
							<tbody>
								{accountsInSales.map((acc, idx) => {
									const accountSales = sales.filter(
										(s) => s.buyerId === acc.accountId
									);
									return (
										<tr key={`${acc.accountId}__${idx}`}>
											<td>{idx + 1}</td>
											<td>{acc.accountName}</td>
											<td>
												{
													getAccountSalesPerMeal(
														acc.accountId,
														"breakfast",
														accountSales as unknown as MealSaleItem[]
													).length
												}
											</td>
											<td>
												{
													getAccountSalesPerMeal(
														acc.accountId,
														"lunch",
														accountSales as unknown as MealSaleItem[]
													).length
												}
											</td>
											<td>
												{
													getAccountSalesPerMeal(
														acc.accountId,
														"dinner",
														accountSales as unknown as MealSaleItem[]
													).length
												}
											</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					</div>
				)}

				{reportType === "extended" && (
					<div>
						{getWeeksfromDateRange(dates?.[0] as Date, dates?.[1] as Date).map(
							(week, idx) => {
								const weekSummary = getWeeklySummary(
									sales as unknown as MealSaleItem[],
									dates[0] as Date,
									dates[1] as Date
								);
								return (
									<div key={idx} className="pt-2 mb-14">
										<ReportTitle />
										<Text>
											From {format(week[0], "MMM dd")} to{" "}
											{format(week[week.length - 1], "MMM dd")}
										</Text>
										<>
											Breakfast: {weekSummary.breakfast.length} <br />
											Lunch: {weekSummary.lunch.length} <br />
											Dinner: {weekSummary.dinner.length} <br />
										</>
										<Table>
											<thead>
												<tr>
													<th>#</th>
													<th>Name</th>
													{week.map((day) => (
														<th key={`${day}__${idx}`}>
															{format(day, "eee, dd")}
														</th>
													))}

													{times(7 - week.length, (n) => (
														<th key={n} style={{ color: "transparent" }}>
															_
														</th>
													))}
												</tr>
											</thead>
											<tbody>
												{accountsInSales.map((acc, adx) => (
													<tr key={`${acc.accountId}__${idx}`}>
														<td>{adx + 1}</td>
														<td>{acc.accountName}</td>
														{week.map((day, dayIdx) => (
															<td key={dayIdx}>
																{dailySales[day.toDateString()]
																	?.filter((s) => s.buyerId === acc.accountId)
																	.map((s: any) =>
																		((s as MealSaleItem).category[0] || "").toUpperCase()
																	)
																	?.join(", ")}
															</td>
														))}
														{/* TODO: Add total weekly sales per row */}
													</tr>
												))}
												{/* <tr></tr> */}
											</tbody>
										</Table>
									</div>
								);
							}
						)}
					</div>
				)}
			</div>
			{/* <ReportFooter /> */}
		</div>
	);
}

// For headers and footers with tables: https://medium.com/@Idan_Co/the-ultimate-print-html-template-with-header-footer-568f415f6d2a
