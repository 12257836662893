import {
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import { onAuthStateChanged, signOut, User } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import { auth } from "../firebase";

type AuthContextType = {
	user?: User;
	loading: boolean;
	error?: any;
	login: (email: string, password: string) => void;
	logout: () => void;
};

const AuthContext = createContext<AuthContextType>({
	loading: true,
} as AuthContextType);

// Export the provider as we need to wrap the entire app with it
export function AuthProvider({
	children,
}: {
	children: ReactNode;
}): JSX.Element {
	const [user, setUser] = useState<User>();
	const [error, setError] = useState<any>();
	const [loading, setLoading] = useState<boolean>(false);
	const [loadingInitial, setLoadingInitial] = useState<boolean>(true);

	const navigate = useNavigate();
	const location = useLocation();

	// If we change page, reset the error state.
	useEffect(() => {
		if (error) setError(null);
	}, [location.pathname]);

	function goToLoginScreen() {
		navigate("/");
	}

	useEffect(() => {
		const authListener = onAuthStateChanged(auth, (user) => {
			if (!user) {
				setLoading(false);
				setLoadingInitial(false);
				if (location.pathname.includes("/public/")) {
					return;
				}

				goToLoginScreen();
			} else {
				user
					.getIdTokenResult()
					.then((idTokenResult) => {
						console.log({ idTokenResult });
						if (!!idTokenResult.claims.role === false) {
							auth.signOut();
							goToLoginScreen();
							alert("This account is broken. Please contact support");
							throw new Error("Broken account.");
						}
						setUser(user);
						console.log({ location });
						if (location.pathname.includes("/public/")) {
							return;
						}
						// Confirm the user is an Admin.
						if (idTokenResult.claims.role === "admin") {
							// Show admin UI.
							navigate("/admin");
						} else {
							// Show regular user UI.
							navigate("/app");
						}
					})
					.finally(() => {
						setLoading(false);
						setLoadingInitial(false);
					});
			}
		});

		return () => {
			authListener();
		};
	}, []);

	function login(email: string, password: string) {
		// setLoading(true);
		throw new Error("MethodNotImplemented");
	}

	function logout() {
		signOut(auth).then(() => {
			setUser(undefined);
		});
	}

	const memoedValue = useMemo(
		() => ({
			user,
			loading,
			error,
			login,
			logout,
		}),
		[user, loading, error]
	);

	// We only want to render the underlying app after we
	// assert for the presence of a current user.
	return (
		<AuthContext.Provider value={memoedValue}>
			{!loadingInitial && children}
		</AuthContext.Provider>
	);
}

// Let's only export the `useAuth` hook instead of the context.
// We only want to use the hook directly and never the context component.
export default function useAuth() {
	return useContext(AuthContext);
}
