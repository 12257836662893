import { Button } from "@mantine/core";
import { useFirestoreDocument, useFirestoreDocumentData } from "@react-query-firebase/firestore";
import { sub } from "date-fns";
import {
	collection,
	doc,
	getDocs,
	limit,
	query,
	where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NavBar } from "../../components/AdminNavBar";
import { firestore } from "../../firebase";
import { Invoice, Report } from "../../types";

export default function ExpandedReportsList() {
	const navigate = useNavigate();
	const [invoices, setInvoices] = useState<Invoice[]>([]);
	const { reportId = "undefined" } = useParams();
	console.log(reportId);
	const ref = doc(firestore, "reports", reportId) as any;

	const report = useFirestoreDocumentData<Report>(["reports", reportId], ref);

	const threeMonthsAgo = sub(new Date(), { months: 3 });

	useEffect(() => {
		const q = query(
			collection(firestore, `reports/${reportId}/invoices`),
			where("createdAt", ">=", threeMonthsAgo),
			limit(20)
		);
		getDocs(q).then((res) => {
			const results = res.docs.map((doc) => {
				return doc.data() as Invoice;
			});

			setInvoices(results);
		});
	}, [reportId]);

	const openInvoice = () => navigate("view-invoice/new");

	if (report.isLoading) {
		return <div>Loading...</div>;
	}

	const reportObj = report.data;

	return (
		<div>
			{reportObj && <NavBar title={`${reportObj.name}`} />}

			<Button onClick={openInvoice} variant="subtle">
				Create a new Invoice
			</Button>

			<ul>
				{invoices.map((invoice) => (
					<li key={invoice.createdAt.getTime()}> {invoice.name}</li>
				))}
			</ul>
		</div>
	);
}
