import {
	Button,
	Divider,
	Grid,
	Modal,
	MultiSelect,
	Paper,
	Title,
} from "@mantine/core";
import { DatePickerInput, DatePickerValue } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { format } from "date-fns";
import { useFirestoreQuery, useFirestoreQueryData } from "@react-query-firebase/firestore";
import { addDoc, collection, updateDoc, doc, query } from "firebase/firestore";
import { keys, omit, upperFirst } from "lodash";
import React, { useEffect, useState } from "react";
import { NavBar } from "../../components/AdminNavBar";
import { firestore } from "../../firebase";
import { formatFirebaseDates } from "./CreateAccount";
import { WeekMenu } from "../../types";

const createWeekMenuItem = (): WeekMenu => ({
	from: new Date(),
	to: new Date(),
	breakfast: {
		main: [],
	},
	lunch: {
		fruits: [],
		main: [],
		salad: [],
		soup: [],
	},
	dinner: {
		main: [],
		fruits: [],
		desert: [],
		drinks: [],
		starter: [],
	},
	createdAt: new Date(),
	updatedAt: new Date(),
});

export default function MenuManager() {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [editingMenu, setEditingMenu] = useState<WeekMenu | null>(null);
	const [editingMenuId, setEditingMenuId] = useState<string>("");

	const ref = query(collection(firestore, "weekly-menus")) as any;

	// Provide the query to the hook
	const menusQuery = useFirestoreQueryData<WeekMenu>(["weekly-menus"], ref, {
		subscribe: true,
	});

	if (menusQuery.isLoading) {
		return <div>Loading ...</div>;
	}

	const snapshot = menusQuery.data || [];
	const menus = snapshot.map((d: any) =>
		formatFirebaseDates(d)
	) as WeekMenu[];

	return (
		<div>
			<NavBar title="Menu Manager" />

			<Button onClick={() => setIsModalOpen(true)}>Create new week menu</Button>

			<div className="pt-4">
				{menus.map((menu, idx) => (
					<WeekMenuItem key={`menu__${idx}`} menu={menu} />
				))}
			</div>

			<Modal
				opened={isModalOpen}
				size="55%"
				onClose={() => setIsModalOpen(false)}
				title={editingMenu ? "Edit week menu" : "Create new week menu"}
			>
				<WeekMenuForm
					onClose={() => setIsModalOpen(false)}
					editingMenu={editingMenu}
					menuId={editingMenuId}
				/>
			</Modal>
		</div>
	);
}

// const toggleStringList = (list: string[]) => (str: string) => {
// 	true;
// };

type WeekMenuFormProps = {
	onClose: () => void;
	editingMenu: WeekMenu | null;
	menuId: string;
};

const WeekMenuForm = ({ onClose, editingMenu, menuId }: WeekMenuFormProps) => {
	const [menu, setMenu] = useState(createWeekMenuItem);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (editingMenu) {
			setMenu(editingMenu);
		}
	}, []);

	const updateMenuMeal =
		(meal: keyof WeekMenu, group: string) => (names: string[]) => {
			setMenu((m) => ({
				...m,
				[meal]: { ...m[meal], [group]: names },
			}));
		};

	const updateWeekDates = (dates: [Date, Date | null]) => {
		if (dates[1] === null) {
			return;
		}

		setMenu((m) => ({
			...m,
			from: dates[0],
			to: dates[1] as Date,
		}));
	};

	const submit = async () => {
		console.log(menu);
		if (loading) return;
		if (
			!window.confirm(
				"Please confirm creation of this menu for the given week."
			)
		) {
			return;
		}

		const menusRef = collection(firestore, "weekly-menus");
		if (editingMenu === null) {
			try {
				await addDoc(menusRef, {
					...menu,
				});
				alert("Menu created successfully.");
				setLoading(false);
				onClose();
			} catch (error) {
				console.error(error);
				alert("Error creating menu. Please try again.");
			}
		} else if (editingMenu !== null) {
			try {
				await updateDoc(doc(menusRef, menuId), {
					...omit(menu, ["createdAt"]),
				});
				alert("Menu updated successfully.");
				setLoading(false);
				onClose();
			} catch (error) {
				console.error(error);
				alert("Error updating Menu. Please try again.");
			}
		}
	};
	return (
		<div>
			<div className="space-y-4">
				<DatePickerInput
					type="range"
					label="Week"
					placeholder="Pick dates that define the week"
					value={[menu.from, menu.to]}
					onChange={updateWeekDates}
				/>

				<Divider />
				<div>
					<span className="text-md">Breakfast</span>
					<MultiSelect
						label="Choose main meals"
						data={[]}
						placeholder="Select items"
						searchable
						onChange={updateMenuMeal("breakfast", "main")}
						creatable
						getCreateLabel={(query) => `+ Create ${query}`}
						onCreate={(query) => {
							const item = { value: query, label: query };
							console.log(query);
							return item;
						}}
					/>
				</div>

				<Divider />
				<div className="space-y-2">
					<span className="text-md">Lunch</span>
					{keys(menu.lunch).map((lunchKey) => (
						<div>
							<MultiSelect
								label={`Enter the ${lunchKey} options`}
								searchable
								creatable
								data={[]}
								onChange={updateMenuMeal("lunch", lunchKey)}
								getCreateLabel={(query) => `+ Create ${query}`}
								onCreate={(query) => {
									const item = { value: query, label: query };
									console.log(query);
									return item;
								}}
							/>
						</div>
					))}
				</div>

				<Divider />

				<div className="space-y-2">
					<span className="text-md">Dinner</span>
					{keys(menu.dinner).map((dinnerKey) => (
						<div>
							<MultiSelect
								label={`Enter the ${dinnerKey} options`}
								searchable
								creatable
								data={[]}
								onChange={updateMenuMeal("dinner", dinnerKey)}
								getCreateLabel={(query) => `+ Create ${query}`}
								onCreate={(query) => {
									const item = { value: query, label: query };
									// setData((current) => [...current, item]);
									console.log(query);
									return item;
								}}
							/>
						</div>
					))}
				</div>

				<div>
					<Button loading={loading} onClick={submit}>
						Submit
					</Button>
				</div>
			</div>
		</div>
	);
};

type WeekMenuItemProps = {
	menu: WeekMenu;
};

export const WeekMenuItem = ({ menu }: WeekMenuItemProps) => {
	const { breakfast, lunch, dinner } = menu;
	return (
		<Paper shadow={"sm"} p={8} px={12} className="space-y-4">
			<Title className="text-gray-800 text-semibold" order={3}>
				{`${format(menu.from, "dd MMMM yyyy")} - ${format(
					menu.to,
					"dd MMMM yyyy"
				)}`}
			</Title>

			<div>
				<Title order={5}>Breakfast</Title>
				<ul>
					{breakfast.main.map((meal) => (
						<li key={meal}>{upperFirst(meal)}</li>
					))}
				</ul>
			</div>

			<div>
				<Title order={5}>Lunch</Title>
				<Grid>
					<Grid.Col sm={12} lg={3}>
						<MealItemsList title="Salads" names={lunch.salad} />
					</Grid.Col>
					<Grid.Col sm={12} lg={3}>
						<MealItemsList title="Soup" names={lunch.soup} />
					</Grid.Col>
					<Grid.Col sm={12} lg={3}>
						<MealItemsList title="Main" names={lunch.main} />
					</Grid.Col>
					<Grid.Col sm={12} lg={3}>
						<MealItemsList title="Fruits" names={lunch.fruits} />
					</Grid.Col>
				</Grid>
			</div>

			<div>
				<Title order={5}>Dinner</Title>
				<Grid columns={15}>
					<Grid.Col sm={15} lg={3}>
						<MealItemsList title="Salads" names={dinner.starter} />
					</Grid.Col>
					<Grid.Col sm={15} lg={3}>
						<MealItemsList title="Main" names={dinner.main} />
					</Grid.Col>
					<Grid.Col sm={15} lg={3}>
						<MealItemsList title="Drinks" names={dinner.drinks} />
					</Grid.Col>
					<Grid.Col sm={15} lg={3}>
						<MealItemsList title="Fruits" names={dinner.fruits} />
					</Grid.Col>
					<Grid.Col sm={15} lg={3}>
						<MealItemsList title="Desert" names={dinner.desert} />
					</Grid.Col>
				</Grid>
			</div>
		</Paper>
	);
};

const MealItemsList = ({
	title,
	names,
}: {
	title: string;
	names: string[];
}) => {
	return (
		<>
			<h4 className="text-lg ">{title}</h4>
			<ul>
				{names.map((name) => (
					<li key={name}>{upperFirst(name)}</li>
				))}
			</ul>
		</>
	);
};
