import { Button, Group, NumberInput, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { httpsCallable } from "firebase/functions";
import React, { useState } from "react";
import { functions } from "../firebase";
import { Account } from "../types";

type InitialBalancesResetFormProps = {
	account: Account;
	onResetComplete: () => void;
};

type BalancesResetForm = {
	accountId: string;
	mainBalance: number;
	snackBalance: number;
};

export const InitialBalancesResetForm: React.FC<
	InitialBalancesResetFormProps
> = ({ account, onResetComplete }) => {
	const [loading, setLoading] = useState(false);

	const form = useForm<BalancesResetForm>({
		initialValues: {
			accountId: account.uid,
			mainBalance: account.initialBalance || 0,
			snackBalance: account.initialSnackBalance || 0,
		},
	});

	const submit = (values: BalancesResetForm) => {
		if (!window.confirm("Are you sure you want to reset the balances?")) {
			return;
		}
		setLoading(true);
		httpsCallable(
			functions,
			"resetInitialBalances"
		)({ ...values })
			.then((res) => {
				setLoading(false);
				alert("Initial balances have been reset");
				onResetComplete();
			})
			.catch((error) => {
				setLoading(false);
				console.error({ error });
				alert(
					"An error occured while resetting the initial balances for this account. Please contact support!"
				);
			});
	};
	return (
		<form onSubmit={form.onSubmit(submit)} className="space-y-4">
			<div className="text-gray-700 text-sm">
				Note: This is a destructive action - meaning changing this can have
				unintended consequences as it updates a value without the option to
				undo.
			</div>
			<TextInput label="Account Name" value={account.ownerFullName} disabled />
			<NumberInput
				label="Main Balance Initial Balance"
				description="The initial balance for this main account"
				{...form.getInputProps("mainBalance")}
			/>
			<NumberInput
				label="Snack Balance Initial Balance"
				description="The initial balance for this snack account"
				{...form.getInputProps("snackBalance")}
			/>

			<Group position="right" mt="md">
				<Button className="bg-sky-800" loading={loading} type="submit">
					Submit
				</Button>
			</Group>
		</form>
	);
};
