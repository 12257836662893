import { format } from "date-fns";
import { sortBy } from "lodash";
import { Account, Invoice, MealSaleItem, Refill, Sale, SnackSaleItem } from "./types";

// TODO: take in props??
export function createAccountTemplate(
	uid: string,
	type: Account["type"] = "student",
	typeSubGroup: Account["typeSubGroup"] = "other"
): Account {
	return {
		uid,
		active: true,
		name: "",
		grade: "",
		type,
		typeSubGroup,
		dormitory: "",
		ownerId: "",
		postpaid: false,
		ownerEmail: "",
		balance: 0,
		snackBalance: 0,
		schoolBalance: 0,
		schoolSnackBalance: 0,
		organizationId: "",
		organization: "",
		ownerFullName: "",
		ownerTelephone: "",
		ownerPhotoURL: "",
		ownerSecondaryId: "",
		ownerSecondaryIdType: "",
		createdAt: new Date(),
		updatedAt: new Date(),
		expiryDate: new Date(new Date().setFullYear(new Date().getFullYear() + 5)), // Default expires in 5 years from now

		metadata: {
			mealLiabilities: {
				breakfast: "main",
				lunch: "main",
				dinner: "main",
				snack: "snack",
			},
		},
	};
}

export function isABoarder(account: Account) {
	return account.type === "student" && account.typeSubGroup === "boarding";
}



export const isSale = (activity: Sale | Refill): activity is Sale => {
	return "referenceId" in activity
}

export function isSnackSale(sale: Sale): sale is SnackSaleItem {
	// @ts-ignore allow category to be defined for the sake of the old submitted sales where category did exist inside the sale item
	return (sale as SnackSaleItem).items !== undefined && ((sale as SnackSaleItem).category === undefined || sale.category === "snack")
}

/**
Checck whether or not a sale is a Meal

@param {Sale}
@returns {booolean}
*/
export function isMealSale(sale: Sale): sale is MealSaleItem {
	return (sale as MealSaleItem).category !== undefined && !isSnackSale(sale)
}

// Given a meal, a list of accounts, and a list of sales, return the students that missed that meal;
// TODO: Support checking if an account has missed any of the meals.
export const getMissedMealAccounts =
	(meals: MealSaleItem["category"][], present = false) =>
		(sales: MealSaleItem[]) => {
			// IDs of buyers who paid for meals within this category
			const buyerIds = Array.from(
				new Set(
					sales.filter((s) => meals.includes(s.category)).map((s) => s.buyerId)
				)
			);
			return (accounts: Account[]): Account[] => {
				// return the accounts that are not included in the list of sellers
				// console.log("INSIDE: ", accounts);
				return accounts.filter((a) =>
					!present ? !buyerIds.includes(a.uid) : buyerIds.includes(a.uid)
				);
			};
		};

export const calculateInvoiceTotal = (invoice: Invoice): number => {
	return invoice.items.reduce((prev, curr) => prev + curr.price, 0);
};

export const groupSaleDuplicates = (sales: MealSaleItem[]): Record<string, Sale[]> => {
	const groups = sales.reduce((prev, curr) => {
		const key = `${curr.buyerId}_${format(curr.createdAt, "dd-MM-yyyy")}_${curr.category
			}`;
		const onDuty = curr?.isOnduty ? true : false;
		if (prev[key] && !onDuty) {
			// this is a duplicate
			return {
				...prev,
				[key]: [...prev[key], curr],
			};
		} else {
			return {
				...prev,
				[key]: [curr],
			};
		}
	}, {} as Record<string, Array<Sale>>);

	return groups;
};

export const getDuplicatesToDelete = (group: Sale[]): Sale[] => {
	return sortBy(group, "createdAt").slice(1);
};

export const isNotDeleted = (obj: Sale) => {
	// @ts-ignore
	return !obj.deleted;
};

export const getEATime = (d?: Date) =>
	new Date(
		(d || new Date()).toLocaleString("en", { timeZone: "Africa/Nairobi" })
	);


/**
Safely parse a json string into a json object
@param {string} json

@return {{error: null | Error, result: null | Object}} result
*/
export function safelyParseJSON<T>(json: string): { error: null | Error, result: null | T } {
	const result = {
		result: null,
		error: null,
	}
	try {
		result.result = JSON.parse(json, (key, value) => {
			try {
				return JSON.parse(value);
			} catch (e) {
				return value;
			}
		});
	} catch (error) {
		result.error = error as any
	}

	return result
}
