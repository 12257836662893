import React from "react";
import { Dashboard } from "./Dashboard";
import AccountsSummary from "./AccountsSummary";
import Reports from "./Reports";
import CreateAccount from "./CreateAccount";
import Settings from "./Settings";
import Staff from "./Staff";
import RegisterStaff from "./RegisterStaff";
import AccountCards from "./AccountCards";
import SalesScreen from "./Sales";
import SnacksScreen from "./Snacks";
import PrintableCard from "./PrintableCard";
import MenuManager from "./MenuManager";
import ExpandedReportsList from "./ExpandedReportsList";
import InvoiceView from "./InvoiceView";
import SchoolsList from "./SchoolsList";
import CreateSale from "./CreateSale";
import Deposits from "./Deposits";
import { DailySnacks } from "./DailySnacks";
import NewSnackSale from "./NewSnackSale";
import Inventory from "./Inventory"
import Prices from "./Prices"
import NewSale from "./NewSale";
import Deduplicator from "./Deduplicator";

export default {
	Dashboard,
	AccountsSummary,
	Reports,
	Settings,
	CreateAccount,
	Staff,
	RegisterStaff,
	AccountCards,
	SnacksScreen,
	NewSnackSale,
	SalesScreen,
	DailySnacks,
	PrintableCard,
	MenuManager,
	ExpandedReportsList,
	InvoiceView,
	SchoolsList,
	CreateSale,
	NewSale,
	Deposits,
	Inventory,
	Prices,
	Deduplicator
};
