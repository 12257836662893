import React from "react";

export default function Settings() {
	return (
		<div>
			<h1 className="text-3xl">Settings</h1>
			Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nihil
			dignissimos ducimus rem error illo accusantium ratione esse dolorem,
			nostrum quae quaerat aut totam nam exercitationem natus. Quae quaerat ad
			iusto.
		</div>
	);
}
