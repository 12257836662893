import {
	Button,
	Checkbox,
	Input,
	Modal,
	NumberInput,
	Select,
	Table,
	TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useFirestoreQuery, useFirestoreQueryData } from "@react-query-firebase/firestore";
import {
	addDoc,
	collection,
	deleteDoc,
	onSnapshot,
	query,
	doc,
	setDoc,
	updateDoc,
} from "firebase/firestore";
import { upperFirst } from "lodash";
import React, { useEffect, useState } from "react";
import { NavBar } from "../../components/AdminNavBar";
import { firestore } from "../../firebase";
import { Snack } from "../../types";

export default function SnacksScreen({}) {
	const [isCreateModalOpen, setCreateModalOpen] = useState(false);

	const ref = query(collection(firestore, "snacks")) as any;

	// Provide the query to the hook
	const snacksQuery = useFirestoreQueryData<Snack>(["snacks"], ref, {
		subscribe: true,
	});

	if (snacksQuery.isLoading) {
		return <div>Loading ...</div>;
	}

	const snapshot = snacksQuery.data || [];
	const snacks = snapshot.map((d: any) => ({
		...(d as Snack),
	}));

	const deleteSnack = async (id: string) => {
		if (!window.confirm("Are you sure you want to delete this snack?")) {
			return;
		}

		// TODO: delete the snack
		try {
			await deleteDoc(doc(firestore, "snacks", id));
			alert("Snack deleted.");
		} catch (error) {
			alert("Failed to delete snack. Please try again.");
			console.error({ error });
		}
	};

	const toggleAvailability = async (snackId: string, available: boolean) => {
		try {
			await updateDoc(doc(firestore, "snacks", snackId), {
				available,
			});
		} catch (error) {
			alert("Error updating to today. Please try again.");
		}
	};

	const rows = snacks.map((snack: Snack) => (
		<tr key={snack.uid}>
			<td>
				<Checkbox
					onChange={(e) => toggleAvailability(snack.uid, e.target.checked)}
					checked={snack.available || false}
					label={upperFirst(snack.name)}
				/>
				{/* {snack.name} */}
			</td>
			<td>Tsh {snack.price.toLocaleString()} /=</td>
			<td>
				{/* @ts-ignore */}
				<Button
					variant="subtle"
					color="red"
					onClick={() => deleteSnack(snack.uid)}
				>
					Delete
				</Button>
			</td>
		</tr>
	));

	return (
		<div>
			<NavBar title="Snacks" />

			<Button onClick={() => setCreateModalOpen(true)} variant="subtle">
				Create a new Snack
			</Button>

			<Table mt={"sm"}>
				<thead>
					<tr>
						<th>Snack name</th>
						<th>Price</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>{rows}</tbody>
			</Table>

			<Modal
				opened={isCreateModalOpen}
				onClose={() => setCreateModalOpen(false)}
				title="Create a new Snack"
			>
				{/* Modal content */}
				<CreateSnackForm onCreatedSnack={() => setCreateModalOpen(false)} />
			</Modal>
		</div>
	);
}

type CreateSnackFormProps = {
	onCreatedSnack: () => void;
};

const CreateSnackForm: React.FC<CreateSnackFormProps> = ({
	onCreatedSnack,
}) => {
	const [loading, setLoading] = useState(false);
	const form = useForm<{
		name: string;
		price: number;
		origin: "meal" | "shop";
	}>({
		initialValues: {
			name: "",
			price: 0,
			origin: "meal",
		},
	});

	const onSubmit = async (values: {
		name: string;
		price: number;
		origin: "meal" | "shop";
	}) => {
		console.log(values);
		setLoading(true);

		const snackDoc = doc(collection(firestore, "snacks"));

		await setDoc(snackDoc, {
			uid: snackDoc.id,
			name: values.name,
			origin: values.origin,
			price: Number(values.price) || 0,
			createdAt: new Date(),
			updatedAt: new Date(),
		});

		onCreatedSnack();
	};
	return (
		<form className="space-y-4" onSubmit={form.onSubmit(onSubmit)}>
			<TextInput
				label="Snack Name"
				name="snack-name"
				required
				{...form.getInputProps("name")}
			/>
			<NumberInput
				label="Snack Price"
				required
				{...form.getInputProps("price", { type: "input" })}
			/>
			<Select
				label="Where is this snack sold? (Shop vs with meal)"
				placeholder="Pick one"
				data={[
					{ value: "meal", label: "With meals" },
					{ value: "shop", label: "At the shop" },
				]}
				{...form.getInputProps("origin")}
			/>
			<Button className="bg-sky-800" type="submit">
				Submit
			</Button>
		</form>
	);
};
