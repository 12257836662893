import { Button, Modal, NumberInput, Select } from "@mantine/core";
import { DatePicker, DatePickerInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useFirestoreQuery } from "@react-query-firebase/firestore";
import { startOfDay, subDays } from "date-fns";
import {
	addDoc,
	collection,
	deleteDoc,
	doc,
	query,
	setDoc,
	where,
} from "firebase/firestore";
import { useAtom } from "jotai";
import { useState } from "react";
import { v1 } from "uuid";
import { activeModalAtom } from "../../atoms/modals";
import { NavBar } from "../../components/AdminNavBar";
import { DailySnacksList } from "../../components/DailySnacksList";
import { firestore } from "../../firebase";
import { DailySnacksDispatch } from "../../types";
import { getEATime } from "../../utils";
import { formatFirebaseDates } from "./CreateAccount";

// set the minimum date to 40 days ago
const MIN_DATE = subDays(getEATime(getEATime(new Date())), 40);
export function useDailySnackDispatches({
	subscribe = true,
	min_date = MIN_DATE,
}) {
	const ref = query(
		collection(firestore, "daily-snack-dispatches"),
		where("date", ">", MIN_DATE)
	);

	// Provide the query to the hook
	const dailyDispatchQuery = useFirestoreQuery(
		["daily-snack-dispatches"],
		ref,
		{
			subscribe,
		}
	);
	const snapshot = dailyDispatchQuery?.data;
	const dailyDispatches = (snapshot?.docs?.map((d: any) =>
		formatFirebaseDates(d.data())
	) || []) as DailySnacksDispatch[];

	return {
		snackDispatches: dailyDispatches,
		dispatchesToday: dailyDispatches.filter(
			(d) => d.date > startOfDay(new Date())
		),
	};
}

export function DailySnacks({}) {
	const [activeModal, setActiveModal] = useAtom(activeModalAtom);
	const { snackDispatches } = useDailySnackDispatches({});

	const deleteSnackDispatch = async (uid: string) => {
		if (
			!window.confirm("Are you sure you want to delete this daily snack sale?")
		)
			return;

		// TODO: delete snack sale
		try {
			await deleteDoc(doc(firestore, "daily-snack-dispatches", uid));
			window.alert("Deleted successfully.");
		} catch (error) {
			window.alert("Error deleting. Please try again.");
			console.error(error);
		}
	};

	return (
		<div>
			<NavBar title="Daily Snacks" />

			<div className="max-w-3xl">
				<Button
					variant="outline"
					onClick={() => setActiveModal("dispatch-snacks-form")}
				>
					Dispatch Snacks
				</Button>

				<DailySnacksList
					data={snackDispatches}
					deleteSnackDispatch={deleteSnackDispatch}
				/>
			</div>

			<Modal
				opened={activeModal === "dispatch-snacks-form"}
				onClose={() => setActiveModal("none")}
				title="Send out daily snacks"
			>
				{/* Modal content */}
				<DispatchSnacksForm
					onDispatchCompleted={() => setActiveModal("none")}
				/>
			</Modal>
		</div>
	);
}

type DispatchSnacksFormProps = {
	onDispatchCompleted: () => void;
};

const DispatchSnacksForm: React.FC<DispatchSnacksFormProps> = ({
	onDispatchCompleted,
}) => {
	const [loading, setLoading] = useState(false);
	const form = useForm<DailySnacksDispatch>({
		initialValues: {
			count: 0,
			type: "staff",
			uid: v1(), // uuid gets replaced
			paid: false,
			date: getEATime(new Date()),
			createdAt: getEATime(new Date()),
			updatedAt: getEATime(new Date()),
		},
	});

	const submit = async (values: DailySnacksDispatch) => {
		if (values.count === 0 || loading) return;
		if (
			!window.confirm(
				"Are you sure you want to create this daily snacks dispatch?"
			)
		)
			return;

		setLoading(true);

		try {
			// Add a new document with a generated id
			const newDocRef = doc(collection(firestore, "daily-snack-dispatches"));
			await setDoc(newDocRef, {
				...values,
				uid: newDocRef.id,
			});

			window.alert("Daily dispatch of snacks made.");
			setLoading(false);
			onDispatchCompleted();
		} catch {
			window.alert("Unable to record daily dispatch. Please try again.");
			setLoading(false);
		}
	};

	return (
		<form onSubmit={form.onSubmit(submit)} className="space-y-3">
			<Select
				data={["boarding", "staff"]}
				label="Who is the dispatch for?"
				required
				{...form.getInputProps("type")}
			/>
			<NumberInput
				label="How many snacks?"
				required
				{...form.getInputProps("count")}
			/>
			<DatePickerInput
				label="Date of dispatch"
				required
				{...form.getInputProps("date")}
			/>

			<Button loading={loading} fullWidth variant="outline" type="submit">
				Submit
			</Button>
		</form>
	);
};
