import { Select } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { endOfDay, startOfDay } from "date-fns";
import { collection, getDocs, query, where, limit, writeBatch, doc, FieldValue, increment } from "firebase/firestore";
import { groupBy, upperFirst } from "lodash";
import { useEffect, useMemo, useState } from "react"
import { firestore } from "../../firebase";
import { Account, MealSaleItem, Sale, SnackSaleItem } from "../../types";
import { isSnackSale } from "../../utils";
import { formatFirebaseDates } from "./CreateAccount";

export default function Deduplicator() {
  const [date, setDate] = useState(new Date());
  const [queryLimit, setQueryLimit] = useState(400); // Purposely set to 400 to allow one batch only to work.
  const [accountType, setAccountType] = useState<Account["type"]>("student")
  const [saleType, setSaleType] = useState<"meal" | "snack">("meal")
  const [mealSales, setMealSales] = useState<MealSaleItem[]>([])
  const [snackSales, setSnackSales] = useState<SnackSaleItem[]>([])


  useEffect(() => {
    const salesRef = query(
      collection(firestore, "sales"),
      where("buyerAccountType", "==", accountType),
      where("createdAt", ">=", startOfDay(date)),
      where("createdAt", "<=", endOfDay(date)
      ),
      // limit(100)
    );

    const snackSalesRef = query(
      collection(
        firestore, "snack-sales"),
      where("buyerAccountType", "==", accountType),
      where("createdAt", ">=", startOfDay(date)),
      where("createdAt", "<=", endOfDay(date)
      ),
      // limit(50)
    );


    getDocs(salesRef).then(res => {
      const serverSales = res.docs.map(d => {
        return {
          // setting a unique key "_id" to the field to differentiate the uids generated on the mobile db from the server ones, if there is a difference
          _id: d.id,
          ...formatFirebaseDates(d.data()),
        }
      })
      setMealSales(serverSales as any)
    })


    getDocs(snackSalesRef).then(res => {
      const serverSnackSales = res.docs.map(d => {
        return {
          // setting a unique key "_id" to the field to differentiate the uids generated on the mobile db from the server ones, if there is a difference
          _id: d.id,
          ...formatFirebaseDates(d.data()),
        }
      })
      setSnackSales(serverSnackSales as any)
    })
  }, [date, accountType])



  const removeDuplicates = (sales: MealSaleItem[] | SnackSaleItem[]) => () => {
    // @ts-expect-error the _id field is manually added this time
    const saleIds: string[] = sales.map(item => item._id)
    // do nothing with the last one - we are keeping it
    const lastItem = saleIds.pop();

    const amounts = Array.from(new Set(sales.map(item => item.amount)))

    const accountIds = Array.from(new Set(sales.map(item => item.buyerId)))
    if (accountIds.length > 1 || amounts.length > 1) {
      return alert("This set of meals belongs to different people. Contact victor for support")
    }


    // check that each sale is either a snack or meal sale (one type only) and throw an error otherwise
    const isSnack = isSnackSale(sales[0])

    const batch = writeBatch(firestore);

    const collectionName = isSnack ? "snack-sales" : "sales"
    saleIds.forEach(id => {
      const ref = doc(firestore, collectionName, id)
      return batch.delete(ref)
    })


    // give refunds to the user
    const accountID = accountIds[0]
    const refundAccountRef = doc(firestore, "accounts", accountID)
    // this is shady ... should this be another method???
    const balanceField = isSnack ? "snackBalance" : "balance"
    batch.update(refundAccountRef, {
      [balanceField]: increment((+amounts[0] * saleIds.length) || 0)
    })


    batch.commit().then(res => {
      alert("Duplicates deleted");
      if (isSnack) {
        // @ts-expect-error the _id is added on purpose
        setSnackSales(snacks => snacks.filter(snack => !saleIds.includes(snack._id)))
      } else {
        // @ts-expect-error the _id is added on purpose
        setMealSales(meals => meals.filter(m => !saleIds.includes(m._id)))
      }
    }).catch(error => {
      alert("Error!!! Call Victor!")
      console.error({ error })
    })

    console.log({ saleIds, lastItem })
  }


  const possibleDuplicateSales = Object.entries(groupBy(mealSales, "referenceId") as Record<string, MealSaleItem[]>).filter(duplicate => duplicate[1].length > 1)
  const possibleDuplicateSnackSales = Object.entries(groupBy(snackSales, "referenceId") as Record<string, SnackSaleItem[]>).filter(duplicate => duplicate[1].length > 1)


  const duplicateSales = useMemo(() => {
    if (saleType === "meal") {
      return possibleDuplicateSales
    }
    return possibleDuplicateSnackSales
  }, [saleType, mealSales.length, snackSales.length])


  // console.log({ possibleDuplicateSales })

  return (
    <div>

      <DatePickerInput
        placeholder=""
        label="Date"
        required
        value={date}
        // @ts-ignore
        onChange={setDate}
      />

      <Select
        label="Select the type of sale (1 only)"
        placeholder="Pick a sale type"
        value={saleType}
        onChange={t => t && setSaleType(t as any)}
        data={["snack", "meal"].map((d) => ({
          label: upperFirst(d),
          value: d,
        }))}
      />

      <Select
        label="Select the account type"
        placeholder="Pick an account type"
        value={accountType}
        onChange={t => t && setAccountType(t as any)}
        data={["student", "teacher"].map((d) => ({
          label: upperFirst(d),
          value: d,
        }))}
      />

      <div className="space-y-8 mt-8">
        {
          duplicateSales.map(duplicate => {
            const isSnack = isSnackSale(duplicate[1][0])
            const accountTypes = Array.from(new Set(duplicate[1].map(d => d.buyerAccountType)))
            const uids = duplicate[1].map(d => d.uid)

            const isLikelySafe = uids.length === duplicate[1].length

            console.log({ uids })

            const error = accountTypes.length > 1

            if (error) {
              return (
                <div key={duplicate[0]}>There is an error with this grouping</div>
              )
            }

            const accountType = accountTypes[0]
            const isOnduty = duplicate[1][0].isOnduty
            const isGuest = duplicate[1][0].isGuest

          console.log({isOnduty, isGuest})
            return (
              <div key={duplicate[0]} className="border rounded p-4">
                <h3 className="text-lg">ReferenceID: {duplicate[0]} ({accountType}{isOnduty ? "On-duty" : ""})</h3>

                <div className="mb-4">
                  {duplicate[1].map((saleItem, idx) => {
                    const metadata = JSON.parse(String(saleItem.metadata) || "{}");
                  console.log({metadata})
                    return (
                      <div key={saleItem.uid + idx} className="space-x-4">
                        <span>
                          {saleItem.uid}
                        </span>
                        <span>
                          {saleItem.buyerAccountName} {metadata.isAccountVisitor && "Visitor"}
                        </span>
                        <span>
                          {isSnackSale(saleItem) ? "Snack" : saleItem.category}
                        </span>
                      </div>
                    )
                  })}
                </div>

                <div className="mb-4">
                  {isLikelySafe && "(Probably safe to deduplicate)"}
                </div>

                {isOnduty !== true && isGuest !== true &&
                  <button
                    type="button"
                    onClick={removeDuplicates(duplicate[1])}
                    className="rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100">
                    Remove duplicates
                  </button>
                }
              </div>
            )
          })
        }

      </div>
    </div>
  )
}