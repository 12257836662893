import React, { useEffect, useMemo, useState } from "react";
import {
	Table,
	Button,
	TextInput,
	Modal,
	Group,
	NumberInput,
	Text,
	Menu,
	MultiSelect,
	Select,
	Checkbox,
} from "@mantine/core";
import {
	IconArrowsLeftRight,
	IconMessageCircle,
	IconPhoto,
	IconSearch,
	IconSettings,
	IconRefresh,
	IconTrash,
} from "@tabler/icons";
import { useAtom } from "jotai";
import { VariableSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import _, { forIn, uniq, upperFirst } from "lodash";
import { useFirestoreQuery } from "@react-query-firebase/firestore";
import {
	query,
	collection,
	limit,
	getDocs,
	getDoc,
	doc,
	deleteDoc,
	writeBatch,
	where,
	updateDoc,
} from "firebase/firestore";
import { auth, firestore, functions } from "../../firebase";
import { NavBar } from "../../components/AdminNavBar";
import { useForm } from "@mantine/form";
import { DatePicker, DatePickerInput } from "@mantine/dates";
import { httpsCallable } from "firebase/functions";
import { useNavigate, useSearchParams } from "react-router-dom";
import { accountFilters } from "../../atoms/accounts-filters";
import { formatFirebaseDates, getAccountTypsSubGroups } from "./CreateAccount";
import { getDuplicatesToDelete, groupSaleDuplicates, isSnackSale } from "../../utils";
import { AlertCircle } from "tabler-icons-react";
import { InitialBalancesResetForm } from "../../components/InitialBalancesResetForm";
import { dangerouslyAddFieldsToAllBoardingAccounts } from "../../api";
import { Account, Card, MealSaleItem, Refill, Sale } from "../../types";

async function dangerouslyAddFieldsToAllAccounts(
	fields: Record<string, any> | Partial<Account>
) {
	return console.log("Setting these fields: ", fields);
	try {
		const docs = await getDocs(collection(firestore, "accounts"));
		const ids = docs.docs.map((d) => d.id);

		// log out to the console incase of emergency
		console.log(docs.docs.map((d) => ({ ...d.data(), _id: d.id })));
		const batch = writeBatch(firestore);

		ids.forEach((id) => {
			const docRef = doc(firestore, "accounts", id);

			batch.update(docRef, { ...fields });
		});

		await batch.commit();
	} catch (error) {
		console.error({ error });
	}
}

async function matchSalesToAccount(account: Account) {
	if (
		!window.confirm(
			"Are you sure you want to go through all previous sales and re-match them to this account?"
		)
	)
		return;
	// return console.log("Nothing")
	const q = query(
		collection(firestore, "sales"),
		where("buyerId", "==", account.uid)
	);

	const accountSales = await getDocs(q);

	const saleAccounts = accountSales.docs.map((d) => {
		return updateDoc(d.ref, {
			buyerAccountName: account.ownerFullName,
			buyerAccountType: account.type,
			buyerAccountTypeSubGroup: account.typeSubGroup,
		});
	});

	return Promise.all(saleAccounts)
		.then((res) => {
			alert("All sales updated succeccsully");
			console.log(res);
		})
		.catch((error) => {
			console.error(error);
			alert(
				"Failed to update all sales. There might be a partial update that was applied."
			);
		});
}

async function forceCleanDuplicate(account: Account) {
	console.log(account);
	if (
		!window.confirm(
			"Are you sure you want to forcefully delete any duplicates to sales?"
		)
	)
		return;
	const q = query(
		collection(firestore, "sales"),
		where("buyerId", "==", account.uid)
		// where("deleted", "!=", true)
	);
	try {
		const accountSalesRef = await getDocs(q);
		const accountSales = accountSalesRef.docs.map((d) =>
			formatFirebaseDates({ uid: d.id, ...d.data() })
		) as unknown as MealSaleItem[];

		// get the duplicates but ignore all sales that are accounted to on duty staff, and ignore all snack sales
		const duplicates = groupSaleDuplicates(
			accountSales.filter(
				(s) => !s.isOnduty && isSnackSale(s)
			)
		);
		// console.log({ duplicates, accountSales });

		const promiseUpdates: Promise<any>[][] = [];

		forIn(duplicates, (sale, key) => {
			const dups = getDuplicatesToDelete(sale);
			const results = dups.map((s) => {
				// @ts-ignore
				return updateDoc(doc(firestore, "sales", s.uid), {
					deleted: true,
					deletedAt: new Date(),
				});
			});

			promiseUpdates.push(results);
		});

		// console.log(promiseUpdates, promiseUpdates.flat().length);

		if (promiseUpdates.flat().length === 0) {
			alert("This account has no duplicate sales.");
		} else {
			await Promise.all(promiseUpdates.flat());
			alert("Duplicate sales have been moved to trash.");
		}
	} catch (error) {
		console.error(error);
		alert("There was an error deleting duplicate sales.");
	}
}

// const studentAcco
type ModalTypes = "addCard" | "deposit" | "resetInitialBalances" | null;

export default function AccountsSummary() {
	const navigate = useNavigate();
	const [URLParams] = useSearchParams();
	const [searchStr, setSearchStr] = useState("");
	const [activeAccount, setActiveAccount] = useState<null | Account>(null);
	const [activeModal, setActiveModal] = useState<ModalTypes>(null);

	const [filters, setFilters] = useAtom(accountFilters);

	// const [filters, setFilters] = useState<{
	// 	types: string[];
	// 	typeSubGroups: string[];
	// 	grades: string[];
	// 	listLength: number;
	// 	overdraftOnly: boolean;
	// }>({
	// 	types: URLParams.getAll("type") || [],
	// 	typeSubGroups: [],
	// 	grades: [],
	// 	listLength: 25,
	// 	overdraftOnly: URLParams.get("overdrafted") === "true" || false,
	// });

	useEffect(() => {
		const types = URLParams.get("type");
		const overdraftOnly = URLParams.get("overdrafted");

		// console.log(overdraftOnly);
		setFilters({
			...filters,
			types: types ? [types] : [],
			overdraftOnly: overdraftOnly === "true" ? true : false,
		});
	}, []);

	const closeAddCardModal = () => {
		setActiveAccount(null);
		setActiveModal(null);
	};

	const openAddCardModal = (account: Account) => {
		setActiveAccount(account);
		setActiveModal("addCard");
	};

	const setActiveModalByName = (name: ModalTypes, account?: Account) => {
		if (name === null) {
			closeAddCardModal();
		} else if (name === "addCard") {
			account && openAddCardModal(account);
		} else if (name === "deposit") {
			account && setActiveAccount(account);
			setActiveModal("deposit");
		} else if (name === "resetInitialBalances") {
			account && setActiveAccount(account);
			setActiveModal("resetInitialBalances");
		}
	};

	// Deprecated: Tombstone Aug 11
	const printCardQR = async (account: Account) => {
		const res = await getDocs(
			collection(firestore, `accounts/${account.uid}/cards`)
		);
		const cards = res.docs.map((d) => ({
			...d.data(),
			name: account.ownerFullName,
			organization: account.organization,
			accountType: account.type,
		}));
	};

	const deleteAccount = async (accountId: string) => {
		if (
			window.confirm(
				"Are you sure you want to delete this account? It is often better to just deactivate an account."
			)
		) {
			try {
				await deleteDoc(doc(firestore, "accounts", accountId));
				alert("Account deleted!");
			} catch (error) {
				console.error(error);
				alert("There was an error deleting this account. Please try again.");
			}
		}
	};

	/** 
	Reset the account balance to 0. This is a permanent action that is not reversible
	@param {string} accountId
	@param {"main" | "snack"} accountType
	*/
	const resetBalanceAccount = async (accountId: string, accountType: "main" | "snack") => {
		if (window.confirm("Are you sure you want to reset the account balance? This will set the account balance to 0 Tsh. THIS CANNOT BE REVERSED.")) {
			try {
				await updateDoc(doc(firestore, "accounts", accountId), {
					...(accountType === "main" ? { balance: 0 } : { snackBalance: 0 }),
					updatedAt: new Date(),
				});
				alert("Account balance reset")
			} catch (error) {
				console.error(error);
				alert("There was an error reseting the account balance. Please try again.")
			}
		}
	}

	const ref = query(collection(firestore, "accounts"));

	// Provide the query to the hook
	const accountQuery = useFirestoreQuery(["accounts"], ref, {
		subscribe: true,
	});



	// if (accountQuery.isLoading) {
	// 	return <div>Loading ...</div>;
	// }

	const snapshot = accountQuery?.data;
	const accounts = (snapshot?.docs?.map((d: any) => d.data()) ||
		[]) as Account[];

	const gradesInAccounts = uniq(
		accounts.map((acc) => acc.grade.toLowerCase()).filter((c) => c.length > 0)
	);

	console.log({ gradesInAccounts })

	const filteredAccounts = useMemo(
		() => accounts.filter(applyAccountFilters(searchStr, filters)),
		[
			accounts.length,
			searchStr,
			filters.grades,
			filters.typeSubGroups,
			filters.types,
			filters.overdraftOnly,
		]
	);
	const copyAllEmails = () => {
		const emails = filteredAccounts.map((a) => a.ownerEmail).join(", ");
		navigator.clipboard.writeText(emails);
	};
	const accountRows = filteredAccounts
		.slice(0, filters.listLength)
		.map((account: Account) => (
			<AccountRowItem
				account={account}
				setActiveModal={setActiveModalByName}
				selectAccount={setActiveAccount}
				key={account.uid}
				navigate={navigate}
				deleteAccount={deleteAccount}
				resetBalanceAccount={resetBalanceAccount}
			/>
		));

	return (
		<div>
			<NavBar title="Accounts" />
			{/* TODO: Consider this, do we need to load all the accounts first in order to do client side filtering? */}
			<div className=" pb-9">
				<TextInput
					label="Full Name"
					className="w-96"
					value={searchStr}
					onChange={(evt) => setSearchStr(evt.target.value)}
					placeholder=""
					icon={<IconSearch size={14} />}
				/>

				{/* <Button */}
				{/* 	onClick={() => */}
				{/* 		window.confirm("My guy, are you sure you want to do this??") && */}
				{/* 		dangerouslyAddFieldsToAllBoardingAccounts({ */}
				{/* 			schoolSnackBalance: 0, */}
				{/* 			schoolBalance: 0, */}
				{/* 			metadata: { */}
				{/* 				mealLiabilities: { */}
				{/* 					breakfast: "school", */}
				{/* 					lunch: "school", */}
				{/* 					dinner: "school", */}
				{/* 					snack: "snack", */}
				{/* 				}, */}
				{/* 			}, */}
				{/* 		}) */}
				{/* 	} */}
				{/* > */}
				{/* 	Click here to do something dangerous */}
				{/* </Button> */}

				<div className="pt-6">
					Filters
					<div className="grid md:grid-cols-4 gap-4">
						<MultiSelect
							data={["student", "teacher", "guest", "visitor", "staff"].map(
								(d) => ({
									label: upperFirst(d),
									value: d,
								})
							)}
							value={filters.types}
							onChange={(v) => setFilters((s) => ({ ...s, types: v }))}
							label="Which account types to include"
							placeholder="Choose all you want to include"
						/>

						<MultiSelect
							data={["day", "boarding", "support", "auxiliary", "other"].map(
								(d) => ({
									label: upperFirst(d),
									value: d,
								})
							)}
							label="Account sub-groups: day, boarding, support, auxiliary, other"
							value={filters.typeSubGroups}
							onChange={(v: string[]) =>
								setFilters((s) => ({ ...s, typeSubGroups: v }))
							}
							placeholder="Choose all you want to include"
						/>
						<MultiSelect
							data={gradesInAccounts.sort().map((d) => ({
								label: upperFirst(d),
								value: d,
							}))}
							onChange={(v) => setFilters((s) => ({ ...s, grades: v }))}
							value={filters.grades}
							label="Which grades/classes to include"
							placeholder="Choose all you want to include"
						/>
					</div>
					<div className="py-2">
						<Checkbox
							checked={filters.overdraftOnly}
							onChange={() =>
								setFilters((s) => ({
									...s,
									overdraftOnly: !filters.overdraftOnly,
								}))
							}
							label="Show only overdrafted accounts"
						/>
					</div>
				</div>
			</div>
			<div className="py-1">
				<Text className="text-lg">
					Total Accounts: {filteredAccounts.length}
				</Text>
			</div>

			<Button compact onClick={copyAllEmails} variant="subtle">
				Copy all emails
			</Button>

			<Table horizontalSpacing="md" verticalSpacing="sm">
				<thead>
					<tr>
						<th>Full Name</th>
						<th>Account Type</th>
						<th>School</th>
						<th>Balance</th>
						<th>Status</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>{accountRows}</tbody>
			</Table>

			{filters.listLength < filteredAccounts.length && (
				<Button
					variant="subtle"
					autoFocus={false}
					onClick={() =>
						setFilters((s) => ({ ...s, listLength: s.listLength + 25 }))
					}
				>
					Load more ...
				</Button>
			)}

			{/* Modals */}
			{activeAccount && (
				<>
					<Modal
						opened={activeModal === "addCard" && activeAccount !== null}
						onClose={closeAddCardModal}
						title={`Create a new card for ${activeAccount.ownerFullName}`}
					>
						<AddCardForm
							account={activeAccount}
							onCardCreated={closeAddCardModal}
						/>
					</Modal>
					<Modal
						opened={activeModal === "deposit" && activeAccount !== null}
						onClose={closeAddCardModal}
						title={`Add funds to the account: ${activeAccount.ownerFullName}`}
					>
						<DepositForm
							account={activeAccount}
							onDepositComplete={closeAddCardModal}
						/>
					</Modal>
					<Modal
						opened={
							activeModal === "resetInitialBalances" && activeAccount !== null
						}
						onClose={closeAddCardModal}
						title={`Reset initial balances of: ${activeAccount.ownerFullName}`}
					>
						<InitialBalancesResetForm
							account={activeAccount}
							onResetComplete={closeAddCardModal}
						/>
					</Modal>
				</>
			)}
		</div>
	);
}

type AccountRowItemProps = {
	account: Account;
	setActiveModal: (modal: ModalTypes, account?: Account) => void;
	selectAccount: (account: Account) => void;
	deleteAccount: (accountId: Account["uid"]) => void;
	resetBalanceAccount: (accountId: Account["uid"], accountType: "main" | "snack") => void;
	navigate: (url: string) => void;
};
const AccountRowItem: React.FC<AccountRowItemProps> = React.memo(
	({ account, setActiveModal, selectAccount, navigate, deleteAccount, resetBalanceAccount }) => {
		return (
			<tr key={account.uid}>
				<td>{account.ownerFullName}</td>
				<td>
					{_.upperFirst(account.type)}{" "}
					<p className="text-gray-500">
						{account.type === "student" && upperFirst(account.grade) + " "}
						{upperFirst(account.typeSubGroup)}
					</p>
				</td>
				<td>{account.organization}</td>
				<td>
					<p>Main: Tsh {account.balance.toLocaleString()} /=</p>
					<p>Snack: Tsh {(account.snackBalance || 0).toLocaleString()} /=</p>
				</td>
				<td>{account.active ? "Active" : "Inactive"}</td>
				<td className="flex items-center">
					{/* {(!account.initialBalance || !account.initialSnackBalance) && (
						<Button
							variant="subtle"
							title="Account initial balance is not set"
							p={0}
							onClick={() => setActiveModal("resetInitialBalances", account)}
						>
							<AlertCircle color="#FF6F00" />
						</Button>
					)}*/}
					<Button
						variant="subtle"
						compact
						autoFocus={false}
						onClick={() => navigate(`edit/${account.uid}`)}
					>
						Edit Account
					</Button>

					{account.active && (
						<Button
							onClick={() => {
								setActiveModal("deposit", account);
								// selectAccount(account);
							}}
							variant="subtle"
							autoFocus={false}
						>
							Deposit Funds
						</Button>
					)}
					<Menu
						shadow="md"
						// opened={openedMenuId === account.uid}
						// onChange={(v) => setOpenedMenuId(v ? account.uid : "")}
						width={200}
					>
						<Menu.Target>
							<Button autoFocus={false} variant="subtle">
								More
							</Button>
						</Menu.Target>

						{/* {openedMenuId === account.uid && ( */}
						<Menu.Dropdown>
							<Menu.Label>General</Menu.Label>
							<Menu.Item
								onClick={() => navigate(`${account.uid}/cards`)}
								icon={<IconSettings size={14} />}
							>
								View Cards
							</Menu.Item>

							<Menu.Item
								onClick={() => setActiveModal("addCard", account)}
								icon={<IconMessageCircle size={14} />}
							>
								Add Cards
							</Menu.Item>
							<Menu.Divider />
							<Menu.Label>Danger zone</Menu.Label>
							<Menu.Item
								onClick={() => setActiveModal("resetInitialBalances", account)}
								icon={<IconSettings size={14} />}
							>
								Change Initial Balance
							</Menu.Item>
							<Menu.Item
								onClick={() => matchSalesToAccount(account)}
								icon={<IconSettings size={14} />}
							>
								Dangerously Match Sales to Account
							</Menu.Item>
							<Menu.Item
								onClick={() => forceCleanDuplicate(account)}
								icon={<IconSettings size={14} />}
							>
								Force Clean duplicates
							</Menu.Item>

							<Menu.Item
								color="red"
								onClick={() => resetBalanceAccount(account.uid, "main")}
								icon={<IconRefresh size={14} />}
							>
								Reset Main Balance
							</Menu.Item>

							<Menu.Item
								color="red"
								onClick={() => resetBalanceAccount(account.uid, "snack")}
								icon={<IconRefresh size={14} />}
							>
								Reset Snack Balance
							</Menu.Item>
							<Menu.Item
								color="red"
								onClick={() => deleteAccount(account.uid)}
								icon={<IconTrash size={14} />}
							>
								Delete account
							</Menu.Item>
						</Menu.Dropdown>
						{/* )} */}
					</Menu>
				</td>
			</tr>
		);
	},
	(prevProps, nextProps) => {
		return prevProps.account.uid === nextProps?.account?.uid;
	}
);

type AddCardFormProps = {
	account: Account;
	onCardCreated: () => void;
};

const AddCardForm: React.FC<AddCardFormProps> = ({
	account,
	onCardCreated,
}) => {
	const [loading, setLoading] = useState(false);

	const form = useForm<Card>({
		initialValues: {
			number: "",
			cvv: 123, // number gets generated and overwritten server side.
			accountId: account.uid,
			accountType: account.type,
			accountTypeSubGroup: account.typeSubGroup,
			ownerId: account.ownerId,
			createdAt: new Date(),
			updatedAt: new Date(),
			expiresAt: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
		},
	});

	const submit = (value: Card) => {
		setLoading(true);
		httpsCallable(
			functions,
			"createAccountCard"
		)({ card: value })
			.then((res) => {
				setLoading(false);
				alert("New Card Added to account");
				onCardCreated();
			})
			.catch((error) => {
				setLoading(false);
				console.error({ error });
				alert(
					"Error creating the card for the account. Please contact support!"
				);
			});
	};
	return (
		<form onSubmit={form.onSubmit(submit)} className="space-y-4">
			<TextInput
				label="Account Number"
				placeholder="Automatically generated"
				disabled
			/>

			<TextInput
				required
				label="CVV"
				placeholder="Automatically generated"
				disabled
			/>
			<DatePickerInput
				placeholder=""
				{...form.getInputProps("expiresAt", { type: "input" })}
				label="Card Expiration Date"
			/>

			<Group position="right" mt="md">
				<Button className="bg-sky-800" loading={loading} type="submit">
					Submit
				</Button>
			</Group>
		</form>
	);
};

type DepositFormProps = {
	account: Account;
	onDepositComplete: () => void;
};

const DepositForm: React.FC<DepositFormProps> = ({
	account,
	onDepositComplete,
}) => {
	const [loading, setLoading] = useState(false);

	const form = useForm<Refill>({
		initialValues: {
			accountId: account.uid,
			accountType: "main",
			amount: 0,
			staffId: auth.currentUser?.uid || "", // This value is overwritten server side for more security.
			createdAt: new Date(),
			updatedAt: new Date(),
		},
	});

	const submit = (values: Refill) => {
		setLoading(true);
		httpsCallable(
			functions,
			"refillAccount"
		)({ ...values })
			.then((res) => {
				setLoading(false);
				alert("Balance added to account");
				onDepositComplete();
			})
			.catch((error) => {
				setLoading(false);
				console.error({ error });
				alert("Error adding the funds to the account. Please contact support!");
			});
	};
	return (
		<form onSubmit={form.onSubmit(submit)} className="space-y-4">
			<TextInput label="Account Name" value={account.ownerFullName} disabled />
			<Select
				label="Account Type"
				placeholder="Pick one"
				{...form.getInputProps("accountType")}
				data={[
					{ value: "main", label: "Main Account (Lunch & Breakfast & Dinner)" },
					{ value: "snack", label: "Snacks Account" },
				]}
			/>
			<NumberInput
				label="Amount"
				description="The amount being deposited into the account"
				{...form.getInputProps("amount")}
			/>
			<DatePickerInput
				placeholder="Select a date"
				label="Date of deposit"
				{...form.getInputProps("createdAt")}
				withAsterisk
			/>

			<Group position="right" mt="md">
				<Button className="bg-sky-800" loading={loading} type="submit">
					Submit
				</Button>
			</Group>
		</form>
	);
};

// TODO: Add fuse.js??
const searchByName = (searchStr: string) => (account: Account) => {
	return account.ownerFullName.toLowerCase().includes(searchStr.toLowerCase());
};

export const applyAccountFilters =
	(
		searchStr: string,
		filters: {
			typeSubGroups: string[];
			grades: string[];
			types: string[];
			overdraftOnly: boolean;
		}
	) =>
		(account: Account) => {
			const { typeSubGroups, grades, types, overdraftOnly } = filters;
			const tsg =
				typeSubGroups.length === 0
					? true
					: typeSubGroups.includes(account.typeSubGroup);
			const type = types.length === 0 ? true : types.includes(account.type);
			const overdraft = overdraftOnly ? account.balance < 0 : true;
			const grade =
				grades.length === 0
					? true
					: grades.includes(account.grade.toLowerCase()) ||
					grades.includes(account.grade.toUpperCase());
			return (
				type && overdraft && tsg && grade && searchByName(searchStr)(account)
			);
		};
