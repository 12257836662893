import { Card, Input, Paper, Chip, Button, Modal } from "@mantine/core";
import { useFirestoreQuery } from "@react-query-firebase/firestore";
import { IconCircleCheck, IconSearch } from "@tabler/icons";
import React, { useMemo, useState } from "react";
import { NavBar } from "../../components/AdminNavBar";
import {
	addDoc,
	collection,
	deleteDoc,
	onSnapshot,
	query,
	doc,
	setDoc,
	writeBatch,
	increment,
} from "firebase/firestore";
import { v1 as uuidV1 } from "uuid";
import { firestore } from "../../firebase";
import { sortBy, upperFirst } from "lodash";
import useAuth from "../../contexts/auth";
import { Account, Sale, Snack, SnackSaleItem } from "../../types";

export default function NewSnackSale() {
	const [cart, setCart] = useState<(Snack & { count: number })[]>([]);
	const [searchStr, setSearchStr] = useState("");
	const [studentSearchStr, setStudentSearchStr] = useState("");
	const [checkoutModalOpened, setCheckoutModelOpened] = useState(false);
	const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);
	const { user } = useAuth();

	const ref = query(collection(firestore, "snacks"));

	const accountsRef = query(collection(firestore, "accounts"));
	// Provide the query to the hook
	const accountQuery = useFirestoreQuery(["accounts"], accountsRef, {
		subscribe: true,
	});

	const accounts = (accountQuery?.data?.docs?.map(
		(d: any) => d.data() as Account
	) || []) as unknown as Account[];

	// Provide the query to the hook
	const snacksQuery = useFirestoreQuery(["snacks"], ref, {
		subscribe: true,
	});

	// if (snacksQuery.isLoading) {
	// 	return <div>Loading ...</div>;
	// }

	const snacks = snacksQuery?.data?.docs?.map((d: any) => ({
		...(d.data() as Snack),
	})) as Snack[];

	const toggleCartItem = (snack: Snack & { count: number }) => {
		const exists = cart.findIndex((sn) => sn.uid === snack.uid) > -1;
		if (exists) {
			setCart((c) => c.filter((sn) => snack.uid !== sn.uid));
		} else {
			setCart((c) => [...c, { ...snack, count: 1 }]);
		}
	};

	const addToCart = (snack: Snack & { count: number }) => {
		const exists = cart.findIndex((sn) => sn.uid === snack.uid) > -1;
		if (exists) {
			return setCart((c) =>
				c.map((c) => (c.uid === snack.uid ? { ...c, count: c.count + 1 } : c))
			);
		}
		setCart((c) => [...c, { ...snack, count: 1 }]);
	};

	const removeFromCart = (snack: Snack & { count: number }) => {
		const exists = cart.findIndex((sn) => sn.uid === snack.uid) > -1;
		if (exists) {
			return setCart((c) => {
				return c
					.map((c) => (c.uid === snack.uid ? { ...c, count: c.count - 1 } : c))
					.filter((c) => c.count > 0);
			});
		}
		setCart((c) => {
			const idx = c.findIndex((sn) => sn.uid === snack.uid);
			return c.splice(idx, 1);
		});
	};

	const completeCheckout = async () => {
		if (selectedAccount === null)
			return alert("Please select an account first");

		try {
			const batch = writeBatch(firestore);
			// loop through the items in the cart, create a sale for each one of them??
			const totalSnackCost = cart.reduce((acc, curr) => {
				return acc + curr.count * curr.price;
			}, 0);
			const referenceId = uuidV1();
			const snackItems: SnackSaleItem["items"] = cart.map((snack) => ({
				name: snack.name,
				count: snack.count,
				origin: "shop",
				price: snack.price,
			}));


			// @ts-ignore
			const sale: SnackSaleItem = {
				uid: uuidV1(),
				referenceId,
				amount: totalSnackCost,

				buyerId: selectedAccount.uid,
				buyerAccountName: selectedAccount.ownerFullName,
				buyerAccountType: selectedAccount.type,
				buyerAccountTypeSubGroup: selectedAccount.typeSubGroup,
				sellerId: user?.uid || "",

				organizationId: selectedAccount.organizationId,

				items: snackItems, // only for snack do we allow snack items to be recorded
				isGuest: false,
				isOnduty: false,
				isPaid: true,
				metadata: {
					saleExistsToday: false,
					networkStatus: navigator.onLine,
				},

				createdAt: new Date(),
				updatedAt: new Date(),
			};

			const saleDocRef = doc(collection(firestore, "snackSales"));
			const accountRef = doc(firestore, "accounts", selectedAccount.uid);

			batch.set(saleDocRef, { ...sale });

			batch.update(accountRef, {
				snackBalance: increment(-totalSnackCost),
				updatedAt: new Date(),
			});

			console.log({ sale });
			await batch.commit();
			alert("Sale completed");
			resetAll();
		} catch (error) {
			alert("Error making the sale. Check your connection and try again");
			console.warn(error);
			return;
		}
	};

	const resetAll = () => {
		setStudentSearchStr("");
		setCheckoutModelOpened(false);
		setSelectedAccount(null);
		setCart([]);
	};

	const cartIds = cart.map((c) => c.uid);

	const filteredSnacks = useMemo(
		() =>
			sortBy(snacks, ["available"])
				// .filter((s) => !cartIds.includes(s.uid))
				.filter((s) => s.name.includes(searchStr.toLowerCase()))
				.slice(0, 10),
		[searchStr, cartIds]
	);

	return (
		<div className="bg-gray-100">
			<NavBar title="Make a new Sale" />

			{/* Step 1: Select an account */}
			<div className="container mx-auto px-2 pt-4 space-y-8">
				<Input
					placeholder="Snack Name"
					size="lg"
					value={searchStr}
					onChange={(e: any) => setSearchStr(e.target.value)}
					rightSection={
						<div>
							<IconSearch
								size={18}
								style={{ display: "block", opacity: 0.5 }}
							/>
						</div>
					}
				/>
				<div className="flex space-x-2 flex-wrap">
					{cart.map((snack, idx) => (
						<Chip
							checked={true}
							key={snack.uid + idx}
							onChange={() => removeFromCart(snack)}
							className="mb-2"
						>
							{upperFirst(snack.name)} x{snack.count}
						</Chip>
					))}
				</div>
				<div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 gap-y-8">
					{filteredSnacks.map((snack) => (
						<Paper
							className="bg-white cursor-pointer"
							// @ts-ignore
							onClick={() => addToCart(snack)}
							shadow={"sm"}
							p={12}
							py={16}
							withBorder
							key={snack.uid}
						>
							<div className="flex space-x-1 items-center">
								{snack.available && <IconCircleCheck size={14} color="green" />}
								<h4 className="text-lg">{upperFirst(snack.name)}</h4>
							</div>
						</Paper>
					))}
				</div>
				<Button onClick={() => setCheckoutModelOpened(true)} variant="outline">
					Checkout
				</Button>
			</div>

			<Modal
				opened={checkoutModalOpened}
				onClose={() => setCheckoutModelOpened(false)}
				title="Select student Account"
			>
				<>
					{/* Modal content */}
					<Input
						onChange={(e: any) => setStudentSearchStr(e.target.value)}
						value={studentSearchStr}
						placeholder="Student Name"
					/>

					{studentSearchStr.length > 3 &&
						accounts
							.filter((acc) =>
								acc.ownerFullName
									.toLowerCase()
									.includes(studentSearchStr.toLowerCase())
							)
							.slice(0, 3)
							.map((account) => (
								<div
									key={account.uid}
									className="py-2 border-b border-gray-200"
									onClick={() => {
										setSelectedAccount(account);
										setStudentSearchStr(account.ownerFullName);
									}}
								>
									{account.ownerFullName}
								</div>
							))}

					{selectedAccount !== null && (
						<Button
							fullWidth
							className="my-4"
							onClick={completeCheckout}
							variant="outline"
						>
							Complete Checkout
						</Button>
					)}
				</>
			</Modal>
		</div>
	);
}
