import { DatePicker, DatePickerInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { addDays, sub, subDays } from "date-fns";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useFirestoreQuery } from "@react-query-firebase/firestore";
import {
	query,
	collection,
	limit,
	QuerySnapshot,
	DocumentData,
	where,
	getDocs,
} from "firebase/firestore";
import { v1 as uuidV1 } from "uuid";
import { firestore, functions } from "../../firebase";
import { NavBar } from "../../components/AdminNavBar";
import { formatFirebaseDates } from "./CreateAccount";
import { Invoice, Sale } from "../../types";

const thirtyDaysAgo = sub(new Date(), { days: 30 });
const SCHOOL_INVOICE_TEMPLATE: Invoice = {
	name: "",
	createdAt: new Date(),
	updatedAt: new Date(),
	endDate: addDays(new Date(), 3),
	startDate: thirtyDaysAgo,
	items: [
		{
			id: 1,
			name: "Staff",
			price: 250_500,
			units: 200,
			createdAt: new Date(),
		},
	],
};

const projects = [
	{
		id: 1,
		name: "New Advertising Campaign",
		hours: "12.0",
		rate: "$75.00",
		price: "$900.00",
	},
	// More projects...
];

export default function InvoiceView() {
	const { reportId, invoiceId } = useParams();
	const [sales, setSales] = useState<Sale[]>([]);

	const form = useForm<Invoice>({
		initialValues: {
			...SCHOOL_INVOICE_TEMPLATE,
		},
	});

	useEffect(() => {
		const { startDate, endDate } = form.values;
		const salesRef = query(
			collection(firestore, "sales"),
			where("createdAt", ">=", startDate),
			where("buyerAccountType", "!=", "student")
		);

		getDocs(salesRef)
			.then((res) => {
				const result = res.docs.map((d) =>
					formatFirebaseDates(d.data())
				) as Sale[];

				// getSchoolSummarySales(sales);
				setSales(result);
			})
			.catch((error) => {
				console.error(error);
				alert("Error loading sales. Please start afresh.");
			});
	}, [form.values.startDate, form.values.endDate]);

	const editMode = invoiceId === "new";

	useEffect(() => {
		if (invoiceId === "new") {
			// This is a new invoice
			console.log(invoiceId);
		}
	}, [invoiceId]);
	return (
		<div>
			<NavBar title="Invoice View" />

			<div>
				<form>
					<div className="px-4 sm:px-6 lg:px-8">
						<div className="sm:flex sm:items-center">
							<div className="sm:flex-auto">
								<h1 className="text-xl font-semibold text-gray-900">Invoice</h1>
								{editMode ? (
									<>
										<DatePickerInput
											type="range"
											label="Choose the date range"
											placeholder="Pick dates range"
											// value={[form.startDate, form.endDate]}
											onChange={(vals) => {
												form.setFieldValue("startDate", vals[0] || new Date());
												form.setFieldValue("endDate", vals[1] || new Date());
											}}
										/>
									</>
								) : (
									<>
										<p className="mt-2 text-sm text-gray-700">
											For work completed from{" "}
											<time dateTime="2022-08-01">August 1, 2022</time> to{" "}
											<time dateTime="2022-08-31">August 31, 2022</time>.
										</p>
									</>
								)}
							</div>
							<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
								<button
									type="button"
									className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
								>
									Print
								</button>
							</div>
						</div>
						<div className="-mx-4 mt-8 flex flex-col sm:-mx-6 md:mx-0">
							<table className="min-w-full divide-y divide-gray-300">
								<thead>
									<tr>
										<th
											scope="col"
											className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
										>
											Category
										</th>
										<th
											scope="col"
											className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
										>
											Units
										</th>{" "}
										<th
											scope="col"
											className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0"
										>
											Price
										</th>
									</tr>
								</thead>
								<tbody>
									{projects.map((project) => (
										<tr key={project.id} className="border-b border-gray-200">
											<td className="py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
												<div className="font-medium text-gray-900">
													{project.name}
												</div>
												<div className="mt-0.5 text-gray-500 sm:hidden">
													{project.hours} hours at {project.rate}
												</div>
											</td>
											<td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">
												{project.hours}
											</td>{" "}
											<td className="py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
												{project.price}
											</td>
										</tr>
									))}
								</tbody>
								<tfoot>
									<tr>
										<th
											scope="row"
											colSpan={2}
											className="hidden pl-6 pr-3 pt-6 text-right text-sm font-normal text-gray-500 sm:table-cell md:pl-0"
										>
											Subtotal
										</th>
										<th
											scope="row"
											className="pl-4 pr-3 pt-6 text-left text-sm font-normal text-gray-500 sm:hidden"
										>
											Subtotal
										</th>
										<td className="pl-3 pr-4 pt-6 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
											$3,900.00
										</td>
									</tr>
									<tr>
										<th
											scope="row"
											colSpan={2}
											className="hidden pl-6 pr-3 pt-4 text-right text-sm font-normal text-gray-500 sm:table-cell md:pl-0"
										>
											Tax
										</th>
										<th
											scope="row"
											className="pl-4 pr-3 pt-4 text-left text-sm font-normal text-gray-500 sm:hidden"
										>
											Tax
										</th>
										<td className="pl-3 pr-4 pt-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
											$585.00
										</td>
									</tr>
									<tr>
										<th
											scope="row"
											colSpan={2}
											className="hidden pl-6 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell md:pl-0"
										>
											Total
										</th>
										<th
											scope="row"
											className="pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden"
										>
											Total
										</th>
										<td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0">
											$4,485.00
										</td>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
}

// function getSchoolSummarySales(sales: Sale[]): Invoice["items"] {
//   return sales.reduce((prev, curr) => {
//
//   }, { "staff": { name: "staff" }, { "snack": { name: "snack" } }, "direct": { name: "Direct" } })
// }
