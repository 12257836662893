import { collection, getDocs, limit, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { QRCodeSVG } from 'qrcode.react';
import { firestore } from "../firebase";
import { Account } from "../types";



export default function AccountCodesList() {
  const [accounts, setAccounts] = useState<Account[]>([])

  useEffect(() => {
    const q = query(collection(firestore, "accounts"));
    getDocs(q).then(res => {
      const data = res.docs.map(d => d.data())
      console.log({ data })
      setAccounts(data as unknown as Account[])
    })
  }, [])
  return (
    <div className="grid grid-cols-2 gap-x-6 p-4">
      {
        accounts.map(acc => (
          <div className="flex row items-center justify-between space-x-8 m-2">
            <div>
              {acc.uid} <br />
              {acc.ownerFullName}
            </div>
            <QRCodeSVG includeMargin={true} level="H" style={{ borderWidth: 1, borderColor: "#333" }} value={`${acc.uid}|${acc.ownerFullName}`} />
          </div>
        ))
      }
    </div>
  )
}