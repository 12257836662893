import { Button, Input, Modal, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useFirestoreQuery, useFirestoreQueryData } from "@react-query-firebase/firestore";
import { doc, query, setDoc, collection } from "firebase/firestore";
import { identity } from "lodash";
import React, { useState } from "react";
import { NavBar } from "../../components/AdminNavBar";
import { firestore } from "../../firebase";
import { School } from "../../types";

export default function SchoolsList() {
	const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
	const ref = query(collection(firestore, "schools")) as any;

	// Provide the query to the hook
	const schoolsQuery = useFirestoreQuery<School>(["schools"], ref, {
		subscribe: true,
	});

	if (schoolsQuery.isLoading) {
		return <div>Loading ...</div>;
	}

	const schools = (schoolsQuery.data?.docs || []) as unknown as School[];
	// const schools = snapshot.map((d: any) => d.data()) as School[];

	return (
		<div>
			<NavBar title="Schools" />

			<Button
				compact
				variant="subtle"
				onClick={() => setIsRegisterModalOpen(true)}
			>
				Register new school
			</Button>

			<div className="pt-4 space-y-4">
				{schools.map((school) => (
					<div key={school.uid} className="border-b border-gray-300 py-2">
						<h1 className="text-lg">{school.name}</h1>
						<span className="text-gray-400 italic">{school.email}</span>
					</div>
				))}
			</div>

			<Modal
				opened={isRegisterModalOpen}
				onClose={() => setIsRegisterModalOpen(false)}
				title="Register new School"
			>
				<SchoolRegistrationForm
					onRegistrationComplete={() => setIsRegisterModalOpen(false)}
				/>
			</Modal>
		</div>
	);
}

type SchoolRegistrationFormProps = {
	onRegistrationComplete: () => void;
};

const SchoolRegistrationForm: React.FC<SchoolRegistrationFormProps> = ({
	onRegistrationComplete,
}) => {
	const form = useForm<School>({
		initialValues: {
			uid: "",
			email: "",
			name: "",
			createdAt: new Date(),
			updatedAt: new Date(),
		},
	});
	const [loading, setLoading] = useState(false);

	const onSubmit = async (values: School) => {
		if (loading) return;
		const schoolsRef = doc(collection(firestore, "schools"));
		try {
			await setDoc(schoolsRef, {
				...values,
				uid: schoolsRef.id,
				createdAt: new Date(),
				updatedAt: new Date(),
			});
			alert("School Registered Successfully.");
			onRegistrationComplete();
		} catch (error) {
			alert("Error registering the school successfully. Please try again.");
			setLoading(false);
		}
	};
	return (
		<div>
			<form
				onSubmit={form.onSubmit((values) => onSubmit(values))}
				className="space-y-4"
			>
				<TextInput
					label="School Name"
					{...form.getInputProps("name")}
					required
				/>
				<TextInput
					label="Contact Email"
					{...form.getInputProps("email")}
					required
				/>

				<Button
					type="submit"
					loading={loading}
					fullWidth
					className="bg-sky-800"
				>
					Submit
				</Button>
			</form>
		</div>
	);
};
