import {
	Button,
	Checkbox,
	Radio,
	Select,
	SimpleGrid,
	Table,
	Text,
} from "@mantine/core";
import { DatePickerInput, DatesRangeValue } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { endOfDay, format, startOfDay, subDays } from "date-fns";
import { collection, getDocs, query, where } from "firebase/firestore";
import { chunk, groupBy, times, uniq, upperFirst } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { MealReportCostSummary } from "../components/MealReportCostSummary";
import { firestore } from "../firebase";
import { Account, MealSaleItem, Sale } from "../types";
import { getEATime } from "../utils";
import {
	getAccountTypsSubGroups,
	accountTypes,
	formatFirebaseDates,
} from "./admin/CreateAccount";
import {
	deduplicateSales,
	getAccountSalesPerMeal,
	getWeeklySummary,
	getWeeksfromDateRange,
	LetterHead,
} from "./PublicSchoolReports";

const MIN_DATE = startOfDay(subDays(new Date(), 35));

export default function TeachersOnDutySummary() {
	const [reportType, setReportType] = useState<"summary" | "extended">(
		"summary"
	);
	const [sales, setSales] = useState<MealSaleItem[]>([]);
	const form = useForm<Partial<Account> & { teachersOnDutyOnly: boolean }>({
		initialValues: {
			type: "teacher",
			typeSubGroup: "none",
			grade: "",
			teachersOnDutyOnly: true,
		},
	});

	const [dates, setDates] = useState<DatesRangeValue>([
		getEATime(),
		getEATime(),
	]);

	// on account type change, clear the subtype
	useEffect(() => {
		// const type = form.values.type;
		// @ts-ignore
		form.setFieldValue("typeSubGroup", "");
	}, [form.values.type]);

	const accountsInSales = useMemo(() => {
		const saleAccounts = sales.map((s) => ({
			type: s.buyerAccountType,
			typeSubGroup: s.buyerAccountTypeSubGroup,
			accountId: s.buyerId,
			accountName: s.buyerAccountName,
		}));
		return [
			// @ts-ignore
			...new Map(
				// @ts-ignore
				saleAccounts.map((item) => [item["accountId"], item])
			).values(),
		];
	}, [sales.length]);

	const search = async () => {
		setSales([]);
		const { type: accountType, teachersOnDutyOnly } = form.values;
		const q = query(
			collection(firestore, "sales"),
			where("createdAt", ">=", startOfDay(dates[0] || new Date())),
			where("createdAt", "<=", endOfDay(dates[1] || new Date())),
			where("isOnduty", "==", true)
		);
		// purposely not including the typeSubGroup in the query as there was confusion in creating accounts and some results might be missing (???)
		const res = await getDocs(q);
		const saleItems = res.docs
			.map((d) => formatFirebaseDates(d.data()) as Sale)
			.filter((s) => {
				if (
					(form.values.typeSubGroup?.length || 0) > 0 &&
					!teachersOnDutyOnly
				) {
					return s.buyerAccountTypeSubGroup === form.values.typeSubGroup;
				}
				return true;
			});

		const singleSales = deduplicateSales(saleItems) as unknown as MealSaleItem[];

		setSales(singleSales);
	};

	const dailySales = useMemo(
		() => groupBy(sales, (o) => o.createdAt.toDateString()),
		[sales.length]
	);

	const totalMeals = {
		breakfast: sales.filter((s) => s.category === "breakfast").length,
		lunch: sales.filter((s) => s.category === "lunch").length,
		dinner: sales.filter((s) => s.category === "dinner").length,
	};
	return (
		<div className="container mx-auto">
			<DatePickerInput
				type="range"
				className="no-print"
				label="Choose the dates"
				minDate={MIN_DATE}
				placeholder="Pick dates range"
				value={dates}
				onChange={setDates}
			/>
			{/* <div> */}
			{/* 	<Checkbox */}
			{/* 		label="Show only teachers on duty instead" */}
			{/* 		className="no-print" */}
			{/* 		{...form.getInputProps("teachersOnDutyOnly")} */}
			{/* 	/> */}
			{/* </div> */}

			<div className="no-print">
				<Radio.Group
					// name="favoriteFramework"
					className="mt-4"
					label="What type of report do you want to render?"
					// description="Pick the right repo"
					// withAsterisk
					value={reportType}
					onChange={(t) => setReportType(t as any)}
				>
					<Radio value="summary" label="Total summaries" />
					<Radio value="extended" label="Extended report" />
				</Radio.Group>
			</div>
			<div className="flex space-x-6">
				<Button className="no-print mt-6" onClick={search} variant="outline">
					Generate Summary Report
				</Button>
				<Button
					className="no-print mt-6 bg-orange-500"
					onClick={window.print}
					variant="filled"
					color={"orange"}
				>
					Print
				</Button>
			</div>

			<LetterHead />

			<div className="h-10" />
			<MealReportCostSummary
				accountType="staff"
				accountTypeSubGroup={form.values.typeSubGroup || "support"}
				grade=""
				totalMeals={totalMeals}
			/>
			<div className="px-4">
				{reportType === "summary" && (
					<div className="print-container">
						<p className="text-gray-500 text-sm">
							From {format(dates[0] || new Date(), "dd/MM/yyyy")} to{" "}
							{format(dates[1] || new Date(), "dd/MM/yyyy")}
						</p>
						<Table className="pt-4">
							<thead>
								<tr>
									<th>#</th>
									<th>Name</th>
									<th>Breakfast</th>
									<th>Lunch</th>
									<th>Dinner</th>
								</tr>
							</thead>
							<tbody>
								{accountsInSales.map((acc, idx) => {
									const accountSales = sales.filter(
										(s) => s.buyerId === acc.accountId
									);
									return (
										<tr key={`${acc.accountId}__${idx}`}>
											<td>{idx + 1}</td>
											<td>{acc.accountName}</td>
											<td>
												{
													getAccountSalesPerMeal(
														acc.accountId,
														"breakfast",
														accountSales
													).length
												}
											</td>
											<td>
												{
													getAccountSalesPerMeal(
														acc.accountId,
														"lunch",
														accountSales
													).length
												}
											</td>
											<td>
												{
													getAccountSalesPerMeal(
														acc.accountId,
														"dinner",
														accountSales
													).length
												}
											</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					</div>
				)}

				{reportType === "extended" && (
					<div>
						{getWeeksfromDateRange(dates?.[0] as Date, dates?.[1] as Date).map(
							(week, idx) => {
								const weekSummary = getWeeklySummary(
									sales,
									dates[0] as Date,
									dates[1] as Date
								);
								return (
									<div key={idx} className="pt-2 mb-14">
										<Text>
											From {format(week[0], "MMM dd")} to{" "}
											{format(week[week.length - 1], "MMM dd")}
										</Text>
										<>
											Breakfast: {weekSummary.breakfast.length} <br />
											Lunch: {weekSummary.lunch.length} <br />
											Dinner: {weekSummary.dinner.length} <br />
										</>
										<Table>
											<thead>
												<tr>
													<th>#</th>
													<th>Name</th>
													{week.map((day) => (
														<th key={`${day}__${idx}`}>
															{format(day, "eee, dd")}
														</th>
													))}

													{times(7 - week.length, (n) => (
														<th key={n} style={{ color: "transparent" }}>
															_
														</th>
													))}
												</tr>
											</thead>
											<tbody>
												{accountsInSales.map((acc, adx) => (
													<tr key={`${acc.accountId}__${idx}`}>
														<td>{adx + 1}</td>
														<td>{acc.accountName}</td>
														{week.map((day, dayIdx) => (
															<td key={dayIdx}>
																{dailySales[day.toDateString()]
																	?.filter((s) => s.buyerId === acc.accountId)
																	.map((s) =>
																		(s.category[0] || "").toUpperCase()
																	)
																	?.join(", ")}
															</td>
														))}
														{/* TODO: Add total weekly sales per row */}
													</tr>
												))}
												{/* <tr></tr> */}
											</tbody>
										</Table>
									</div>
								);
							}
						)}
					</div>
				)}
			</div>
			{/* <ReportFooter /> */}
		</div>
	);
}
