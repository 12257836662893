import { Button, Modal, Table, TextInput } from "@mantine/core";
import { useFirestoreQuery, useFirestoreQueryData } from "@react-query-firebase/firestore";
import { firestore, functions } from "../../firebase";
import { query, collection } from "firebase/firestore";
import _ from "lodash";
import React, { useState } from "react";
import { NavBar } from "../../components/AdminNavBar";
import { httpsCallable } from "firebase/functions";
import { Staff } from "../../types";

const staff1: Partial<Staff>[] = [
	{
		active: true,
		ownerId: "2fnwods",
		role: "server",
		name: "Test name goes here",
	},
];

export default function StaffScreen() {
	const [passwordChangeModalOpen, setPasswordChangeModal] = useState(false);
	const [activeUser, setActiveUser] = useState<Staff | null>(null);
	const ref = query(collection(firestore, "staff")) as any;

	// Provide the query to the hook
	const staffQuery = useFirestoreQuery<Staff>(["staff"], ref, { subscribe: true });

	if (staffQuery.isLoading) {
		return <div>Loading ...</div>;
	}

	const staff = staffQuery.data?.docs.map(d => d.data()) as unknown as Staff[] || [];
	// const staff = snapshot.map((d: any) => d.data());

	const deleteUser = (userId: string) => () => {
		if (!window.confirm("Are you sure you want to delete this user?")) {
			return;
		}

		httpsCallable(
			functions,
			"deleteUser"
		)({
			userId,
			accountType: "staff",
		})
			.then((res) => {
				console.log(res);
				alert("Account deleted successfully");
			})
			.catch((error) => {
				console.error({ error });
				alert("Error deleting user. Please try again.");
			});
	};

	return (
		<div>
			<NavBar title="Staff Accounts" />
			<Table mt={10}>
				<thead>
					<tr>
						<th>Name</th>
						<th>Role</th>
						<th>Status</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{staff.map((member: Staff) => (
						<tr key={member.ownerId}>
							<td>{member.name}</td>
							<td>{_.upperFirst(member.role)}</td>
							<td>{member.active ? "Active" : "Inactive"}</td>
							<td>
								{member.active ? (
									<Button type="button" className="" variant="subtle">
										Deactivate
									</Button>
								) : (
									<Button type="button" className="" variant="subtle">
										Activate
									</Button>
								)}
								<Button
									variant="subtle"
									onClick={() => {
										setPasswordChangeModal(true);
										setActiveUser(member);
									}}
								>
									Change Password
								</Button>
								<Button
									onClick={deleteUser(member.ownerId)}
									variant="subtle"
									color={"red"}
								>
									Delete User
								</Button>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
			{/* Modals */}{" "}
			<Modal
				opened={passwordChangeModalOpen && activeUser !== null}
				onClose={() => setPasswordChangeModal(false)}
				title={`Set a new password for ${activeUser?.name}`}
			>
				<StaffPasswordChangeFrom
					staff={activeUser as Staff}
					onComplete={() => setPasswordChangeModal(false)}
				/>
			</Modal>
		</div>
	);
}

const StaffPasswordChangeFrom = ({
	staff,
	onComplete,
}: {
	staff: Staff;
	onComplete: () => void;
}) => {
	const [newPassword, setNewPassword] = useState("");
	const [loading, setLoading] = useState(false);

	const submit = () => {
		if (
			!window.confirm("Are you sure you want to change this users password?")
		) {
			return;
		}
		setLoading(true);
		httpsCallable(
			functions,
			"setUserPassword"
		)({
			userId: staff.ownerId,
		})
			.then((res) => {
				setLoading(false);
				console.log(res);
				alert("Account password updated");
				onComplete();
			})
			.catch((error) => {
				console.error({ error });
				setLoading(false);
				alert("Error setting account password. Please try again.");
			});
	};

	return (
		<div className="space-y-2">
			<TextInput
				label="New Password"
				value={newPassword}
				onChange={(e) => setNewPassword(e.target.value)}
			/>

			<Button onClick={submit} loading={loading} className="bg-sky-700">
				Submit
			</Button>
		</div>
	);
};
