import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { Login } from "./screens/Login";
import { AdminLayout } from "./screens/admin/Layout";
import Admin from "./screens/admin";
import UserApp from "./screens/app";
import { AppLayout } from "./screens/app/Layout";
import { AuthProvider } from "./contexts/auth";
// @ts-ignore
import { QueryClient, QueryClientProvider } from "react-query";
import PublicAccountSummary from "./screens/PublicAccountSummary";
import PublicSchoolReports from "./screens/PublicSchoolReports";
import PublicMissedMeals from "./screens/PublicMissedMeals";
import BoardersMissedDinner from "./screens/BoardersMissedDinner";
import StaffReports from "./screens/StaffMealSummary";
import TeachersOnDutySummary from "./screens/TeachersOnDutySummary";
import DailySnackDispatches from "./screens/DailySnackDispatches";
import AccountCodesList from "./screens/AccountCodesList";
import PrintAccountIds from "./screens/PrintAccountIds";

// Create a client
const queryClient = new QueryClient();

function App() {
	return (
		<React.StrictMode>
			<QueryClientProvider client={queryClient}>
				<BrowserRouter>
					<AuthProvider>
						<Routes>
							<Route path="/" element={<Login />} />
							<Route path="/public">
								<Route
									path="account-summary"
									element={<PublicAccountSummary />}
								/>
								<Route
									path="school-reports"
									element={<PublicSchoolReports />}
								/>
								<Route
									path="staff-reports"
									element={<StaffReports accountType="staff" />}
								/>
								<Route
									path="visitors-report"
									element={<StaffReports accountType="visitor" />}
								/>
								<Route
									path="teachers-on-duty"
									element={<TeachersOnDutySummary />}
								/>
								<Route path="missed-meals" element={<PublicMissedMeals />} />
								<Route
									path="boarding-missed-dinners"
									element={<BoardersMissedDinner />}
								/>
								<Route path="daily-snacks" element={<DailySnackDispatches />} />
								<Route path="account-codes" element={<AccountCodesList />} />
								<Route path="print-account-ids/:grade" element={<PrintAccountIds />} />
							</Route>

							<Route path="/admin" element={<AdminLayout />}>
								<Route path="" element={<Admin.Dashboard />} />
								<Route path="accounts" element={<Admin.AccountsSummary />} />
								<Route path="prices" element={<Admin.Prices />} />
								<Route path="schools" element={<Admin.SchoolsList />} />
								<Route
									path="accounts/:accountId/cards"
									element={<Admin.AccountCards />}
								/>
								<Route
									path="accounts/:accountId/cards/print"
									element={<Admin.PrintableCard />}
								/>
								<Route path="snacks" element={<Admin.SnacksScreen />} />
								<Route path="sell-snacks" element={<Admin.NewSnackSale />} />
								<Route path="daily-snacks" element={<Admin.DailySnacks />} />
								<Route path="staff" element={<Admin.Staff />} />
								<Route path="menu" element={<Admin.MenuManager />} />
								<Route
									path="register-staff"
									element={<Admin.RegisterStaff />}
								/>
								<Route path="sales" element={<Admin.SalesScreen />} />
								<Route path="deposits" element={<Admin.Deposits />} />
								<Route path="inventory" element={<Admin.Inventory />} />
								<Route path="sales/create" element={<Admin.CreateSale />} />
								<Route path="sales/new" element={<Admin.NewSale />} />
								<Route path="reports" element={<Admin.Reports />} />
								<Route
									path="reports/:reportId"
									element={<Admin.ExpandedReportsList />}
								/>
								<Route
									path="reports/:reportId/view-invoice/:invoiceId"
									element={<Admin.InvoiceView />}
								/>

								<Route path="settings" element={<Admin.Settings />} />
								<Route path="deduplicator" element={<Admin.Deduplicator />} />
								<Route
									path="create-account"
									element={<Admin.CreateAccount />}
								/>
								<Route
									path="accounts/edit/:accountId"
									element={<Admin.CreateAccount isEditing={true} />}
								/>
							</Route>
							<Route path="/app" element={<AppLayout />}>
								<Route path="" element={<UserApp.Dashboard />} />
							</Route>
							{/* <Route path="about" element={<About />} /> */}
						</Routes>
					</AuthProvider>
				</BrowserRouter>
			</QueryClientProvider>
		</React.StrictMode>
	);
}

export default App;
