import React, { useEffect, useMemo, useState } from "react";
import { endOfDay, startOfDay, subDays } from "date-fns";
import {
	collection,
	getDoc,
	getDocs,
	limit,
	query,
	where,
} from "firebase/firestore";
import { LetterHead } from "./PublicSchoolReports";
import { firestore } from "../firebase";
import { formatFirebaseDates } from "./admin/CreateAccount";
import { MealAttendanceSummary } from "../components/MealAttendanceSummary";
import { DailySnacksList } from "../components/DailySnacksList";
import { useSearchParams } from "react-router-dom";
import { getEATime } from "../utils";
import { DatePickerInput, DatesRangeValue } from "@mantine/dates";
import { upperFirst } from "lodash";
import { DailySnacksDispatch } from "../types";

const MIN_DATE = startOfDay(subDays(new Date(), 32));

export default function DailySnackDispatches() {
	const [dispatches, setDispatches] = useState<DailySnacksDispatch[]>([]);
	const [accountType, setAccountType] = useState("");
	const params = useSearchParams();
	const [dateRange, setDateRange] = useState<DatesRangeValue>([
		startOfDay(subDays(new Date(), 15)),
		getEATime(new Date()),
	]);

	const fetchDispatches = async (type: string) => {
		setDispatches([]);
		const q = query(
			collection(firestore, "daily-snack-dispatches"),
			where("type", "==", type),
			where("date", ">=", MIN_DATE)
		);
		const res = await getDocs(q);
		// getDocs(collection(firestore, "accounts"));

		setDispatches(
			res.docs.map((d) => formatFirebaseDates(d.data()) as DailySnacksDispatch)
		);
	};

	useEffect(() => {
		const type = params[0].get("t");

		if (type === "staff" || type === "boarding") {
			fetchDispatches(type);
			setAccountType(type);
		} else {
			alert("Invalid link. Please use the correct reporting link.");
		}
		// fetchDispatches();
	}, []);

	const filteredDispatches = useMemo(
		() =>
			dispatches.filter((d) => {
				if (dateRange[0] && dateRange[1]) {
					return (
						d.date >= startOfDay(dateRange[0]) &&
						d.date <= endOfDay(dateRange[1])
					);
				}
				return true;
			}),
		[dateRange]
	);

	return (
		<div className="container mx-auto">
			<LetterHead />

			<DatePickerInput
				type="range"
				label="Book hotel"
				placeholder="Pick dates range"
				value={dateRange}
				onChange={setDateRange}
				className="no-print"
			/>
			<div className="px-4">
				<div className="print-only">
					<h1 className="text-xl mb-6">
						Daily snacks dispatched for {upperFirst(accountType)} accounts
					</h1>
				</div>

				<DailySnacksList
					data={filteredDispatches}
					deleteSnackDispatch={() => {}}
					readOnly
				/>
			</div>
		</div>
	);
}
