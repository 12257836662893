import { NumberInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useFirestoreDocument, useFirestoreDocumentData, useFirestoreQueryData } from "@react-query-firebase/firestore";
import { doc, getDoc, onSnapshot, setDoc } from "firebase/firestore";
import { upperFirst } from "lodash";
import React, { useEffect, useState } from "react";
import { firestore } from "../../firebase";
import { Account, AccountType, AccountTypeSubGroup } from "../../types";


const grades = ["ec1", "ec2", "ec",
	"p1", "p2", "p3", "p4", "p5", "p6",
	"m1", "m2", "m3", "m4", "m5", "m6", "d1", "d2", "none"] as const


type MealPrices = {
	breakfast: number;
	lunch: number;
	dinner: number;
}

type PricingProfile = {
	[key in AccountType]: {
		[key in AccountTypeSubGroup]: {
			[grade in (typeof grades[number] | "none")]: MealPrices
		}
	}
}

type PricingProfileName = "default" | "event" | string

export type PricingTable = {
	[key in PricingProfileName]: PricingProfile
}

const defaultMeals = {
	"breakfast": 1000,
	"lunch": 1000,
	"dinner": 1000
}

const mealPriceObjects = grades.reduce((prev, curr) => {
	return {
		...prev,
		[curr]: { ...defaultMeals }
	}
}, {})


export const pricingTable: PricingTable = {
	"default": {
		"student": {
			"day": { ...mealPriceObjects },
			"boarding": { ...mealPriceObjects }
		},
		"teacher": {
			"none": {
				"none": {
					...defaultMeals
				}
			},
		},
		"guest": {
			"none": {
				"none": {
					...defaultMeals
				}
			}
		},

		"staff": {
			"auxiliary": {
				"none": {
					...defaultMeals
				}
			},

			"support": {
				"none": {
					...defaultMeals
				}
			}
		},
		createdAt: new Date()
	},
} as unknown as PricingTable


/**
Get the cost of a meal given the pricing table, and account details

// @returns {() => {error: boolean, result: MealPrices}} result
*/
export const getMealCost = (pricing: PricingProfile) => (accountType: Account["type"], accountTypeSubGroup: Account["typeSubGroup"], grade: keyof PricingProfile["student"]["day"]): { result: MealPrices, error: boolean } => {
  // const accc = pricing[accountType][accountTypeSubGroup][]
  try {
    // @ts-ignore issue with the grade name
    const gradeKey = grade === "" ? "none" : grade;

    const result = pricing[accountType][accountTypeSubGroup][gradeKey]
    return {
      result,
      error: false
    }
  } catch (error) {
    console.error(error)
    return {
      error: true,
      result: {
        "breakfast": 6_500,
        "lunch": 6_500,
        "dinner": 6_500
      }
    }
  }
}


const sections: { type: AccountType, typeSubGroups: AccountTypeSubGroup[], grades: typeof grades | ["none"] }[] = [
	{ type: "student", typeSubGroups: ["day", "boarding"], grades },
	{ type: "teacher", typeSubGroups: ["none"], grades: ["none"] },
	{ type: "staff", typeSubGroups: ["support", "auxiliary"], grades: ["none"] },
	{ type: "guest", typeSubGroups: ["none"], grades: ["none"] },
]


export default function Prices() {
	const form = useForm<PricingTable>({
		initialValues: {
			...pricingTable,

			// @ts-ignore
			// createdAt: new Date(),
		},

	});

	// Flag to determine whether or not this render is of an update to pricing or no pricing values exist
	const [isUpdate, setIsUpdate] = useState(true)

	const defaultPricingRef = doc(firestore, "pricing", "default") as any
	// const pricingQuery = useFirestoreDocument<PricingTable["default"]>(["pricing", "default"], defaultPricingRef, {
	// 	subscribe: true
	// });


	const save = () => {
		const defaultPricing = {
			...form.values.default,
			updatedAt: new Date()
		};

		// @ts-ignore
		setDoc(defaultPricingRef, defaultPricing).then(res => {
			alert("✅ Changes to pricing saved!")
		}).catch(error => {
			alert("❌ Error saving changes. Try again.")
		})
	}

	useEffect(() => {
		getDoc(defaultPricingRef).then((doc) => {
			if (doc.exists()) {
				setIsUpdate(true)
				form.setValues({ default: Object.assign({ ...form.values.default }, doc.data() as PricingTable["default"]) })
			} else {
				setIsUpdate(false)
			}
		})

	}, [])

	return (
		<div>
			<div className="flex justify-between">
				<h1 className="text-3xl">Price Manager</h1>
				<button type="button"
					className="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
					onClick={save}
				>
					Save Changes
				</button>
			</div>
			{
				sections.map(section => (
					<div key={section.type} className="py-4 space-y-2">
						<h1 className="text-xl">{upperFirst(section.type)}</h1>
						{section.typeSubGroups.map(subType =>
							<div key={subType} className="space-y-1">
								<h2 className="text-md">{upperFirst(subType)}</h2>
								<div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
									<table className="min-w-full divide-y divide-gray-300">
										<thead>
											<tr>
												<th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
													Grade
												</th>
												<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
													Breakfast
												</th>
												<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
													Lunch
												</th>
												<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
													Dinner
												</th>
											</tr>
										</thead>
										<tbody className="bg-white">
											{
												section.grades.map(grade => {
													// @ts-ignore
													// console.log(pricingTable["default"][section.type][subType][grade], grade, section.type)
													return (
														<tr key={grade} className="even:bg-gray-50">
															<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
																{grade}
															</td>
															<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
																<NumberInput
																	// {/*@ts-ignore */}
																	placeholder="breakfast"
																	{...form.getInputProps(
																		`default.${section.type}.${subType}.${grade}.breakfast`
																	)}
																	thousandsSeparator=","
																	min={0}
																	withAsterisk />
															</td>
															<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
																<NumberInput
																	defaultValue={0}
																	min={0}
																	{...form.getInputProps(
																		`default.${section.type}.${subType}.${grade}.lunch`
																	)}
																	placeholder="lunch"
																	thousandsSeparator=","
																	withAsterisk />
															</td>
															<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
																<NumberInput
																	defaultValue={0}
																	min={0}
																	{...form.getInputProps(
																		`default.${section.type}.${subType}.${grade}.dinner`
																	)}
																	placeholder="dinner"
																	thousandsSeparator=","
																	withAsterisk />
															</td>
														</tr>
													);
												})
											}
										</tbody>
									</table>

								</div>
							</div>
						)}
					</div>
				))
			}
		</div>
	);
}
