import { useState } from "react";
import { createStyles, Table, ScrollArea, ActionIcon } from "@mantine/core";
import { format } from "date-fns";
import { IconTrash } from "@tabler/icons";
import { upperFirst } from "lodash";
import { DailySnacksDispatch } from "../types";

const useStyles = createStyles((theme) => ({
	header: {
		position: "sticky",
		top: 0,
		backgroundColor:
			theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
		transition: "box-shadow 150ms ease",

		"&::after": {
			content: '""',
			position: "absolute",
			left: 0,
			right: 0,
			bottom: 0,
			borderBottom: `1px solid ${
				theme.colorScheme === "dark"
					? theme.colors.dark[3]
					: theme.colors.gray[2]
			}`,
		},
	},

	scrolled: {
		boxShadow: theme.shadows.sm,
	},
}));

type DailySnacksListProps = {
	readOnly?: boolean; // Indicator of wether any functions are available
	data: DailySnacksDispatch[];
	deleteSnackDispatch: (uid: string) => void;
};

export function DailySnacksList({
	data,
	deleteSnackDispatch,
	readOnly = false,
}: DailySnacksListProps) {
	const { classes, cx } = useStyles();
	const [scrolled, setScrolled] = useState(false);

	const rows = data.map((row) => (
		<tr key={row.uid}>
			<td>{format(row.date, "dd MMMM yyyy")}</td>
			<td>{upperFirst(row.type)}</td>
			<td>{row.count}</td>
			{!readOnly && (
				<td>
					<ActionIcon
						title="Delete"
						onClick={() => deleteSnackDispatch(row.uid)}
						variant="light"
					>
						<IconTrash size={16} />
					</ActionIcon>
				</td>
			)}
		</tr>
	));

	return (
		<ScrollArea
			// sx={{ height: 300 }}
			onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
		>
			<Table sx={{ minWidth: 700 }}>
				<thead className={cx(classes.header, { [classes.scrolled]: scrolled })}>
					<tr>
						<th>Date</th>
						<th>Type</th>
						<th>Snack Count</th>
						{!readOnly && <th>Actions</th>}
					</tr>
				</thead>
				<tbody>{rows}</tbody>
			</Table>
		</ScrollArea>
	);
}
