import { subDays } from "date-fns";
import { collection, getDoc, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { LetterHead } from "./PublicSchoolReports";
import { firestore } from "../firebase";
import { formatFirebaseDates } from "./admin/CreateAccount";
import { MealAttendanceSummary } from "../components/MealAttendanceSummary";
import { Account, MealSaleItem, Sale } from "../types";

const MIN_DATE = subDays(new Date(), 31);

export default function PublicMissedMeals() {
	const [sales, setSales] = useState<MealSaleItem[]>([]);
	const [accounts, setAccounts] = useState<Account[]>([]);

	const fetchSales = async () => {
		setSales([]);
		const q = query(
			collection(firestore, "sales"),
			where("createdAt", ">=", MIN_DATE),
			where("createdAt", "<=", new Date())
		);
		// purposely not including the typeSubGroup in the query as there was confusion in creating accounts and some results might be missing (???)
		const res = await getDocs(q);

		setSales(res.docs.map((d) => formatFirebaseDates(d.data()) as MealSaleItem));
	};

	const fetchAccounts = async () => {
		setAccounts([]);
		const res = await getDocs(collection(firestore, "accounts"));

		setAccounts(res.docs.map((d) => formatFirebaseDates(d.data()) as Account));
	};

	useEffect(() => {
		fetchSales();
		fetchAccounts();
	}, []);
	return (
		<div className="container mx-auto">
			<LetterHead />

			<MealAttendanceSummary
				startDate={MIN_DATE}
				accounts={accounts}
				sales={sales}
			/>
		</div>
	);
}
