import { collection, doc, getDocs } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
// @ts-ignore
import QRCode from "qrcode";
import { NavBar } from "../../components/AdminNavBar";
import { firestore } from "../../firebase";
import { useFirestoreDocument, useFirestoreDocumentData } from "@react-query-firebase/firestore";
import { Avatar, Button } from "@mantine/core";
import _ from "lodash";
import { QRCodeSVG } from "qrcode.react";
import { Account, Card } from "../../types";

// const DOMAIN_ORIGIN = "https://chakula.salehgroup.co.tz/";
const DOMAIN_ORIGIN = window.location.origin;

export default function AccountCards() {
	const navigate = useNavigate();
	const { accountId = "" } = useParams();
	const [cards, setCards] = useState<Card[]>([]);
	const [loading, setLoading] = useState(true);

	const accountRef = doc(firestore, "accounts", accountId || "") as any;

	const account = useFirestoreDocumentData<Account>(["accounts", accountId], accountRef);

	useEffect(() => {
		if (typeof accountId !== "string") {
			return;
		}
		const getCards = async () =>
			await getDocs(collection(firestore, "accounts", accountId, "cards"));
		getCards()
			.then((res) => {
				setCards(
					res.docs.map((d) => ({ uid: d.id, ...d.data() } as unknown as Card))
				);
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
				setCards([]);
			});
	}, []);
	if (account.isLoading) {
		return <div>Loading...</div>;
	}

	return (
		<div>
			<NavBar title="Account Cards" />

			{loading && <div>Loading ...</div>}

			{cards.map((card) => (
				<div key={card.number} className="py-8">
					<Button
						onClick={() => {
							// @ts-ignore
							return navigate(`print?id=${card.uid}`);
						}}
						className="bg-sky-800 no-print"
					>
						Print Card
					</Button>
					<a
						target="_blank"
						className="text-blue-800 ml-8 no-print"
						href={`${DOMAIN_ORIGIN}/public/account-summary?id=${accountId}`}
					>
						Open Public Summary {account.data?.uid}
					</a>

					{account.data && <CardItem card={card} account={account.data} />}
				</div>
			))}
		</div>
	);
}

type CardItemProps = {
	card?: Card;
	account: Account;
};

export const CardItem: React.FC<CardItemProps> = ({ card, account }) => {
	const canvasRef = useRef(null);
	const publicLinkRef = useRef(null);

	const accountPublicLink = `${DOMAIN_ORIGIN}/public/account-summary?id=${account.uid}`;

	// useEffect(() => {
	// console.log(canvasRef.current);
	// const cardObj = {
	// 	...card,
	// 	name: account.ownerFullName,
	// 	organization: account.organization,
	// 	accountType: account.type,
	// };

	// const minimalCard = _.omit(cardObj, [
	// 	"cvv",
	// 	"createdAt",
	// 	"updatedAt",
	// 	"expiresAt",
	// ]);

	// QRCode.toCanvas(
	// 	canvasRef.current,
	// 	JSON.stringify(minimalCard),
	// 	{ errorCorrectionLevel: "H" },
	// 	function(error: any) {
	// 		if (error) console.error(error);
	// 		console.log("success!");
	// 	}
	// );

	// QRCode.toCanvas(publicLinkRef.current, accountPublicLink);
	// }, [card.number]);

	return (
		<div
			className="flex flex-col border border-gray-600 p-8 rounded-md w-full"
		>
			<div className="flex justify-between items-center">
				<Avatar
					className="border-8 border-solid rounded-full"
					style={{ borderColor: "#063259" }}
					component="div"
					radius={"xl"}
					src={account.ownerPhotoURL}
					size={120}
				/>
				<div className="flex flex-row">
					<QRCodeSVG includeMargin={true} size={150} level="H" value={`${account.uid}|${account.ownerFullName}`} />
				</div>
			</div>
			<div className="flex flex-col justify-center">
				<h1 className="text-3xl text-center uppercase" style={{ fontFamily: "AladinRegular" }}>{account.ownerFullName}</h1>
				<div className="flex items-end">
					<QRCodeSVG includeMargin={true} size={75} level="M" value={accountPublicLink} />

					<div style={{ maxWidth: 80 }} className="ml-4 mb-3">
						<img src={require("../../assets/curly-arrow.png")} style={{}} />
					</div>
					<h1 className="text-lg font-bold text-center mb-3" style={{ fontFamily: "AladinRegular" }}>CHEQUE ME OUT</h1>
					{/* <a href={accountPublicLink} target="_blank"> */}
					{/* 	{accountPublicLink} */}
					{/* </a> */}
				</div>
			</div>
		</div>
	);
};
