import React, { useEffect, useState } from "react";
import { useForm } from "@mantine/form";
import {
	TextInput,
	PasswordInput,
	Checkbox,
	Anchor,
	Paper,
	Title,
	Text,
	Container,
	Group,
	Button,
} from "@mantine/core";
import { auth, firestore, functions } from "../firebase";
import { httpsCallable } from "firebase/functions";
import {
	createUserWithEmailAndPassword,
	onAuthStateChanged,
	signInWithEmailAndPassword,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, query, where } from "firebase/firestore";

type LoginForm = {
	email: string;
	password: string;
};

export function Login() {
	const navigate = useNavigate();
	// @ts-ignore
	const form = useForm({
		initialValues: {
			email: "",
			password: "",
		},
	});

	const [loading, setLoading] = useState(true);

	const goToUserDashboard = () => navigate("/app");
	const goToAdminDashboard = () => navigate("/admin");

	useEffect(() => {
		const authListener = onAuthStateChanged(auth, (user) => {
			if (!user) {
				setLoading(false);
			} else {
				user
					.getIdTokenResult()
					.then((idTokenResult) => {
						console.log({ idTokenResult });
						if (!!idTokenResult.claims.role === false) {
							auth.signOut();
							alert("This account is broken. Please contact support");
							throw new Error("Broken account.");
						}
						// Confirm the user is an Admin.
						if (idTokenResult.claims.role === "admin") {
							// Show admin UI.
							goToAdminDashboard();
						} else {
							// Show regular user UI.
							goToUserDashboard();
						}
					})
					.finally(() => setLoading(false));
			}
		});

		return () => {
			authListener();
		};
	}, []);

	const updateUserClaims = (uid: string) => {
		httpsCallable(
			functions,
			"updateUserClaims"
		)({
			uid,
			claim: "admin",
		})
			.then((res) => {
				console.log(res);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const createAdminAccount = (data: LoginForm) => {
		console.log(data);
		if (loading) return;
		setLoading(true);
		createUserWithEmailAndPassword(auth, data.email, data.password).then(
			(res) => {
				updateUserClaims(res.user.uid);
			}
		);
	};

	const handleSignIn = (data: LoginForm) => {
		console.log(data);
		if (loading) return;
		setLoading(true);

		signInWithEmailAndPassword(auth, data.email, data.password)
			.then((res) => {
				if (res.user) {
				}
			})
			.catch((error) => {
				setLoading(false);
				if (error.code === "auth/user-not-found") {
					return alert("This account does not exist");
				} else if (error.code === "auth/wrong-password") {
					return alert("Incorrect Password");
				}
				console.error({ error });
			});
	};

	return (
		<Container size={420} my={40}>
			<Title
				align="center"
				sx={(theme) => ({
					fontFamily: `Greycliff CF, ${theme.fontFamily}`,
					fontWeight: 900,
				})}
			>
				Chakula Platform Admin
			</Title>
			{/* <Text color="dimmed" size="sm" align="center" mt={5}>
				Do not have an account yet?{" "}
				<Anchor<"a">
					href="#"
					size="sm"
					onClick={(event) => event.preventDefault()}
				>
					Create account
				</Anchor>
			</Text> */}

			<form onSubmit={form.onSubmit((values) => handleSignIn(values))}>
				{/* <form onSubmit={form.onSubmit((values) => createAdminAccount(values))}> */}
				<Paper withBorder shadow="md" p={30} mt={30} radius="md">
					<TextInput
						label="Email"
						placeholder="you@mantine.dev"
						{...form.getInputProps("email")}
						required
					/>
					<PasswordInput
						label="Password"
						placeholder="Your password"
						{...form.getInputProps("password")}
						required
						mt="md"
					/>
					<Button
						loading={loading}
						type="submit"
						fullWidth
						mt="xl"
						className="bg-sky-800"
					>
						Sign in
					</Button>
				</Paper>
			</form>
		</Container>
	);
}
