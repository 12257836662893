import { Account } from "./types";

type StrategyItem = {
	grades: string[];
	price: number;
};

export type StrategyName = "lunch" | "breakfast" | "dinner" | "snacks";

type CostStrategy = Record<StrategyName, StrategyItem[]>;

function createStrategy(
	grades: StrategyItem["grades"],
	price: StrategyItem["price"]
): StrategyItem {
	return {
		grades,
		price,
	};
}

// Shorter alias
const cs = createStrategy;

export const studentsStrategy: CostStrategy = {
	lunch: [
		cs(["ec"], 3_000),
		cs(["p1", "p2", "p3"], 5_500),
		cs(["p4", "p5", "p6", "p7", "m1", "m2"], 6_500),
		cs(["m3", "m4", "m5", "m6", "d1", "d2"], 7_500),
	],
	breakfast: [cs(["default"], 5_000)],
	dinner: [cs(["default"], 8_000)],
	snacks: [cs(["default"], 3_000)],
};

export const boardersStrategy: CostStrategy = {
	breakfast: [cs(["default"], 5_000)],
	lunch: [cs(["default"], 8_000)],
	dinner: [cs(["default"], 8_000)],
	snacks: [cs(["default"], 3_000)],
};

// Strategy for teachers, guests, and visitors
export const teachersStrategy: CostStrategy = {
	breakfast: [cs(["default"], 5_000)],
	lunch: [cs(["default"], 7_500)],
	dinner: [cs(["default"], 8_000)],
	snacks: [cs(["default"], 3_000)],
};

export const staffStrategy: CostStrategy = {
	breakfast: [cs(["default"], 5_000)],
	lunch: [cs(["default"], 4_500)],
	dinner: [cs(["default"], 8_000)],
	snacks: [cs(["default"], 500)],
};

export const visitorsStrategy: CostStrategy = {
	breakfast: [cs(["default"], 5_000)],
	lunch: [cs(["default"], 8_000)],
	dinner: [cs(["default"], 8_000)],
	snacks: [cs(["default"], 3_000)],
};

function getStrategyPrice(
	strategy: CostStrategy,
	meal: StrategyName,
	grade: string
): number {
	const mealStrategy = strategy[meal];
	const result =
		mealStrategy.find((s) => s.grades.includes(grade.toLowerCase()))?.price ||
		mealStrategy.find((s) => s.grades.includes("default"))?.price ||
		8_000;
	return result;
}

export const getPrice = (account: Account, meal: StrategyName): number => {
	switch (account.type) {
		case "staff":
			return getStrategyPrice(staffStrategy, meal, "default");
		case "guest":
		case "visitor":
			return getStrategyPrice(visitorsStrategy, meal, "default");
		case "teacher":
			return getStrategyPrice(teachersStrategy, meal, "default");
		case "student":
			if (account.typeSubGroup === "boarding") {
				return getStrategyPrice(boardersStrategy, meal, account.grade);
			} else if (account.typeSubGroup === "day") {
				return getStrategyPrice(studentsStrategy, meal, account.grade);
			}
			return 0;
		default:
			return 0;
	}
};

export const getDefaultAccess = (account: Account): StrategyName[] => {
	const group = account.typeSubGroup;
	switch (account.type) {
		case "student":
			if (group === "day") {
				return ["lunch", "snacks"];
			} else if (group === "boarding") {
				return ["breakfast", "lunch", "dinner", "snacks"];
			}
			return [];
		case "staff":
			return ["snacks", "lunch"];
		case "teacher":
		case "visitor":
		case "guest":
		case "school":
			return ["lunch", "snacks"];
		default:
			return [];
	}
};
