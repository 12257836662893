import { Button, Group, PasswordInput, Select, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase";
import { upperFirst } from "lodash";
import React, { useState } from "react";
import { NavBar } from "../../components/AdminNavBar";
import { useNavigate } from "react-router-dom";
import { Staff } from "../../types";

type StaffForm = {
	email: string;
	password: string;
	name: string;
	role: Staff["role"];
};

export default function RegisterStaff() {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const form = useForm<StaffForm>({
		initialValues: {
			email: "",
			password: "",
			name: "",
			role: "server",
		},
	});
	const createAccount = (values: StaffForm) => {
		if (loading) return;
		setLoading(true);
		httpsCallable(
			functions,
			"registerStaff"
		)({ account: values })
			.then((res) => {
				setLoading(false);
				console.log(res);
				alert("Account created");
				navigate("/admin/staff");
			})
			.catch((error) => {
				console.error({ error });
				setLoading(false);
				alert("Error creating account. Please try again.");
			});

		console.log(values);
	};
	return (
		<div>
			<NavBar title="Register Staff" />

			<form onSubmit={form.onSubmit(createAccount)} className="space-y-4">
				<TextInput
					required
					label="Name"
					placeholder="Staff Account Name"
					{...form.getInputProps("name")}
				/>
				<TextInput
					required
					label="Account Email"
					placeholder="staff@canteen.com"
					type="email"
					{...form.getInputProps("email")}
				/>
				<PasswordInput
					required
					label="Password"
					placeholder="MyPassoword"
					{...form.getInputProps("password")}
				/>
				<Select
					label="Account Type"
					placeholder="Pick one"
					{...form.getInputProps("role")}
					data={["server", "admin"].map((acc) => ({
						value: acc,
						label: upperFirst(acc),
					}))}
				/>
				<Group position="right" mt="md">
					<Button loading={loading} type="submit" className="bg-sky-800">
						Submit
					</Button>
				</Group>
			</form>
		</div>
	);
}
