import { collection, getDocs, limit, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { QRCodeSVG } from 'qrcode.react';
import { firestore } from "../firebase";
import { Account } from "../types";
import { CardItem } from "./admin/AccountCards";
import { useParams } from "react-router";



export default function PrintAccountIds() {
  const [accounts, setAccounts] = useState<Account[]>([])
  const { grade } = useParams()


  useEffect(() => {
    let q;
    if (grade !== "teachers") {
      q = query(collection(firestore, "accounts"), where("grade", "==", grade || "d1"));
    } else {
      q = query(collection(firestore, "accounts"), where("type", "==", "teacher"));
    }
    getDocs(q).then(res => {
      const data = res.docs.map(d => d.data())
      console.log({ data })
      setAccounts(data as unknown as Account[])
    })
  }, [])

  return (
    <div className="grid grid-cols-2 gap-x-1 gap-y-2 p-4 pagebreak">
      {
        accounts.map((acc, idx) => (
          <div key={acc.uid}>
            <CardItem account={acc} key={acc.uid} />
            {(idx + 1) % 4 === 0 && <div style={{ breakAfter: "page" }}></div>}
          </div>
        ))
      }
    </div>
  )
}