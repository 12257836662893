import { useForm } from "@mantine/form";
import React, { useEffect, useState } from "react";
import { countBy, uniq, upperFirst, keys } from "lodash";
import { getEATime, getMissedMealAccounts } from "../utils";
import {
	Avatar,
	Checkbox,
	MultiSelect,
	Paper,
	Radio,
	Select,
} from "@mantine/core";
import { DatePicker, DatePickerInput } from "@mantine/dates";
import { endOfDay, format } from "date-fns";
import {
	getAccountTypsSubGroups,
	accountTypes,
} from "../screens/admin/CreateAccount";
import { applyAccountFilters } from "../screens/admin/AccountsSummary";
import SingleBar from "./SingleBar";
import { Account, AccountType, AccountTypeSubGroup, MealSaleItem, Sale } from "../types";

type MealAttendanceSummaryProps = {
	title?: string;
	startDate: Date;
	accounts: Account[];
	sales: MealSaleItem[];
	todayOnly?: boolean;
	hideFilters?: boolean;
	defaultFilters?: Record<string, any>;
};

type FilterForm = {
	date: Date;
	accountType: AccountType[];
	accountTypeSubGroup: AccountTypeSubGroup[];
	meal: MealSaleItem["category"];
	dormitory: string;
	missedMeals: "missed" | "present";
	overdraftOnly: boolean;
	grade: string[];
};
export function MealAttendanceSummary({
	title = "Meal Attendance Summary",
	startDate,
	accounts,
	sales,
	todayOnly = true,
	hideFilters = false,
	defaultFilters = {
		accountType: "student",
		accountTypeSubGroup: "boarding",
		meal: "lunch",
	},
}: MealAttendanceSummaryProps) {
	const form = useForm<FilterForm>({
		initialValues: {
			date: getEATime(),
			accountType: [defaultFilters.accountType || "student"],
			accountTypeSubGroup: [defaultFilters.accountTypeSubGroup || "boarding"],
			meal: defaultFilters.meal || "lunch",
			overdraftOnly: false,
			dormitory: "",
			grade: [],
			missedMeals: "missed",
		},
	});

	// listen for changes to form values
	// useEffect(() => {
	// 	if (form.values.accountType.length > 0) {
	// 		form.setFieldValue("accountTypeSubGroup", []);
	// 	}
	// }, [form.values.accountType]);

	const accountGrades = React.useMemo(
		() =>
			uniq(accounts.map((acc) => acc.grade || "")).filter((s) => s.length > 0),
		[]
	);

	const missedByCategory = getMissedMealAccounts(
		[form.values.meal],
		form.values.missedMeals === "present"
	);
	const categorySales = missedByCategory(
		sales.filter(
			(s) => s.createdAt.toDateString() === form.values.date?.toDateString()
		)
	);

	// const dormitoryPopulations = countBy(
	// 	accounts.map((a) => a.dormitory?.toLowerCase().trim())
	// );

	const dormitoryOptions = Array.from(
		new Set(
			accounts.map((d) => d.dormitory?.toLowerCase().trim()).filter(Boolean)
		)
	);

	const appliedFilters = applyAccountFilters("", {
		grades: form.values.grade,
		overdraftOnly: form.values.overdraftOnly,
		types: form.values.accountType,
		typeSubGroups: form.values.accountTypeSubGroup,
	});

	const filteredAccounts = accounts.filter(appliedFilters);

	const categorizedSales = categorySales(filteredAccounts);

	const missedAccounts = categorizedSales.filter((a) =>
		a.dormitory
			?.toLowerCase()
			.trim()
			.includes(form.values.dormitory || "")
	);
	const dormitoryDistribution = countBy(missedAccounts, (o) =>
		o.dormitory?.toLowerCase().trim()
	);

	// @ts-ignore HACK!
	// Students accounts are being used to store staff who are similar to students
	// No idea why though ...
	delete dormitoryDistribution[undefined];

	return (
		<Paper shadow="xs" p="md">
			<h1 className="text-xl text-bold">{title}</h1>
			<p className="text-sm text-gray-500">
				{format(form.values.date, "dd/MM/yyyy")}
			</p>
			<div className="flex flex-col md:flex-row md:space-x-4 no-print">
				<DatePickerInput
					label="Date"
					minDate={startDate}
					clearable={false}
					maxDate={endOfDay(new Date())}
					// value={date}
					// onChange={onChange}
					{...form.getInputProps("date")}
				/>
				{!hideFilters && (
					<>
						{" "}
						<Select
							label="Select the meal type"
							placeholder="Pick a sale type"
							// value={meal}
							// onChange={(v) => {
							// 	console.log(v);
							// 	setMeal(v as Sale["category"]);
							// }}
							{...form.getInputProps("meal")}
							data={["breakfast", "lunch", "dinner"]}
						/>
						<Select
							label="Account Type"
							placeholder="Pick one"
							// onChange={(s) => setAccountType(s as any)}
							// value={accountType}
							{...form.getInputProps("accountType")}
							data={accountTypes.map((acc) => ({
								value: acc,
								label: upperFirst(acc),
							}))}
						/>
						<MultiSelect
							label="Account Type Group"
							placeholder="Pick one"
							clearable
							{...form.getInputProps("accountTypeSubGroup")}
							// value={accountSubType}
							// onChange={(s) => setAccountSubType(s as any)}
							data={getAccountTypsSubGroups(form.values.accountType as any)}
						/>
						{form.values.accountType.includes("student") && (
							<MultiSelect
								label="Select the grade"
								placeholder="Pick a grades to filter by"
								// value={grades}
								clearable
								multiple
								//								{/* {...form.getInputProps("grade")} */}
								onChange={(g) => console.log(g as any)}
								data={accountGrades}
							/>
						)}
						{form.values.accountTypeSubGroup.includes("boarding") && (
							<MultiSelect
								label="Select the dormitory"
								placeholder="Pick a dormitory type"
								// value={dormitory}
								clearable
								multiple
								{...form.getInputProps("dormitory")}
								// onChange={(v) => {
								// 	setDormitory(v as string);
								// }}
								data={dormitoryOptions}
							/>
						)}
					</>
				)}
			</div>
			<div className="mt-2">
				<div className="print-only">
					<h1 className="text-xl">
						{form.values.missedMeals === "present"
							? "Showing accounts that had their meals"
							: `Showing accounts that missed their ${form.values.meal} meals`}
					</h1>
				</div>
				<Radio.Group
					// value={form.values.missedMeals}
					{...form.getInputProps("missedMeals")}
					name="missedMeals"
					className="no-print"
					label="Missed vs Present Accounts"
					description="Filter between accounts that missed meals, those who ate, or both."
					withAsterisk
				>
					<Radio value="missed" label="Show accounts that missed meals" />
					<Radio value="present" label="Show accounts that were present" />
				</Radio.Group>
			</div>
			<div className="py-2 mt-5">
				{form.values.accountTypeSubGroup.includes("boarding") && (
					<div className="max-w-xl no-print">
						{/* Absent Dormitory Summary */}
						{/* {keys(dormitoryDistribution).map((k) => ( */}
						{/* 	<div key={k}> */}
						{/* 		{upperFirst(k)} : {dormitoryDistribution[k]} out of{" "} */}
						{/* 		{dormitoryPopulations[k]} */}
						{/* 	</div> */}
						{/* ))} */}
						{/* <Text className="text-xl">{summary.title}</Text> */}
						<SingleBar
							data={keys(dormitoryDistribution).map((k) => ({
								label: upperFirst(k),
								value: dormitoryDistribution[k],
							}))}
							withLegend={true}
							orientation={"vertical"}
						/>
					</div>
				)}
				<h1 className="text-lg">Total Accounts: ({missedAccounts.length})</h1>
				{missedAccounts.map((acc) => (
					<MissedMealAccountRow account={acc} key={acc.uid} />
				))}
			</div>
		</Paper>
	);
}

type MissedMealAccountRowProps = {
	account: Account;
};

export const MissedMealAccountRow: React.FC<MissedMealAccountRowProps> = ({
	account,
}) => {
	return (
		<div
			className="border-b border-gray-200 py-4 flex flex-row space-x-2"
			key={account.uid}
		>
			<Avatar
				className="self-center"
				radius={"xl"}
				src={account.ownerPhotoURL || ""}
			/>
			<div>
				<h1 className="text-lg text-gray-800">{account.ownerFullName}</h1>
				{account.typeSubGroup === "boarding" && (
					<p>Dormitory Name: {account.dormitory}</p>
				)}
			</div>
		</div>
	);
};

const missedMealFilter = (sale: Sale) => {
	return sale;
};
