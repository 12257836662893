import { atom } from "jotai";

type AccountsFilters = {
	types: string[];
	typeSubGroups: string[];
	grades: string[];
	listLength: number;
	overdraftOnly: boolean;
};

export const accountFilters = atom<AccountsFilters>({
	types: [],
	typeSubGroups: [],
	grades: [],
	listLength: 25,
	overdraftOnly: false,
});
